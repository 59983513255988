import { createContext, Dispatch } from "react"
import { ColumnFiltersState, SortingState } from "@tanstack/react-table"

import { QUALIFIED_LEADS, ELIGIBLE_LEADS } from "../shared/constants"
import { RowType } from "../types/partners"
import { FILTER_FNS } from "../sections/Partners/PartnersTable/constants"

export interface ViewportTypes {
  latitude: number
  longitude: number
  zoom: number
}

export type FilterFNSType =
  | "includesString"
  | "doesNotIncludeString"
  | "equals"
  | "weakEquals"
  | "isNot"
  | "isEmpty"
  | "isNotEmpty"
  | "lessOrEqual"
  | "moreOrEqual"
  | "notEqual"
  | "isBefore"
  | "isAfter"
  | "isDate"
  | "isNotDate"

export interface QualifiedLeadFilterFunctionsTypes {
  project_name: FilterFNSType
  deal_owner: FilterFNSType
  partner_name: FilterFNSType
  id: FilterFNSType
  status: FilterFNSType
  qualified_status: FilterFNSType
  first_name: FilterFNSType
  last_name: FilterFNSType
  primary_property_state: FilterFNSType
  acreage: FilterFNSType
  eligible_acres: FilterFNSType
  legal_entity_name: FilterFNSType
  email: FilterFNSType
  phone_number: FilterFNSType
  interest_date: FilterFNSType
  county: FilterFNSType
}

export interface EligibleLeadFilterFunctionsTypes {
  project_name: FilterFNSType
  id: FilterFNSType
  status: FilterFNSType
  // DEV: Temporarily disable filtering by qualified_status
  // qualified_status: FilterFNSType
  first_name: FilterFNSType
  last_name: FilterFNSType
  primary_property_state: FilterFNSType
  acreage: FilterFNSType
  eligible_acres: FilterFNSType
  email: FilterFNSType
  phone_number: FilterFNSType
  interest_date: FilterFNSType
}

export interface StateTypes {
  activeRow: RowType | null
  activeTab: typeof QUALIFIED_LEADS | typeof ELIGIBLE_LEADS
  eligibleActiveRowUnlocked: boolean
  viewport: ViewportTypes | null
  dealOwnerRowId: number | null
  eligibleLeadDataLoaded: boolean
  qualifiedLeadSorting: SortingState
  qualifiedLeadColumnFilters: ColumnFiltersState
  qualifiedLeadFilters: string[]
  qualifiedLeadFilterFunctions: QualifiedLeadFilterFunctionsTypes
  eligibleLeadSorting: SortingState
  eligibleLeadColumnFilters: ColumnFiltersState
  eligibleLeadFilterFunctions: EligibleLeadFilterFunctionsTypes
  eligibleLeadFilters: string[]
}

export type ActionType =
  | { type: "setActiveRow"; payload: any }
  | { type: "setActiveTab"; payload: string }
  | {
      type: "setEligibleActiveRowUnlocked"
      payload: boolean
    }
  | { type: "setViewport"; payload: any }
  | { type: "setDealOwnerRowId"; payload: any }
  | {
      type: "setEligibleLeadDataLoaded"
      payload: boolean
    }
  | {
      type: "setQualifiedLeadSorting"
      payload: SortingState
    }
  | {
      type: "setQualifiedLeadColumnFilters"
      payload: ColumnFiltersState
    }
  | {
      type: "setQualifiedLeadFilters"
      payload: number
    }
  | {
      type: "setQualifiedLeadFilterFunctions"
      payload: QualifiedLeadFilterFunctionsTypes
    }
  | {
      type: "setEligibleLeadSorting"
      payload: SortingState
    }
  | {
      type: "setEligibleLeadColumnFilters"
      payload: ColumnFiltersState
    }
  | {
      type: "setEligibleLeadFilters"
      payload: number
    }
  | {
      type: "setEligibleLeadFilterFunctions"
      payload: EligibleLeadFilterFunctionsTypes
    }

const initialState: StateTypes = {
  activeRow: null,
  activeTab: QUALIFIED_LEADS,
  eligibleActiveRowUnlocked: false,
  viewport: null,
  dealOwnerRowId: null,
  eligibleLeadDataLoaded: false,
  qualifiedLeadColumnFilters: [],
  qualifiedLeadFilters: [],
  qualifiedLeadFilterFunctions: {
    project_name: FILTER_FNS.includesString as FilterFNSType,
    deal_owner: FILTER_FNS.includesString as FilterFNSType,
    partner_name: FILTER_FNS.includesString as FilterFNSType,
    id: FILTER_FNS.lessOrEqual as FilterFNSType,
    status: FILTER_FNS.includesString as FilterFNSType,
    qualified_status: FILTER_FNS.includesString as FilterFNSType,
    first_name: FILTER_FNS.includesString as FilterFNSType,
    last_name: FILTER_FNS.includesString as FilterFNSType,
    primary_property_state: FILTER_FNS.includesString as FilterFNSType,
    acreage: FILTER_FNS.lessOrEqual as FilterFNSType,
    eligible_acres: FILTER_FNS.lessOrEqual as FilterFNSType,
    legal_entity_name: FILTER_FNS.includesString as FilterFNSType,
    email: FILTER_FNS.includesString as FilterFNSType,
    phone_number: FILTER_FNS.includesString as FilterFNSType,
    interest_date: FILTER_FNS.isBefore as FilterFNSType,
    county: FILTER_FNS.includesString as FilterFNSType,
  },
  qualifiedLeadSorting: [{ id: "status", desc: true }],
  eligibleLeadColumnFilters: [],
  eligibleLeadFilters: [],
  eligibleLeadFilterFunctions: {
    project_name: FILTER_FNS.includesString as FilterFNSType,
    id: FILTER_FNS.lessOrEqual as FilterFNSType,
    status: FILTER_FNS.includesString as FilterFNSType,
    // DEV: Temporarily disable filtering by qualified_status
    // qualified_status: FILTER_FNS.includesString as FilterFNSType,
    first_name: FILTER_FNS.includesString as FilterFNSType,
    last_name: FILTER_FNS.includesString as FilterFNSType,
    primary_property_state: FILTER_FNS.includesString as FilterFNSType,
    acreage: FILTER_FNS.lessOrEqual as FilterFNSType,
    eligible_acres: FILTER_FNS.lessOrEqual as FilterFNSType,
    email: FILTER_FNS.includesString as FilterFNSType,
    phone_number: FILTER_FNS.includesString as FilterFNSType,
    interest_date: FILTER_FNS.isBefore as FilterFNSType,
  },
  eligibleLeadSorting: [{ id: "status", desc: true }],
}

const PartnersContext = createContext<{
  state: StateTypes
  dispatch: Dispatch<ActionType>
}>({
  state: initialState,
  dispatch: () => null,
})

export { initialState, PartnersContext }
