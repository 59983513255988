import { useMemo } from "react"
import { LineChart, Line, XAxis, YAxis, Tooltip } from "recharts"
import { faCircleSmall } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"

import { roundTo } from "../../../utils"

const getChartData = (data) => {
  return data.revenue.map((_, i) => {
    const newObj = { year: i + 1 }

    Object.keys(data).forEach((key) => {
      const cents = data[key][i] < 10 ? 1 : 10
      newObj[key] = roundTo(data[key][i], cents)
    })

    return newObj
  })
}

// DEV: Using font awesome icon to ensure that the dot symbol is consistent on all devices.
// Ensure that values are limited to 2 decimals, and that negative values are displayed with - in front of $ sign.
const TooltipValueText = ({ dataKey, value, className }) => (
  <p
    className={cx(
      "flex items-center text-base leading-130 tracking-0.32",
      className
    )}
  >
    {dataKey === "revenue" ? "Revenue " : "Profit "}
    <FontAwesomeIcon
      icon={faCircleSmall}
      style={{
        fontSize: "5px",
        padding: "0 6px",
        "--fa-secondary-opacity": 1,
      }}
    />{" "}
    {value < 0 ? "-" : ""}${Math.abs(value).toLocaleString("en-US")}
  </p>
)

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white border border-charcoal-50 rounded p-2">
        <p className="text-base charcoal-500 font-semibold leading-130 tracking-0.32 mb-3">
          {label} years
        </p>
        <TooltipValueText
          dataKey={payload[0].dataKey}
          value={payload[0].value}
          className="text-leaf mb-1"
        />
        <TooltipValueText
          dataKey={payload[1].dataKey}
          value={payload[1].value}
          className="text-dusk"
        />
      </div>
    )
  }

  return null
}

const RoiChart = ({ roiData, isMobile, width, height, setActiveYear }) => {
  const chartData = useMemo(
    () =>
      getChartData({
        revenue: roiData.total_revenue,
        profit: roiData.total_profit,
      }),
    [roiData.total_revenue, roiData.total_profit]
  )

  // DEV: Set activeYear on chart dot onMouseOver event, so it can be used to center amd highlight the corresponding table row inside the table container.
  const handleMouseOver = (_, data) => {
    if (!isMobile) {
      setActiveYear(data.payload.year)
    }
  }

  return (
    <LineChart
      width={width}
      height={height}
      data={chartData}
      margin={{
        top: 20,
        right: 28,
        left: isMobile ? 28 : 102,
        bottom: 0,
      }}
    >
      <XAxis
        type="number"
        label={{
          value: "Years",
          fill: "#262624",
          stroke: "none",
          position: "insideBottomCenter",
          offset: 0,
          fontSize: 14,
          lineHeight: "130%",
          letterSpacing: "0.32px",
        }}
        axisLine={{ stroke: "#6B7280", strokeWidth: "1px" }}
        dataKey="year"
        height={90}
        domain={[0, "dataMax"]}
        tickLine={false}
        tickCount={6}
        dy={5}
        tick={{
          fill: "#6B7280",
          stroke: "none",
          fontWeight: "normal",
          strokeWidth: 0,
          fontSize: isMobile ? 12 : 16,
          lineHeight: "130%",
          letterSpacing: "0.32px",
        }}
      />

      <YAxis
        type="number"
        axisLine={false}
        domain={["dataMin", "auto"]}
        tickFormatter={(v) => {
          if (v < 0) {
            return `-$${Math.abs(v).toLocaleString("en-US")}`
          }
          return `$${v.toLocaleString("en-US")}`
        }}
        tickCount={6}
        dx={isMobile ? -10 : -50}
        tickLine={false}
        tick={{
          fill: "#6B7280",
          stroke: "none",
          fontWeight: "normal",
          strokeWidth: 0,
          fontSize: isMobile ? 12 : 16,
          lineHeight: "130%",
          letterSpacing: "0.32px",
        }}
      />

      <Tooltip content={<CustomTooltip />} />

      <Line
        type="monotone"
        dataKey="revenue"
        stroke="#008561"
        dot={false}
        activeDot={{ onMouseOver: handleMouseOver }}
      />

      <Line
        type="monotone"
        dataKey="profit"
        stroke="#979CA6"
        dot={false}
        activeDot={{ onMouseOver: handleMouseOver }}
      />
    </LineChart>
  )
}

export default RoiChart
