import cx from "classnames"

import { getTimePassedText } from "../../shared/utils"
import { NotificationTypes } from "@/types/notifications"
import { Dispatch, SetStateAction } from "react"
import { ActiveViewType } from "@/pages/Inbox"
import { UseMutateAsyncFunction } from "@tanstack/react-query"

interface MessagesTabsContentTypes {
  messages: NotificationTypes[] | undefined
  activeTab: string | number | null
  setActiveTab: Dispatch<SetStateAction<string | number | null>>
  setActiveView: Dispatch<SetStateAction<ActiveViewType>>
  viewNotification: UseMutateAsyncFunction<unknown, Error, unknown, unknown>
}

const MessagesTabsContent = ({
  messages,
  activeTab,
  setActiveTab,
  setActiveView,
  viewNotification,
}: MessagesTabsContentTypes) => {
  const handleClick = async (message: NotificationTypes) => {
    if (message?.viewed_at === null) {
      await viewNotification(message.id)
    }
  }

  return messages?.map((message) => (
    <div
      key={message.id}
      onClick={() => {
        setActiveTab(message.id)
        setActiveView("thread")
        handleClick(message)
      }}
      className={cx(
        "flex items-center gap-4 hover:bg-grass-50 border-b-1 border-dusk-50 cursor-pointer p-4",
        {
          "lg:bg-grass-50": activeTab === message.id,
        }
      )}
    >
      <div className="w-12 h-12 rounded shrink-0 overflow-hidden">
        <img
          src={message?.extra_fields?.image_url}
          alt="avatar"
          className="block object-cover w-12 h-12"
        />
      </div>
      <p className="text-leaf text-sm font-bold leading-130 tracking-0.14">
        {message?.extra_fields?.project_name}
      </p>
      <p className="text-dusk text-sm leading-130 tracking-0.14 ml-auto whitespace-nowrap">
        {getTimePassedText(message?.created_at)}
      </p>
    </div>
  ))
}

export default MessagesTabsContent
