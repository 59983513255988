import { capitalize } from "lodash"

const EligibilityQuizCategoryStats = ({ projCategoryStats }) => {
  const paymentOptionLabels = {
    fixed_cash: "fixed cash",
    revenue_share: "revenue share",
    cost_share: "cost share",
    per_unit: "per unit",
  }

  const statFormats = {
    potential_earnings_per_acre: {
      label: "Potential earnings per acre",
      format: (val) => {
        const min = val.min
        const max = val.max
        if (min === max) {
          return max === null ? "Variable" : `$${max}`
        }
        return `$${min}-$${max}`
      },
    },
    term_lengths: {
      label: "Term length",
      format: (val) => {
        const min = val.min
        const max = val.max
        if (min === max) {
          return max === null ? "Variable" : `${max} years`
        }
        return `${min}-${max} years`
      },
    },
    payment_options: {
      label: "Payment options",
      format: (options) => {
        let optionsString
        const labels = options.map((option) => paymentOptionLabels[option])
        if (options.length > 1) {
          const lastOption = labels.pop()
          optionsString = `${labels.join(", ")} or ${lastOption}`
        } else {
          optionsString = labels.join(", ")
        }
        return capitalize(optionsString)
      },
    },
  }

  return (
    projCategoryStats && (
      <div className="grid grid-cols-2 gap-x-8 gap-y-6 mt-8">
        {Object.entries(projCategoryStats).map(([key, value]) => {
          const statFormat = statFormats[key]
          return (
            <div key={key} className="flex flex-col gap-y-1">
              <div className="text-center text-black text-xl font-medium">
                {statFormat.format(value)}
              </div>
              <div className="text-center text-leaf text-base font-bold">
                {statFormat.label}
              </div>
            </div>
          )
        })}
      </div>
    )
  )
}

export default EligibilityQuizCategoryStats
