import { DialogDisclosure } from "reakit/Dialog"

const ProjectNpv = ({ roiData, roiDialog }) => {
  return (
    <div className="w-full lg:w-396 flex justify-between gap-4 border border-dusk-50 rounded p-4 mb-8">
      <div className="flex flex-col">
        <p className="text-xl text-charcoal-500 font-bold leading-120 tracking-0.4 mb-1">
          ${roiData.project_npv.toLocaleString("en-US")}
        </p>
        <p className="text-sm text-dusk-700 leading-130 tracking-0.14">
          Time adjusted value
        </p>
      </div>

      <div className="h-6 flex items-center shrink-0">
        <DialogDisclosure
          {...roiDialog}
          className="link text-sm font-semibold leading-130 tracking-0.14"
        >
          View Calculator
        </DialogDisclosure>
      </div>
    </div>
  )
}

export default ProjectNpv
