import { Form } from "formik"

import MultiSelect from "../../components/MultiSelect"
import { ActionPermissionWrapper } from "../../components/ActionPermissionWrapper"
import RequestInformationBtn from "./RequestInformationBtn"
import { CONTACT_METHODS } from "../../shared/constants"
import { DialogDisclosure } from "reakit"
import { WrappedInput } from "../../components/Input"

const PreferredContactMethodForm = ({
  dialog,
  notInterestedDialog,
  accountRole,
  formikProps,
  enrollmentDeadlineType,
  requires_service_provider,
  ctaOverride,
  isValidPhoneNumber,
  landowner_status,
  projectId,
  accountId,
  ctaOverrideUrl,
}) => {
  const showPhoneField =
    !isValidPhoneNumber &&
    (formikProps.values.preferred_contact_method.includes("phone") ||
      formikProps.values.preferred_contact_method.includes("text"))

  return (
    <Form>
      <div className="flex flex-col w-full max-w-614 border border-dusk-50 rounded p-6 mt-5 mb-6">
        <p className="text-overline">ADD YOUR PREFERRED CONTACT METHOD</p>

        <p className="text-base text-charcoal-500 leading-130 tracking-0.32 mt-3">
          When you request a call, NCX will share your preferred contact method
          and information with{" "}
          {!requires_service_provider ? "this program partner" : "a forester"}.
          You can update this at anytime in Account Settings.
        </p>

        <div className="w-full flex flex-col md:grid md:grid-cols-2 gap-4 mt-6">
          <div>
            <MultiSelect
              labelClass="label font-bold mb-2 leading-18px"
              aria-label="Preferred contact method"
              label="Preferred contact method"
              name="preferred_contact_method"
              placeholder="Select contact preference"
              options={Object.entries(CONTACT_METHODS).map(
                ([value, label]) => ({
                  label,
                  value,
                })
              )}
            />
          </div>
          {showPhoneField ? (
            <div>
              <WrappedInput
                labelClass="label font-bold mb-2 leading-18px"
                aria-label="Phone number"
                label="Phone number"
                placeholder="Phone number"
                type="text"
                name="phone_number"
              />
            </div>
          ) : null}
        </div>
      </div>

      <hr className="border-charcoal-50 mb-6 lg:max-w-624 xl:max-w-664" />

      <div className="flex gap-4">
        <ActionPermissionWrapper accountRole={accountRole} action="editAccount">
          <DialogDisclosure
            {...notInterestedDialog}
            className="btn2 btn2-outline-primary bg-cloud-50 text-base !font-bold py-7px not-interested-btn-project-modal"
          >
            Not Interested
          </DialogDisclosure>
        </ActionPermissionWrapper>

        <ActionPermissionWrapper accountRole={accountRole} action="editAccount">
          <RequestInformationBtn
            landowner_status={landowner_status}
            projectId={projectId}
            accountId={accountId}
            sourceIdentifier="project-modal"
            accountRole={accountRole}
            enrollmentDeadlineType={enrollmentDeadlineType}
            ctaOverride={ctaOverride}
            ctaOverrideUrl={ctaOverrideUrl}
            formikProps={formikProps}
          />
        </ActionPermissionWrapper>
      </div>

      <button
        className="block text-leaf font-semibold hover:underline focus:outline-none mb-3 mt-3 skip-btn-project-modal"
        type="button"
        onClick={dialog.hide}
      >
        Skip for now
      </button>
    </Form>
  )
}

export default PreferredContactMethodForm
