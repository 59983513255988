import { Link } from "react-router-dom"

import StayInTheLoop from "./StayInTheLoop"
import IMG from "../../images/satellite.png"

const AssessmentInProgress = ({ accountId, isMultiAccount }) => (
  <>
    <h3 className="text-xl mb-4">
      We're assessing your land for potential opportunities
    </h3>
    <div className="mb-4">
      <img src={IMG} alt="" className="w-full h-auto rounded" />
    </div>
    <div className="mt-2 mb-4 space-y-p">
      <p>
        NCX uses satellite and map data to pre-qualify your property for
        programs.
      </p>
    </div>
    <div>
      <Link
        to={
          isMultiAccount
            ? `/accounts/${accountId}/programs/page/1`
            : "/programs/page/1"
        }
        className="btn2 btn2-primary font-semibold"
      >
        View programs
      </Link>
    </div>
    <StayInTheLoop />
  </>
)

export default AssessmentInProgress
