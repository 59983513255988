import { useState } from "react"
import { Link, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/pro-duotone-svg-icons"

import EligibilityQuizForm from "./EligibilityQuizForm"
import EligibilityQuizCompleted from "./EligibilityQuizCompleted"
import useViewport from "../../hooks/useViewport"
import { useAccountUrlPrefix } from "../../hooks"

const EligibilityQuizSteps = ({
  quizesData,
  attestations,
  quizData,
  quizStep,
  setQuizStep,
}) => {
  const { quizCategory } = useParams()
  const accountUrlPrefix = useAccountUrlPrefix()
  const [completed, setCompleted] = useState(false)
  const [numEligible, setNumEligible] = useState(undefined)
  const [projCategoryStats, setProjCategoryStats] = useState(undefined)
  const { width } = useViewport()

  const isMobile = width < 768

  const imageUrl =
    typeof attestations[quizStep]?.image_url === "string"
      ? attestations[quizStep]?.image_url
      : quizData.image_url

  const someNotCompleted = Object.values(quizesData).some(
    (quiz) => quiz.completed === false
  )

  return (
    <div className="pb-24 sm:pb-0">
      <div className="flex justify-end px-4 sm:px-[72px] py-4 sm:pt-12 sm:pb-[76px]">
        <Link
          to={`${accountUrlPrefix}/programs/page/1`}
          className="text-charcoal-900"
          state={{ quizCategory: completed ? quizCategory : undefined }}
        >
          <FontAwesomeIcon
            icon={faXmark}
            style={{
              "--fa-primary-opacity": 0,
              "--fa-secondary-opacity": 1,
            }}
          />
        </Link>
      </div>

      <div className="container xl:max-w-[1440px] flex justify-between gap-8">
        <div className="hidden md:block w-auto max-w-[528px]">
          <img
            src={imageUrl}
            alt={quizData.quiz_type}
            className="w-auto max-h-[502px]"
          />
        </div>

        <div className="md:w-[500px] max-w-full">
          {completed ? (
            <EligibilityQuizCompleted
              someNotCompleted={someNotCompleted}
              numEligible={numEligible}
              projCategoryStats={projCategoryStats}
              quizData={quizData}
              isMobile={isMobile}
              setQuizStep={setQuizStep}
              setCompleted={setCompleted}
            />
          ) : (
            <EligibilityQuizForm
              attestations={attestations}
              quizData={quizData}
              quizStep={quizStep}
              setQuizStep={setQuizStep}
              setCompleted={setCompleted}
              setNumEligible={setNumEligible}
              setProjCategoryStats={setProjCategoryStats}
              isMobile={isMobile}
              imageUrl={imageUrl}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default EligibilityQuizSteps
