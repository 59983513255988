import { Form, FormikProps } from "formik"

import { ButtonPair } from "../../components/ButtonPair"
import { SubmitButton } from "../../components/SubmitButton"
import { CONTACT_METHODS } from "../../shared/constants"
import { WrappedInput } from "../../components/Input"
import { DialogStateReturn } from "reakit"

interface AcceptModalFormTypes {
  formikProps: FormikProps<{
    contact_method: string
    phone_number: string
    email: string
  }>
  dialog: DialogStateReturn
}

const AcceptModalForm = ({ formikProps, dialog }: AcceptModalFormTypes) => {
  const showPhoneField =
    formikProps.values.contact_method.includes("phone") ||
    formikProps.values.contact_method.includes("text")

  const showEmailField = formikProps.values.contact_method.includes("email")

  return (
    <Form className="space-y-6">
      <div>
        <WrappedInput
          className="resize-none border-charcoal-50"
          labelClass="label font-bold mb-2 leading-18px"
          aria-label="Contact method"
          label="Contact method"
          name="contact_method"
          placeholder="Select a contact preference"
          as="select"
          options={Object.entries(CONTACT_METHODS).map(([value, label]) => ({
            label,
            value,
          }))}
        />
      </div>

      {showPhoneField ? (
        <div>
          <WrappedInput
            labelClass="label font-bold mb-2 leading-18px"
            aria-label="Phone number"
            label="Phone number"
            placeholder="Phone number"
            type="text"
            name="phone_number"
          />
        </div>
      ) : null}

      {showEmailField ? (
        <div>
          <WrappedInput
            labelClass="label font-bold mb-2 leading-18px"
            label="Email Address"
            placeholder="email@emailtree.com"
            type="text"
            name="email"
            as="email"
          />
        </div>
      ) : null}

      <ButtonPair
        // eslint-disable-next-line react/no-unstable-nested-components
        primary={(primaryProps) => (
          <SubmitButton
            disabled={!(formikProps.isValid && formikProps.dirty)}
            isSubmitting={formikProps.isSubmitting}
            {...primaryProps}
          >
            Accept
          </SubmitButton>
        )}
        secondary={
          <button type="button" onClick={dialog.hide}>
            Go Back
          </button>
        }
      />
    </Form>
  )
}

export default AcceptModalForm
