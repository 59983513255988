import { useEffect, useState } from "react"
import mapboxglSupported from "@mapbox/mapbox-gl-supported"

import { MapboxGLNotSupported } from "../../components/MapVisualization"
import ProjectMapVisualization from "../../sections/ProjectDetails/ProjectMap/ProjectMapVisualization"
import ProjectMapParcels from "../../sections/ProjectDetails/ProjectMap/ProjectMapParcels"
import ProjectMapSkeleton from "../../sections/ProjectDetails/ProjectMap/ProjectMapSkeleton"
import { getViewportFromFeature } from "../../sections/ProjectDetails/ProjectMap/helpers"
import { genericErrMsg } from "../../api/auth"
import { useGetParcelGeoms } from "../../hooks/index"
import useViewport from "../../hooks/useViewport"
import { handleNextFeature, handlePrevFeature } from "./helpers"

// This is similar to ProjectMap except removing project-related eligibility elements
const PropertyMap = ({ accountId, footer }) => {
  const [activeFeature, setActiveFeature] = useState(0)
  const [viewport, setViewport] = useState()
  const { width } = useViewport()

  const isMobile = width < 768

  // DEV: we only care about parcel data because this isn't about eligibility, just a parcel viewer
  const { data: parcelData, isLoading: parcelIsLoading } =
    useGetParcelGeoms(accountId)

  useEffect(() => {
    if (parcelData !== undefined) {
      setViewport(getViewportFromFeature(parcelData?.features[0]))
    }
  }, [parcelData])

  if (!mapboxglSupported.supported()) {
    const reason = mapboxglSupported.notSupportedReason()

    return (
      <div className="container xl:max-w-1120 mt-[66px]">
        <div className="lg:max-w-[844px] h-[400px] lg:flex relative rounded bg-gray2 overflow-hidden p-6">
          <MapboxGLNotSupported reason={reason} />
        </div>
      </div>
    )
  }

  return (
    <div className="card card-content relative my-4 p-0">
      <div className="lg:flex relative rounded">
        {parcelIsLoading ? (
          <ProjectMapSkeleton animate />
        ) : parcelData ? (
          <>
            {isMobile ? footer : null}
            <ProjectMapVisualization
              viewport={viewport}
              setViewport={setViewport}
              parcelData={parcelData}
              activeFeature={activeFeature}
              isIneligible={false}
              enableEligibility={false}
            />

            <ProjectMapParcels
              isIneligible={false}
              parcelData={parcelData}
              activeFeature={activeFeature}
              setActiveFeature={setActiveFeature}
              setViewport={setViewport}
              onNextFeatureClick={handleNextFeature}
              onPrevFeatureClick={handlePrevFeature}
              enableEligibility={false}
              className="property-map-landcover-report"
            />
          </>
        ) : (
          <ProjectMapSkeleton
            message={
              <div className="lg:max-w-[844px] h-[400px] lg:flex relative rounded bg-gray2 overflow-hidden p-6">
                <div className="bg-gray2 w-full h-full">
                  <div className="w-1/2 mx-auto mt-1/4 bg-white p-12">
                    {genericErrMsg}
                  </div>
                </div>
              </div>
            }
          />
        )}
      </div>
      {!isMobile ? footer : null}
    </div>
  )
}

export default PropertyMap
