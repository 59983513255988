import React, { useContext, useState } from "react"
import { useLocation } from "react-router-dom"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { OptimizelyContext } from "@optimizely/react-sdk"

import { genericErrMsg } from "../../api/auth"
import {
  identifyUserForIntegrations,
  sendAnalyticsEvent,
} from "../../api/integrations"
import { ErrorCard } from "../../components/Card"
import { SubmitButton } from "../../components/SubmitButton"
import { WrappedInput } from "../../components/Input"
import { useCreateUser } from "../../hooks"
import NCX from "../../images/ncx-login-logo.png"

const FormComponent = ({
  values, // form values
  errorMessage,
  isSubmitting,
  setAuthData,
}) => {
  const [showPassword, setShowPassword] = useState(false)

  function handleBlur() {
    setAuthData({
      email: values.email,
      password: values.password,
    })
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <>
      <div className="flex justify-center mb-4">
        <img
          src={NCX}
          alt="NCX Logo"
          className="block w-auto h-[51px] lg:h-[46px]"
        />
      </div>

      <h2 className="text-center text-lg leading-130 tracking-0.378 mb-2">
        Welcome to the NCX Pro Network
      </h2>

      <p className="text-center text-base leading-130 tracking-0.32 mb-4">
        Sign up for a free account
      </p>

      <Form className="space-y-4" onBlur={handleBlur}>
        {errorMessage && <ErrorCard>{errorMessage}</ErrorCard>}
        <div className="space-y-4">
          <div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-1">
                <WrappedInput
                  data-test="input-first-name"
                  label="First name"
                  name="first_name"
                  placeholder="First name"
                  type="text"
                />
              </div>
              <div className="space-y-1">
                <WrappedInput
                  data-test="input-last-name"
                  label="Last name"
                  name="last_name"
                  placeholder="Last name"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="space-y-1">
            <WrappedInput
              data-test="input-email"
              label="Email address"
              name="email"
              placeholder="name@example.com"
              type="text"
              as="email"
            />
          </div>
          <div className="space-y-1">
            <WrappedInput
              data-test="input-password"
              label="Password"
              name="password"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              as="password"
              toggleShowPassword={toggleShowPassword}
              instructions="Password must have at least 8 characters and include a mix of letters and numbers."
            />
          </div>

          <div>
            <SubmitButton
              className="btn2 btn2-primary btn2-block font-semibold mt-6"
              isSubmitting={isSubmitting}
              data-test="sign-up"
            >
              Create Account
            </SubmitButton>
          </div>

          <div className="text-base text-dusk-500 text-center">
            By creating an account, you agree to the NCX{" "}
            <a
              href="https://ncx.com/terms/"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="https://ncx.com/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              Privacy Policy
            </a>
            .
          </div>
          <div className="text-base text-dusk-500 text-center">
            NCX Pro Network{" "}
            <a
              href="https://help.ncx.com/hc/en-us/articles/24976346232091-NCX-Pro-Network-Terms-Conditions"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              Terms & Conditions
            </a>{" "}
            apply.
          </div>
          <hr className="border-charcoal-50" />
          <div className="text-center text-dusk">
            Need help?{" "}
            <a
              className="link--underline-only text-base text-dusk-500"
              href="mailto:landowners@ncx.com"
              data-test="having-trouble"
            >
              <span className="link">landowners@ncx.com</span>
            </a>
          </div>
        </div>
      </Form>
    </>
  )
}

const validationSchema = Yup.object().shape({
  profile_type: Yup.string().required("Please choose an account type."),
  first_name: Yup.string().required("Please provide your first name."),
  last_name: Yup.string().required("Please provide your last name."),
  email: Yup.string()
    .email("Please provide a valid email address.")
    .required("Please provide your email address."),
  password: Yup.string()
    .required("Please provide a password.")
    .min(8, "Password must be at least 8 characters."),
})

const SignUpForm = ({
  authData,
  inputEmail,
  setAuthData,
  setIsUserCreated,
}) => {
  const [errorMessage, setErrorMessage] = useState(null)

  const { optimizely } = useContext(OptimizelyContext)

  const location = useLocation()

  const { mutateAsync: createUser } = useCreateUser({
    onSuccess: (data, variables /* from onSubmit's values */) => {
      identifyUserForIntegrations(variables.email, optimizely)
      sendAnalyticsEvent({ action: "signup", label: "Landowners Signup" })
      setIsUserCreated(true)
    },
    onError: (error) => {
      setErrorMessage(error?.detail || genericErrMsg)
    },
  })

  const onSubmit = async (_values) => {
    const values = { ..._values }
    await createUser(values)
  }

  return (
    <Formik
      initialValues={{
        profile_type: "multi_account",
        first_name: "",
        last_name: "",
        email: inputEmail || "",
        password: "",
        ...authData,
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      children={(formikProps) =>
        FormComponent({
          ...formikProps,
          errorMessage,
          setAuthData,
          location,
        })
      }
    />
  )
}
export default SignUpForm
