import StayInTheLoop from "./StayInTheLoop"
import IMG from "../../images/created-content.png"

const IneligibleContent = () => (
  <>
    <h3 className="text-xl mb-4">
      No eligible programs available at this time
    </h3>
    <div className="mb-4">
      <img src={IMG} alt="" className="w-full h-auto" />
    </div>
    <div className="mt-2 mb-4 space-y-p text-base">
      <p>
        Based on the land you submitted, you are not eligible for any current
        program opportunities. If you have additional landholdings, make sure to
        add all your property to get re-assessed.
      </p>
      <p>
        New opportunities are added regularly, so check back soon! We'll also
        notify you over email if you qualify for a new program.
      </p>
    </div>
    <StayInTheLoop />
  </>
)

export default IneligibleContent
