import React, { ReactElement } from "react"
import { useQueryClient } from "@tanstack/react-query"
import cx from "classnames"
import { DialogStateReturn } from "reakit"

import PropertyContainer from "../containers/PropertyContainer"
import { Spinner } from "../components/Spinner"
import { useAccountId, useAccount } from "../hooks"
import { transformBounds } from "../shared/utils"
import { AccountTypes } from "../types/account"
import { AccountProperty } from "../types/property"

interface PropertyTypes {
  backLink?: ReactElement | undefined
  onSuccess: () => void
  property: AccountProperty
  hidden?: boolean
  submitText: string
  uploadDialog: DialogStateReturn
}

export const Property = ({
  backLink,
  onSuccess,
  property,
  hidden,
  submitText,
  uploadDialog,
}: PropertyTypes) => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const { data: account, status: accountStatus } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  if (accountStatus !== "success") {
    return (
      <div className={cx("absolute inset-0 bg-white", { invisible: hidden })}>
        <div className="h-full flex items-center justify-center">
          <Spinner />
        </div>
      </div>
    )
  }

  return (
    <>
      {accountStatus === "success" && (
        <PropertyContainer
          account={account}
          initialFeatures={transformBounds(property?.bounds)}
          acreage={property?.acreage || 0}
          backLink={backLink}
          onSuccess={onSuccess}
          submitText={submitText}
          uploadDialog={uploadDialog}
        />
      )}
    </>
  )
}

export default Property
