import { Form, Formik } from "formik"
import * as yup from "yup"
import { useQueryClient } from "@tanstack/react-query"

import { Modal } from "../../components/Modal"
import MultiSelect from "../../components/MultiSelect"
import { WrappedInput } from "../../components/Input"
import { ButtonPair } from "../../components/ButtonPair"
import { SubmitButton } from "../../components/SubmitButton"
import { Toast } from "../../components/Toast"
import { NOT_INTERESTED_REASONS } from "../../shared/constants"
import { useUpdateAccountProject } from "../../hooks"

const OPTIONS = [
  {
    value: NOT_INTERESTED_REASONS.KEY_OBLIGATION,
    label: "Key obligation",
  },
  { value: NOT_INTERESTED_REASONS.TERM_LENGTH, label: "Term length" },
  {
    value: NOT_INTERESTED_REASONS.LANDOWNER_COSTS,
    label: "Landowner costs",
  },
  {
    value: NOT_INTERESTED_REASONS.ELIGIBILITY_REQUIREMENTS,
    label: "Eligibility requirements",
  },
  {
    value: NOT_INTERESTED_REASONS.EARNINGS_AND_PENALTIES,
    label: "Earnings and penalties",
  },
  { value: NOT_INTERESTED_REASONS.RESTRICTIONS, label: "Restrictions" },
  {
    value: NOT_INTERESTED_REASONS.LANDOWNER_RIGHTS,
    label: "Landowner rights",
  },
  {
    value: NOT_INTERESTED_REASONS.PROPERTY_ACCESS,
    label: "Property access",
  },
  {
    value: NOT_INTERESTED_REASONS.OTHER,
    label: "Other",
  },
]

const REASONS = "reasons"
const MORE_DETAILS = "more_details"

const validationSchema = yup.object().shape({
  [REASONS]: yup
    .array()
    .min(1, "At least one reason is required")
    .of(yup.string().required())
    .required(),
  [MORE_DETAILS]: yup.string().when(REASONS, (reasons, schema) => {
    return reasons.includes(NOT_INTERESTED_REASONS.OTHER)
      ? yup.string().required("Please fill out this field.")
      : schema
  }),
})

const FormComponent = ({ formikProps, dialog }) => (
  <Form className="inline">
    <div className="mb-6">
      <MultiSelect
        name={REASONS}
        label="Reason for declining"
        labelClass="label font-bold mb-2 leading-18px"
        options={OPTIONS}
        aria-label="Reason for declining"
      />
    </div>
    <div className="mb-6">
      <WrappedInput
        label={`Why aren't you interested in this program${
          !formikProps.values[REASONS].includes(NOT_INTERESTED_REASONS.OTHER)
            ? " (optional)"
            : ""
        }`}
        labelClass="leading-18px"
        name={MORE_DETAILS}
        as="textarea"
        placeholder="Add in more details about why this opportunity did not interest you."
        className="mt-2 resize-none border-charcoal-50"
        aria-label="Why aren't you interested in this program?"
      />
    </div>
    <ButtonPair
      // eslint-disable-next-line react/no-unstable-nested-components
      primary={(primaryProps) => (
        <SubmitButton
          disabled={!(formikProps.isValid && formikProps.dirty)}
          {...primaryProps}
        >
          Submit Feedback
        </SubmitButton>
      )}
      secondary={
        <button type="button" onClick={dialog.hide}>
          Go Back
        </button>
      }
    />
  </Form>
)

const NotInterestedModal = ({ dialog, accountId, projectId, onClose }) => {
  const queryClient = useQueryClient()
  const { mutateAsync: updateAccountProject } = useUpdateAccountProject(
    queryClient,
    accountId,
    projectId,
    {
      onSuccess: () => {
        dialog.hide()
        Toast.success(
          "Your feedback about this program has been successfully sent."
        )
        onClose && onClose()
      },
      onError: (error) => {
        dialog.hide()
        Toast.error(
          error?.detail || "An error occurred while updating your preferences."
        )
      },
    }
  )

  const handleSubmit = async (values) => {
    const newValues = {
      status: "not_interested",
      not_interested_reasons: values.reasons,
      not_interested_reason_additional: values?.more_details,
    }
    await updateAccountProject(newValues)
  }

  return (
    <Modal
      header="Not interested? Tell us more."
      aria-label="Not interested? Tell us more."
      dialog={dialog}
      className="max-w-400 p-5"
    >
      <p className="mb-4">
        Select a reason for declining interest in this opportunity and tell us
        more about your decision.
      </p>
      {/* DEV: Force formik re-render to reset initial values on cached projects */}
      {dialog.visible === true ? (
        <Formik
          initialValues={{
            [REASONS]: [],
            [MORE_DETAILS]: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <FormComponent formikProps={formikProps} dialog={dialog} />
          )}
        </Formik>
      ) : null}
    </Modal>
  )
}

export default NotInterestedModal
