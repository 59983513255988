import { DialogDisclosure } from "reakit/Dialog"
import cx from "classnames"

import RequestInformationBtn from "./RequestInformationBtn"
import ModalTextOnlyLayout from "./ModalTextOnlyLayout"
import ModalImageLayout from "./ModalImageLayout"
import { ButtonPair } from "../../components/ButtonPair"
import { ActionPermissionWrapper } from "../../components/ActionPermissionWrapper"
import SimilarProgramsSlider from "../../components/SimilarProgramsSlider"
import { DEADLINE_TYPE, LANDOWNER_STATUS } from "../../shared/constants"
import SENDING from "../../images/sending.svg"

export const STEPS = {
  ATTESTATION: {
    key: "ATTESTATION",
    renderHeadline: ({ attestationsHaveVal }) => (
      <div className="max-w-643">
        <h2 className="flex-auto text-xl leading-6 mb-2">
          {`${
            attestationsHaveVal ? "Edit" : "Add"
          } eligibility details to request a call`}
        </h2>
        <p className="text-base leading-snug">
          You might qualify for this opportunity based on your property details,
          but we need more information to assess your eligibility. If eligible,
          NCX will share your contact information with the program partner.
        </p>
      </div>
    ),
    // this will be the form.
    // rather than pass in all the variables needed for form, we render it in the main function below
    renderBody: () => {},
    renderActions: () => {},
  },
  ELIGIBLE: {
    key: "ELIGIBLE",
    renderHeadline: ({ enrollmentDeadlineType }) => (
      <h2 className="flex-auto text-xl leading-6">
        You are eligible! Next up,{" "}
        {enrollmentDeadlineType === DEADLINE_TYPE.pending_interest
          ? "join the waitlist to be notified when enrollment opens."
          : "request a call to learn more about participation."}
      </h2>
    ),
    renderBody: ({ enrollmentDeadlineType, img }) => {
      if (enrollmentDeadlineType === DEADLINE_TYPE.pending_interest) {
        return (
          <ModalImageLayout heading="Next Steps" img={img}>
            <ol className="ordered-list mt-6">
              <li>Join the waitlist for this program. </li>
              <li>Get notified when program enrollment opens.</li>
              <li className="!mb-6">
                Talk with the program partner to discover if this program is
                right for you and your land by getting answers to your
                questions. This is a no commitment way to learn more.
              </li>
            </ol>
          </ModalImageLayout>
        )
      }

      return (
        <ModalImageLayout heading="Up Next" img={img}>
          <p className="text-base leading-snug">
            Based on the information you added, you're eligible for this
            program.
          </p>
          <p className="text-base leading-snug">
            To learn more about how to participate, request a call below to talk
            with the program partner. When you request information, NCX will
            share your interest and contact information with the program
            partner. This is your time to chat more about the program, ask
            questions, and determine if it is the right fit for you and your
            land.
          </p>
          <p className="text-base leading-snug">
            Requesting a call does not require any commitment.
          </p>
        </ModalImageLayout>
      )
    },
    renderActions: ({
      landowner_status,
      accountId,
      projectId,
      eligibilityDialog,
      onRequestCallSubmit,
      notInterestedDialog,
      accountRole,
      enrollmentDeadlineType,
      ctaOverride,
      ctaOverrideUrl,
    }) => (
      <>
        <hr className="border-charcoal-50 lg:max-w-624 xl:max-w-664" />
        <ButtonPair
          className="mx-auto sm:mx-0 mt-6"
          primary={
            <RequestInformationBtn
              landowner_status={landowner_status}
              projectId={projectId}
              accountId={accountId}
              onRequestCallSubmit={onRequestCallSubmit}
              sourceIdentifier="project-modal"
              accountRole={accountRole}
              enrollmentDeadlineType={enrollmentDeadlineType}
              ctaOverride={ctaOverride}
              ctaOverrideUrl={ctaOverrideUrl}
            />
          }
          secondary={(secondaryProps) => (
            <ActionPermissionWrapper
              accountRole={accountRole}
              action="editAccount"
            >
              <DialogDisclosure
                {...notInterestedDialog}
                className={cx(
                  secondaryProps.className,
                  "bg-cloud-50 text-base !font-bold py-7px not-interested-btn-project-modal"
                )}
              >
                Not Interested
              </DialogDisclosure>
            </ActionPermissionWrapper>
          )}
        />
        <button
          className="block text-leaf font-semibold hover:underline focus:outline-none mb-3 mt-3 skip-btn-project-modal"
          type="button"
          onClick={eligibilityDialog.hide}
        >
          Skip for now
        </button>
      </>
    ),
  },
  INELIGIBLE: {
    key: "INELIGIBLE",
    renderHeadline: () => (
      <h2 className="flex-auto text-xl leading-6 mb-2">
        You are ineligible for this program
      </h2>
    ),
    renderBody: () => (
      <ModalTextOnlyLayout>
        <p className="text-base leading-snug">
          You are ineligible for this program based on your eligibility details.
          Express your interest below to help us find new opportunities like
          this for you.
        </p>
      </ModalTextOnlyLayout>
    ),
    renderActions: ({
      landowner_status,
      ineligibleDialog,
      notInterestedDialog,
      accountRole,
      eligibilityDialog,
    }) => (
      <>
        <ButtonPair
          className="mx-auto sm:mx-0 mt-6"
          primary={
            <DialogDisclosure
              {...ineligibleDialog}
              className={cx("text-base interested-btn-project-modal", {
                enabled:
                  landowner_status !== LANDOWNER_STATUS.request_information,
              })}
              disabled={
                landowner_status === LANDOWNER_STATUS.request_information
              }
            >
              {`${
                landowner_status === LANDOWNER_STATUS.request_information
                  ? "Interested"
                  : "I'm Interested"
              }`}
            </DialogDisclosure>
          }
          secondary={(secondaryProps) => (
            <ActionPermissionWrapper
              accountRole={accountRole}
              action="editAccount"
            >
              <DialogDisclosure
                {...notInterestedDialog}
                className={cx(
                  secondaryProps.className,
                  "bg-cloud-50 text-base !font-bold py-7px not-interested-btn-project-modal"
                )}
              >
                Not Interested
              </DialogDisclosure>
            </ActionPermissionWrapper>
          )}
        />
        <button
          className="block text-leaf font-semibold hover:underline focus:outline-none mb-3 mt-3 skip-btn-project-modal"
          type="button"
          onClick={eligibilityDialog.hide}
        >
          Skip for now
        </button>
      </>
    ),
  },
  ASSESSING: {
    key: "ASSESSING",
    renderHeadline: () => (
      <h2 className="flex-auto text-xl leading-6 mb-2">
        You are being assessed for this program
      </h2>
    ),
    renderBody: () => (
      <ModalTextOnlyLayout>
        <p className="text-base leading-snug">
          Thanks for your interest in this program! NCX is currently assessing
          you for this program based on the information you added. We will be in
          touch with more details after your assessment is complete.
        </p>
        <p className="text-base leading-snug">
          You can request a call below to talk with the program partner. When
          you request information, NCX will share your interest and contact
          information with the program partner if we find you are eligible after
          assessment.
        </p>
      </ModalTextOnlyLayout>
    ),
    renderActions: ({
      landowner_status,
      accountId,
      projectId,
      eligibilityDialog,
      onRequestCallSubmit,
      notInterestedDialog,
      accountRole,
      enrollmentDeadlineType,
      ctaOverride,
      ctaOverrideUrl,
    }) => (
      <>
        <ButtonPair
          className="mx-auto sm:mx-0"
          primary={
            <RequestInformationBtn
              landowner_status={landowner_status}
              projectId={projectId}
              accountId={accountId}
              onRequestCallSubmit={onRequestCallSubmit}
              sourceIdentifier="project-modal-still-assessing"
              accountRole={accountRole}
              enrollmentDeadlineType={enrollmentDeadlineType}
              ctaOverride={ctaOverride}
              ctaOverrideUrl={ctaOverrideUrl}
            />
          }
          secondary={(secondaryProps) => (
            <ActionPermissionWrapper
              accountRole={accountRole}
              action="editAccount"
            >
              <DialogDisclosure
                {...notInterestedDialog}
                className={cx(
                  secondaryProps.className,
                  "bg-cloud-50 text-base !font-bold py-7px not-interested-btn-project-modal-still-assessing"
                )}
              >
                Not Interested
              </DialogDisclosure>
            </ActionPermissionWrapper>
          )}
        />
        <button
          className="block text-leaf font-semibold hover:underline focus:outline-none mb-3 mt-3 skip-btn-project-modal-still-assessing"
          type="button"
          onClick={eligibilityDialog.hide}
        >
          Skip for now
        </button>
      </>
    ),
  },
  CALL_CONFIRMED: {
    key: "CALL_CONFIRMED",
    renderHeadline: ({
      enrollmentDeadlineType,
      requires_service_provider,
      has_service_provider_coverage,
    }) => (
      <h2 className="flex-auto text-xl text-center leading-6 mb-2">
        {enrollmentDeadlineType === DEADLINE_TYPE.pending_interest ||
        (requires_service_provider && !has_service_provider_coverage)
          ? "Congratulations! You're on the waitlist."
          : requires_service_provider && has_service_provider_coverage
            ? "Congratulations! You have requested a call with a forester."
            : "Congratulations! You have requested a call with the program partner."}
      </h2>
    ),
    renderBody: ({
      enrollmentDeadlineType,
      otherPrograms,
      isMultiAccount,
      accountId,
      requires_service_provider,
      has_service_provider_coverage,
      eligibilityDialog,
    }) => (
      <>
        <div className="lg:max-w-624 xl:max-w-664 mx-auto text-center">
          <p className="text-overline mb-2">What's Next</p>
          {enrollmentDeadlineType === DEADLINE_TYPE.pending_interest ? (
            <p className="text-base leading-130 tracking-0.32">
              Once the enrollment for this program is open, we'll notify you and
              share your contact information and preferences with this program
              partner.
            </p>
          ) : requires_service_provider && !has_service_provider_coverage ? (
            <p className="text-base leading-130 tracking-0.32">
              When a forester starts servicing your area, we'll share your
              contact information.
            </p>
          ) : requires_service_provider && has_service_provider_coverage ? (
            <p className="text-base leading-130 tracking-0.32">
              Your information is on the way to a forester. They will review
              your request and be in touch soon to discuss their services and
              your goals in more detail.
            </p>
          ) : (
            <p className="text-base leading-130 tracking-0.32">
              NCX will share your contact information with the program partner.
              They will review your request and be in touch to discuss the
              program in more detail. If you are no longer interested in
              learning about this program and discussing participation, you can
              select 'Not Interested' on the program listing page.
            </p>
          )}

          {(enrollmentDeadlineType === DEADLINE_TYPE.pending_interest ||
            (requires_service_provider && !has_service_provider_coverage)) &&
          otherPrograms?.length > 0 ? (
            <hr className="lg:max-w-624 xl:max-w-664 border-charcoal-50 my-6" />
          ) : (
            <img
              src={SENDING}
              className="d-block mx-auto mb-12 mt-12"
              alt="sending info"
            />
          )}
        </div>

        {otherPrograms?.length > 0 ? (
          <div className="sm:flex">
            <SimilarProgramsSlider
              programs={otherPrograms}
              isMultiAccount={isMultiAccount}
              accountId={accountId}
              className="similar-programs-slider-centered"
              cardClassName={
                enrollmentDeadlineType === DEADLINE_TYPE.pending_interest
                  ? "similar-program"
                  : "stackable-program"
              }
              header={
                <div className="lg:max-w-624 xl:max-w-664 mx-auto text-center px-5 sm:px-0">
                  <p className="text-overline mb-2">
                    {enrollmentDeadlineType === DEADLINE_TYPE.pending_interest
                      ? "Discover Similar Programs"
                      : "Add stackables to increase your earnings per acre"}
                  </p>

                  <p className="text-base leading-130 tracking-0.32 mb-6">
                    {enrollmentDeadlineType === DEADLINE_TYPE.pending_interest
                      ? "Keep browsing similar programs that you may be eligible for."
                      : "Stackables are programs that you can participate in parallel with this program."}
                  </p>
                </div>
              }
              showMinimalInfo={
                !(enrollmentDeadlineType === DEADLINE_TYPE.pending_interest)
              }
              eligibilityDialog={eligibilityDialog}
            />
          </div>
        ) : null}
      </>
    ),
    renderActions: () => {},
  },
  OUTOFCOVERAGE: {
    key: "OUTOFCOVERAGE",
    renderHeadline: () => (
      <h2 className="flex-auto text-xl leading-6">
        Thanks for your interest! Your area is currently on a waitlist.
      </h2>
    ),
    renderBody: ({ img }) => {
      return (
        <ModalImageLayout heading="Next Steps" img={img}>
          <ol className="ordered-list mt-6">
            <li>
              Join the waitlist to connect with a forester in your service area.{" "}
            </li>
            <li>Get matched with a forester through NCX at no cost.</li>
            <li className="!mb-6">
              Talk with a forester and determine what services you're interested
              in to become a more informed steward of your land.
            </li>
          </ol>
        </ModalImageLayout>
      )
    },
    renderActions: ({
      landowner_status,
      accountId,
      projectId,
      eligibilityDialog,
      onRequestCallSubmit,
      notInterestedDialog,
      accountRole,
      enrollmentDeadlineType,
      ctaOverride,
      ctaOverrideUrl,
      requires_service_provider,
      has_service_provider_coverage,
    }) => (
      <>
        <hr className="border-charcoal-50 lg:max-w-624 xl:max-w-664" />
        <ButtonPair
          className="mx-auto sm:mx-0 mt-6"
          primary={
            <RequestInformationBtn
              landowner_status={landowner_status}
              projectId={projectId}
              accountId={accountId}
              onRequestCallSubmit={onRequestCallSubmit}
              sourceIdentifier="project-modal"
              accountRole={accountRole}
              enrollmentDeadlineType={enrollmentDeadlineType}
              ctaOverride={ctaOverride}
              ctaOverrideUrl={ctaOverrideUrl}
              requires_service_provider={requires_service_provider}
              has_service_provider_coverage={has_service_provider_coverage}
            />
          }
          secondary={(secondaryProps) => (
            <ActionPermissionWrapper
              accountRole={accountRole}
              action="editAccount"
            >
              <DialogDisclosure
                {...notInterestedDialog}
                className={cx(
                  secondaryProps.className,
                  "bg-cloud-50 text-base !font-bold py-7px not-interested-btn-forester-modal"
                )}
              >
                Not Interested
              </DialogDisclosure>
            </ActionPermissionWrapper>
          )}
        />
        <button
          className="block text-leaf font-semibold hover:underline focus:outline-none mb-3 mt-3 skip-btn-forester-modal"
          type="button"
          onClick={eligibilityDialog.hide}
        >
          Skip for now
        </button>
      </>
    ),
  },
  INCOVERAGE: {
    key: "INCOVERAGE",
    renderHeadline: () => (
      <h2 className="flex-auto text-xl leading-6">
        You are eligible for this program! Next up, request a call to connect
        with a forester.
      </h2>
    ),
    renderBody: ({ img, has_preferred_contact_method }) => {
      return (
        <ModalImageLayout heading="Next Steps" img={img}>
          <ol className="ordered-list mt-6">
            <li>
              {!has_preferred_contact_method
                ? "Add your preferred contact method."
                : "Request a call to connect with a forester that services your area."}
            </li>
            <li>
              {!has_preferred_contact_method
                ? "Request a call to connect with a forester that services your area"
                : "NCX will share your preferred contact method and information with a forester."}
            </li>
            <li className="!mb-6">
              Talk with a forester and determine what services you're interested
              in to become a more informed steward of your land.
            </li>
          </ol>
        </ModalImageLayout>
      )
    },
    renderActions: ({
      landowner_status,
      accountId,
      projectId,
      eligibilityDialog,
      onRequestCallSubmit,
      notInterestedDialog,
      accountRole,
      enrollmentDeadlineType,
      ctaOverride,
      ctaOverrideUrl,
      requires_service_provider,
      has_service_provider_coverage,
    }) => (
      <>
        <hr className="border-charcoal-50 lg:max-w-624 xl:max-w-664" />
        <ButtonPair
          className="mx-auto sm:mx-0 mt-6"
          primary={
            <RequestInformationBtn
              landowner_status={landowner_status}
              projectId={projectId}
              accountId={accountId}
              onRequestCallSubmit={onRequestCallSubmit}
              sourceIdentifier="project-modal"
              accountRole={accountRole}
              enrollmentDeadlineType={enrollmentDeadlineType}
              ctaOverride={ctaOverride}
              ctaOverrideUrl={ctaOverrideUrl}
              requires_service_provider={requires_service_provider}
              has_service_provider_coverage={has_service_provider_coverage}
            />
          }
          secondary={(secondaryProps) => (
            <ActionPermissionWrapper
              accountRole={accountRole}
              action="editAccount"
            >
              <DialogDisclosure
                {...notInterestedDialog}
                className={cx(
                  secondaryProps.className,
                  "bg-cloud-50 text-base !font-bold py-7px not-interested-btn-forester-modal"
                )}
              >
                Not Interested
              </DialogDisclosure>
            </ActionPermissionWrapper>
          )}
        />
        <button
          className="block text-leaf font-semibold hover:underline focus:outline-none mb-3 mt-3 skip-btn-forester-modal"
          type="button"
          onClick={eligibilityDialog.hide}
        >
          Skip for now
        </button>
      </>
    ),
  },
}
