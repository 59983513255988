import React from "react"
import cx from "classnames"

const ProjectMapSkeleton = ({ message, animate = false }) => (
  <div className={cx("lg:flex xl:max-w-1120", { "animate-pulse": animate })}>
    <div className="details-hero-img rounded bg-dusk-200 w-full lg:max-w-[600px] h-[400px] relative">
      {message}
    </div>
    <div className="lg:grow lg:pl-12 lg:pr-2 text-lg lg:order-2 lg:max-w-[244px] lg:h-[400px] w-full lg:flex lg:flex-col">
      <div className="w-full h-12 bg-dusk-200 mb-4" />
      <div className="w-full h-12 bg-dusk-200 mb-4" />

      <div className="mx-auto sm:mx-0 lg:mt-60 sm:flex">
        <div className="w-full sm:w-40 h-10 bg-dusk-200 rounded mb-4 sm:mb-auto sm:mr-4" />
        <div className="w-full sm:w-40 h-10 bg-dusk-200 rounded" />
      </div>
    </div>
  </div>
)

export default ProjectMapSkeleton
