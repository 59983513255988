import { useContext, useState, useRef, useEffect, ReactNode } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFilter,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"
import {
  Accordion,
  AccordionItem,
  AccordionItemState,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

import { CloseButton } from "../../components/CloseButton"
import ToggleSwitch from "../../components/ToggleSwitch"
import RangeSlider from "../../components/RangeSlider"
import { PAYMENT_TYPES, PROJECT_ACTIONS } from "../../shared/constants"
import {
  ProjectsContext,
  StateTypes,
  initialState,
} from "../../context/ProjectsContext"
import useViewport from "../../hooks/useViewport"

interface FilterSwitchTypes {
  label: string
  value: boolean
  onChange: () => void
  className?: string
}

interface FilterAccordionItemTypes {
  heading: string
  children: ReactNode
}

interface ProjectFiltersTypes {
  filteredNumber: number
  handleFirstPageRedirect: () => void
}

const FilterSwitch = ({
  label,
  value,
  onChange,
  className,
}: FilterSwitchTypes) => (
  <div className={cx("project-filters-switch", className)}>
    <ToggleSwitch label={label} value={value} onChange={onChange} />
    <span>{value ? "Show" : "Hide"}</span>
  </div>
)

const FilterAccordionItem = ({
  heading,
  children,
}: FilterAccordionItemTypes) => (
  <AccordionItem className="border-t border-charcoal-50 py-6">
    <AccordionItemHeading>
      <AccordionItemButton>
        <AccordionItemState>
          {({ expanded }) => (
            <span className="project-filters-accordion-item-button">
              {heading}
              <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
            </span>
          )}
        </AccordionItemState>
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel className="mb-0 pt-2.5">{children}</AccordionItemPanel>
  </AccordionItem>
)

const ProjectFilters = ({
  filteredNumber,
  handleFirstPageRedirect,
}: ProjectFiltersTypes) => {
  const { width } = useViewport()
  const overlayRef = useRef<HTMLDivElement>(null)
  const filtersRef = useRef<HTMLDivElement>(null)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const {
    state: {
      govtProjectsOn,
      ineligibleProjectsOn,
      hasLandownerCost,
      termLengthFilter,
      paymentTypes,
    },
    dispatch,
  } = useContext(ProjectsContext)

  const [termLength, setTermLength] = useState(termLengthFilter)

  const handleFilter = (type: string, payload: boolean | StateTypes) => {
    dispatch({
      type,
      payload,
    })
    handleFirstPageRedirect()
  }

  useEffect(() => {
    dispatch({
      type: PROJECT_ACTIONS.setTermLengthFilter,
      payload: termLength,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termLength])

  // DEV: improve accessibility by bringing the drawer into focus when it's open, and making it inert when it's closed to prevent focusing of its input elements
  useEffect(() => {
    if (filtersRef?.current) {
      if (filtersOpen) {
        filtersRef?.current.focus()
        filtersRef?.current.removeAttribute("inert")
      } else {
        filtersRef?.current.blur()
        filtersRef?.current.setAttribute("inert", "")
      }
    }
  }, [filtersOpen])

  // DEV: close drawer on Escape key press
  useEffect(() => {
    const handleKeyDown = (e: { key: string; preventDefault: () => void }) => {
      if (filtersOpen && filteredNumber !== 0 && e.key === "Escape") {
        e.preventDefault()
        setFiltersOpen(false)
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [filteredNumber, filtersOpen])

  let activeFilters = [
    !govtProjectsOn,
    !hasLandownerCost,
    termLengthFilter[0] > 0 || termLengthFilter[1] < 100,
    Object.values(paymentTypes).includes(true),
  ]

  // DEV: include government projects toggle in active filters number on mobile
  if (width < 1280) {
    activeFilters = [...activeFilters, !ineligibleProjectsOn]
  }

  const activeFiltersNum = activeFilters.filter(Boolean).length

  return (
    <div className="project-filters">
      <button
        type="button"
        className="project-filters-toggle"
        onClick={() => setFiltersOpen(true)}
        aria-label="Open filters"
      >
        Filters
        <span className="inline-block ml-1">
          <FontAwesomeIcon icon={faFilter} />
        </span>
        {activeFiltersNum > 0 && (
          <span className="project-filters-toggle-active-num">
            {activeFiltersNum}
          </span>
        )}
      </button>

      <div
        ref={overlayRef}
        className={cx("project-filters-overlay", {
          "project-filters-overlay-active": filtersOpen,
        })}
        onClick={(e) => {
          if (e.target === overlayRef.current && filteredNumber !== 0) {
            setFiltersOpen(false)
          }
        }}
      >
        <div
          ref={filtersRef}
          className={cx("project-filters-inner", {
            "project-filters-inner-active": filtersOpen,
          })}
          aria-label="Filters"
        >
          <div className="project-filters-inner-header">
            <button
              type="button"
              className="link focus:outline-none"
              onClick={() =>
                handleFilter(PROJECT_ACTIONS.setInitialState, initialState)
              }
            >
              Clear
            </button>

            <h4>Filters</h4>

            <CloseButton
              className="shrink-0 ml-4 rounded focus:outline-none text-charcoal-500 font-bold"
              aria-label="Close filters"
              onClick={() => setFiltersOpen(false)}
              disabled={filteredNumber === 0}
            />
          </div>

          <div className="project-filters-inner-content">
            <div className="project-filters-inner-content-container">
              <FilterSwitch
                label="Government programs"
                value={govtProjectsOn}
                onChange={() =>
                  handleFilter(
                    PROJECT_ACTIONS.setGovtProjectsOn,
                    !govtProjectsOn
                  )
                }
              />
              <FilterSwitch
                label="Ineligible programs"
                value={ineligibleProjectsOn}
                onChange={() =>
                  handleFilter(
                    PROJECT_ACTIONS.setIneligibleProjectsOn,
                    !ineligibleProjectsOn
                  )
                }
                className="flex xl:hidden"
              />
              <FilterSwitch
                label="Has landowner cost"
                value={hasLandownerCost}
                onChange={() =>
                  handleFilter(
                    PROJECT_ACTIONS.setHasLandownerCost,
                    !hasLandownerCost
                  )
                }
              />
            </div>

            <Accordion
              allowMultipleExpanded={true}
              allowZeroExpanded={true}
              className="divide-y mt-6"
            >
              <FilterAccordionItem heading="Term length">
                <RangeSlider
                  id="term-length"
                  ariaLabel="Term length"
                  value={termLengthFilter}
                  onChange={(e) => {
                    setTermLength(e)
                    handleFirstPageRedirect()
                  }}
                  lowerInputLabel="Min term length"
                  upperInputLabel="Max term length"
                />
              </FilterAccordionItem>

              <FilterAccordionItem heading="Payment terms">
                {Object.entries(PAYMENT_TYPES).map(([key, val]) => (
                  <label
                    key={key}
                    htmlFor={key}
                    className="flex flex-row cursor-pointer mb-2"
                  >
                    <span className="flex mr-2 shrink-0 items-center relative">
                      <input
                        id={key}
                        type="checkbox"
                        className="checkbox"
                        checked={paymentTypes[key as keyof typeof paymentTypes]}
                        onChange={(e) =>
                          handleFilter(PROJECT_ACTIONS.setPaymentTypes, {
                            ...paymentTypes,
                            [key]: e.target.checked,
                          } as unknown as StateTypes)
                        }
                      />
                      <span className="checkmark"></span>
                    </span>
                    <span>{val}</span>
                  </label>
                ))}
              </FilterAccordionItem>
            </Accordion>
          </div>

          <div className="project-filters-inner-footer">
            <button
              type="button"
              className="btn2 btn2-primary font-bold w-full"
              onClick={() => setFiltersOpen(false)}
              aria-label={`Show ${filteredNumber} programs`}
              disabled={filteredNumber === 0}
            >
              Show {filteredNumber} Programs
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectFilters
