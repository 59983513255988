const ProjectMapOverlay = ({
  title,
  text,
  ctaUrl = null,
  ctaType = "link",
  ctaText,
  ctaOnClick = null,
}) => (
  <div className="absolute inset-0 flex flex-col justify-center items-center bg-[#242426] bg-opacity-90 p-4 z-[2]">
    <h3 className="text-white text-lg font-semibold leading-130 tracking-[0.378px]">
      {title}
    </h3>

    <p className="text-white text-base leading-130 tracking-0.32 mt-2">
      {text}
    </p>

    {ctaUrl && ctaType === "link" ? (
      <a
        href={ctaUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="project-map-overlay-cta btn2 btn2-outline-primary w-full sm:w-auto bg-white text-center font-bold leading-130 tracking-0.32 mt-5"
      >
        {ctaText}
      </a>
    ) : null}

    {ctaType === "button" ? (
      <button
        type="button"
        className="project-map-overlay-cta btn2 btn2-outline-primary w-full sm:w-auto bg-white text-center font-bold leading-130 tracking-0.32 mt-5"
        onClick={ctaOnClick}
      >
        {ctaText}
      </button>
    ) : null}
  </div>
)

export default ProjectMapOverlay
