import { LANDCOVER_TYPE_LABELS } from "../../shared/constants"

const Stat = ({ label, value }) => (
  <div className="h-[54px] flex flex-col justify-start items-center gap-1">
    <div className="text-center text-black text-xl font-medium leading-130 tracking-0.32">{`${value}%`}</div>
    <div className="text-leaf text-base font-bold leading-130 tracking-0.32">
      {label}
    </div>
  </div>
)

const LandCoverStats = ({ landCoverDetails }) => (
  <div className="w-full landcover-stats">
    {Object.keys(LANDCOVER_TYPE_LABELS)
      .filter((type) => landCoverDetails[type].pct * 100 > 0)
      .sort((a, b) => landCoverDetails[b].pct - landCoverDetails[a].pct)
      .map((type, i) => (
        <Stat
          key={i}
          label={LANDCOVER_TYPE_LABELS[type]}
          value={(landCoverDetails[type].pct * 100).toLocaleString("en-US")}
        />
      ))}
  </div>
)

export default LandCoverStats
