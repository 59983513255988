import {
  useState,
  useEffect,
  useMemo,
  LabelHTMLAttributes,
  InputHTMLAttributes,
  ComponentType,
  TextareaHTMLAttributes,
  SelectHTMLAttributes,
  ReactNode,
  ReactElement,
  JSXElementConstructor,
  ReactPortal,
  createElement,
} from "react"
import cx from "classnames"
import debounce from "lodash/debounce"
import {
  Field,
  useFormikContext,
  ErrorMessage as FormikErrorMessage,
  FieldProps,
} from "formik"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"

import { Combobox } from "./Combobox"
import assert from "assert"
import MailCheck from "./MailCheck"
import { getSVGURI } from "../shared/utils"

interface InputLabelTypes extends LabelHTMLAttributes<HTMLLabelElement> {
  className: string
}

export interface PopoverContentTypes {
  selected: boolean
}

export type WrappedInputAsTypes =
  | "select"
  | "combobox"
  | "textarea"
  | "email"
  | "password"

interface WrappedInputTypes extends InputHTMLAttributes<HTMLInputElement> {
  label: ReactNode
  labelClass?: string
  as?: WrappedInputAsTypes
  toggleShowPassword?: () => void
  instructions?: string
  options?: {
    value: string
    label: string
    popoverContent?: ({ selected }: PopoverContentTypes) => ReactNode
  }[]
  popoverClassName?: string
}

interface InputFieldTypes extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  meta: FieldProps["meta"]
  field: FieldProps["field"]
  prefix?: string
}

interface InputTypes extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  prefix?: string
}

interface EmailSuggestionTypes {
  inputProps: InputHTMLAttributes<HTMLInputElement>
}

interface TextareaInputTypes
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string
}

interface ErrorMessageTypes {
  className?: string
  name: string
}

interface SelectInputTypes extends SelectHTMLAttributes<HTMLSelectElement> {
  className?: string
  placeholder?: string
  options: { value: string; label: string }[]
}

interface SuggestionTypes {
  full: any
  address:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined
  domain:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined
}

export const InputLabel = ({ className, ...props }: InputLabelTypes) => (
  <label
    className={cx(
      "label",
      { "font-bold": !className.includes("font-normal") },
      className
    )}
    {...props}
  />
)

export const WrappedInput = ({
  label,
  labelClass,
  as: _as,
  toggleShowPassword,
  instructions,
  ...props
}: WrappedInputTypes) => {
  let component: ComponentType<any> = Input
  let suggestionComponent: ComponentType<any> | null = null
  if (_as === "select") {
    component = SelectInput
  } else if (_as === "combobox") {
    component = Combobox
  } else if (_as === "textarea") {
    component = TextareaInput
  } else if (_as === "email") {
    suggestionComponent = EmailSuggestion
  }

  let inputId = props.id
  if (!inputId) {
    props.id = props.name
    inputId = props.id
  }
  return (
    <>
      <InputLabel
        className={cx(labelClass ? labelClass : "mb-1 inline")}
        htmlFor={inputId}
      >
        {label}
      </InputLabel>
      {_as === "password" && (
        <span
          role="button"
          onClick={toggleShowPassword}
          className="btn2 btn2-plain float-right p-0 font-normal text-sm"
        >
          {" "}
          {props.type === "password" ? "Show Password" : "Hide Password"}
        </span>
      )}
      {createElement(component, props)}
      {suggestionComponent &&
        createElement(suggestionComponent, {
          inputProps: props,
        })}

      {instructions ? (
        <p className="text-sm text-dusk leading-130 tracking-0.14 mt-1">
          {instructions}
        </p>
      ) : null}

      {props.name ? (
        <ErrorMessage
          data-test={
            props["data-test" as keyof typeof props]
              ? `error-${props["data-test" as keyof typeof props]}`
              : null
          }
          name={props.name}
        />
      ) : null}
    </>
  )
}

const InputField = ({
  className,
  meta,
  field,
  prefix,
  ...rest
}: InputFieldTypes) => (
  <input
    className={cx(
      "form-input st-input",
      className,
      {
        // DEV: Formik checks both `touched` and `error` for `<ErrorMessage>`, https://formik.org/docs/api/errormessage
        //   https://github.com/formium/formik/blob/e50040abe49cf7bb46580ea46af6a2b487539830/packages/formik/src/ErrorMessage.tsx#L36-L39
        "border-red-500": meta.touched && meta.error,
      },
      { "pl-11": prefix }
    )}
    {...field}
    // Extend `rest` again to pass along `input` attributes like `placeholder` (Formik drops them)
    {...rest}
  />
)

export const Input = ({ className, prefix, ...rest }: InputTypes) => {
  // Extend `rest` once to pass along `as` and similar `Field` tags

  if (prefix) {
    return (
      <Field {...rest}>
        {({ field, meta }: FieldProps) => (
          // DEV: Formik doesn't do any `type="text"` fallback normally so we don't either, https://github.com/formium/formik/blob/formik%402.2.6/packages/formik/src/Field.tsx#L211-L221
          <div className="relative">
            <span className="absolute top-0 bottom-0 left-3 w-6 flex justify-center items-center font-extrabold text-dusk">
              {prefix}
            </span>
            <InputField
              className={className}
              meta={meta}
              field={field}
              prefix={prefix}
              {...rest}
            />
          </div>
        )}
      </Field>
    )
  }

  return (
    <Field {...rest}>
      {({ field, meta }: FieldProps) => (
        // DEV: Formik doesn't do any `type="text"` fallback normally so we don't either, https://github.com/formium/formik/blob/formik%402.2.6/packages/formik/src/Field.tsx#L211-L221
        <InputField className={className} meta={meta} field={field} {...rest} />
      )}
    </Field>
  )
}

export const EmailSuggestion = ({ inputProps }: EmailSuggestionTypes) => {
  // https://formik.org/docs/api/formik#values--field-string-any-

  const { values, setFieldValue, initialValues } = useFormikContext<any>()

  const inputName = inputProps.name
  assert(inputName, "EmailSuggestion requires input to have `name` prop")

  const initialEmail = initialValues[inputName]
  const [email, setEmail] = useState(initialEmail)

  const debouncedSetEmail = useMemo(() => debounce(setEmail, 500), [setEmail])

  useEffect(() => {
    const emailField = values[inputName]
    debouncedSetEmail(emailField)
  }, [values, inputName, debouncedSetEmail])

  // `dirty` is for entire form, this is specific to our field, https://github.com/jaredpalmer/formik/blob/formik%402.2.6/packages/formik/src/Formik.tsx#L935-L938
  const emailIsDirty = initialEmail !== email

  return (
    emailIsDirty && (
      <MailCheck email={email}>
        {(suggestion: SuggestionTypes) =>
          suggestion && (
            <button
              type="button"
              onClick={() => {
                setFieldValue(inputName, suggestion.full)
                // DEV: `setFieldValue` takes 500ms to loop back to `MailCheck` due to our debounce
                //   We use `setEmail` to skip that `debounce` eagerly and hide the field immediately, https://github.com/ncx-co/ncapx-platform/pull/641
                setEmail(suggestion.full)
              }}
              className="text-small text-dusk link--underline-only"
            >
              Did you mean{" "}
              <span className="text-moss">
                {suggestion.address}@<strong>{suggestion.domain}</strong>
              </span>
              ?
            </button>
          )
        }
      </MailCheck>
    )
  )
}

export const TextareaInput = ({ className, ...rest }: TextareaInputTypes) => {
  return (
    // Extend `rest` once to pass along `as` and similar `Field` tags
    <Field {...rest}>
      {({ field, meta }: FieldProps) => (
        <>
          <textarea
            rows={4}
            className={cx("form-input st-input", className, {
              // DEV: Formik checks both `touched` and `error` for `<ErrorMessage>`, https://formik.org/docs/api/errormessage
              //   https://github.com/formium/formik/blob/e50040abe49cf7bb46580ea46af6a2b487539830/packages/formik/src/ErrorMessage.tsx#L36-L39
              "border-red-500": meta.touched && meta.error,
            })}
            {...field}
            // Extend `rest` again to pass along `input` attributes like `placeholder` (Formik drops them)
            {...rest}
          />
          {rest?.maxLength !== undefined ? (
            <p className="text-dusk text-right text-xs leading-130 mt-2">
              {field?.value?.length}/{rest.maxLength}
            </p>
          ) : null}
        </>
      )}
    </Field>
  )
}

export const ErrorMessage = ({ className, ...rest }: ErrorMessageTypes) => {
  const errorClass = cx("st-input-error", className)
  return <FormikErrorMessage component="div" className={errorClass} {...rest} />
}

export const SelectInput = ({
  className,
  placeholder,
  options,
  ...rest
}: SelectInputTypes) => {
  const disabledValue = ""
  return (
    // Extend `rest` once to pass along `as` and similar `Field` tags
    <Field {...rest}>
      {({ field, meta }: FieldProps) => (
        <select
          className={cx(
            "form-select form-input st-input bg-14px bg-right-1-center",
            className,
            {
              "border-red-500": meta.touched && meta.error,
              "text-gray2": field.value === disabledValue,
            }
          )}
          // DEV: override form-select css class background-image property
          style={{
            backgroundImage: `url(${getSVGURI(faChevronDown, "#6B7280")})`,
          }}
          {...field}
          // Extend `rest` again to pass along `select` attributes (e.g. `placeholder` for `input`) (Formik drops them)
          {...rest}
        >
          {/* DEV: `text-dusk` isn't necessary as Chrome, Firefox, Safari, Edge, mobile Chrome, mobile Safari render greyed-out `disabled` as-is but good for consistency */}
          <option value={disabledValue} disabled={true} className="text-gray2">
            {placeholder || "Select"}
          </option>
          {options.map((option) => {
            return (
              // DEV: Chrome will show `text-dusk` for all options if we've set it on the <select> so override each here
              // DEV: Mobile Safari will sadly non-active options as grey, but `:disabled` does set active to grey as desired
              <option
                key={option.value}
                value={option.value}
                className="text-black"
              >
                {option.label}
              </option>
            )
          })}
        </select>
      )}
    </Field>
  )
}
