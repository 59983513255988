import { useQueryClient } from "@tanstack/react-query"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import cx from "classnames"

import OnboardingBtnContainer from "./OnboardingBtnContainer"
import OnboardingSurveyHeader from "./OnboardingSurveyHeader"
import { PROJECT_INTEREST } from "../../shared/constants"
import { SubmitButton } from "../../components/SubmitButton"
import CheckboxGroup from "../../components/CheckboxGroup"
import { RadioGroup } from "../../components/RadioGroup"
import { Toast } from "../../components/Toast"
import { useAccountId, useUpdateAccountInterest } from "../../hooks"
import ONBOARDING from "../../images/onboarding-survey.webp"

const validationSchema = Yup.object().shape({
  onboarding_goal: Yup.string().required("Please choose an option"),
  land_goal: Yup.string().required("Please choose an option"),
  start_program_intention: Yup.string().required("Please choose an option"),
  project_interest: Yup.array().min(1, "Choose at least one interest"),
})

const questionClassname = "space-y-2 mt-8 overflow-y-auto h-56"

const formSteps = {
  0: {
    id: "onboarding_goal",
    headerText: "What do you want to do today?",
    subheader: "Select one. This helps us personalize your experience.",
    options: [
      { value: "learn", label: "Learn about Programs" },
      { value: "browse", label: "Browse Programs" },
      { value: "eligibility", label: "Check Program Eligibility" },
      { value: "ncx_expert", label: "Talk with NCX Expert" },
      { value: "project_developer", label: "Talk with Program Developer" },
    ],
    formInput: (options) => (
      <RadioGroup
        name="onboarding_goal"
        className={questionClassname}
        valueContentTuples={options.map((choice) => [
          choice.value,
          choice.label,
        ])}
        state={options[0]}
        aria-label="Onboarding Goals"
      />
    ),
  },
  1: {
    id: "land_goal",
    headerText: "What is your most important goal?",
    subheader: "Select one. This helps us recommend you programs.",
    options: [
      { value: "wildlife", label: "Wildlife and recreation" },
      { value: "habitat", label: "Habitat restoration" },
      { value: "income", label: "Income generation" },
      { value: "threat_reduction", label: "Threat risk reduction" },
      { value: "preservation", label: "Preservation or conservation" },
      { value: "management_reduction", label: "Reduce management effort" },
      { value: "societal_benefit", label: "Societal benefit" },
      { value: "keep_in_family", label: "Keep land in the family" },
    ],
    formInput: (options) => (
      <RadioGroup
        name="land_goal"
        className={questionClassname}
        valueContentTuples={options.map((choice) => [
          choice.value,
          choice.label,
        ])}
        state={options[0]}
        aria-label="Land Goals"
      />
    ),
  },
  2: {
    id: "start_program_intention",
    headerText: "When do you want to start a program?",
    subheader: "Select one. This helps us create your land report.",
    options: [
      { value: "0_3_mo", label: "As soon as possible" },
      { value: "3_6_mo", label: "In the next 3-6 months" },
      { value: "6_12_mo", label: "In the next 6-12 months" },
      { value: "unknown", label: "Just Browsing" },
    ],
    formInput: (options) => (
      <RadioGroup
        name="start_program_intention"
        className={questionClassname}
        valueContentTuples={options.map((choice) => [
          choice.value,
          choice.label,
        ])}
        state={options[0]}
        aria-label="Start Program Timing"
      />
    ),
  },
  3: {
    id: "project_interest",
    headerText: "What programs interest you?",
    subheader:
      "Select any. This helps us share information and recommend programs.",
    options: [],
    formInput: () => (
      <CheckboxGroup
        name="project_interest"
        className={questionClassname}
        valueContentTuples={Object.keys(PROJECT_INTEREST).map((key) => [
          key,
          PROJECT_INTEREST[key],
        ])}
      />
    ),
  },
}

const OnboardingForm = ({
  onboardingStep,
  setOnboardingStep,
  setSurveyCompleted,
  isMobile,
}) => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const { mutateAsync: updateAccountInterest } = useUpdateAccountInterest(
    queryClient,
    accountId,
    {
      onSuccess: () => {
        setSurveyCompleted(true)
      },
      onError: (error) => {
        Toast.error(
          error?.detail || "An error occurred while adding your information."
        )
      },
    }
  )

  const handleSubmit = async (values) => {
    const postData = {
      onboarding_goal: values.onboarding_goal,
      land_goal: values.land_goal,
      start_program_intention: values.start_program_intention,
      project_interest: values.project_interest.reduce((acc, interest) => {
        return { ...acc, [interest]: true }
      }, {}),
    }
    await updateAccountInterest(postData)
  }

  const questionKeys = Object.keys(formSteps)

  return (
    <Formik
      initialValues={{
        onboarding_goal: "",
        land_goal: "",
        start_program_intention: "",
        project_interest: [],
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        const disabled =
          formikProps.values[formSteps[onboardingStep]?.id] === "" ||
          formikProps.values[formSteps[onboardingStep]?.id] === false ||
          formikProps.values[formSteps[onboardingStep]?.id] === null ||
          formikProps.values[formSteps[onboardingStep]?.id] === undefined

        return (
          <Form>
            {questionKeys.map((questionIdx, index) => {
              return (
                <div
                  key={formSteps[questionIdx].id}
                  className={cx({ hidden: index !== onboardingStep })}
                >
                  <OnboardingSurveyHeader
                    text={formSteps[questionIdx].headerText}
                    subheader={formSteps[questionIdx].subheader}
                    isMobile={isMobile}
                    headerImg={
                      <img
                        src={ONBOARDING}
                        alt="Ebony Tree Image"
                        className="w-[184px] h-auto block"
                      />
                    }
                  />

                  {formSteps[questionIdx].formInput(
                    formSteps[questionIdx].options
                  )}
                </div>
              )
            })}

            <OnboardingBtnContainer>
              {onboardingStep === questionKeys.length - 1 ? (
                <SubmitButton
                  className="btn2 btn2-primary font-semibold md:self-start md:mt-10"
                  isSubmitting={formikProps.isSubmitting}
                  disabled={disabled}
                >
                  Next
                </SubmitButton>
              ) : (
                <button
                  type="button"
                  className="btn2 btn2-primary font-semibold md:self-start md:mt-10"
                  onClick={() => setOnboardingStep((prevStep) => prevStep + 1)}
                  disabled={disabled}
                >
                  Next
                </button>
              )}
            </OnboardingBtnContainer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default OnboardingForm
