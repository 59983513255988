import EligibilityQuizStartMask from "./EligibilityQuizStartMask"
import { PROJECT_TYPES } from "../../shared/constants"

const EligibilityQuizStart = ({
  categoryClass,
  projectType,
  startQuiz,
  questionsNum,
}) => (
  <div className="flex flex-auto items-center justify-center">
    <EligibilityQuizStartMask categoryClass={categoryClass} />

    <div className="max-w-[532px] text-center relative p-4 pb-24">
      <p className="text-west-side text-overline">
        Story of your land: {PROJECT_TYPES[projectType]?.long}
      </p>

      <h1 className="text-white text-2xl sm:text-3xl leading-120 tracking-0.9 mt-2">
        Every piece of land has a story – share yours
      </h1>

      <p className="text-white text-base leading-130 tracking-0.32 mt-6">
        Answer{" "}
        <span className="font-bold">
          {questionsNum} {questionsNum > 1 ? "questions" : "question"}
        </span>{" "}
        to see your eligibility for {PROJECT_TYPES[projectType]?.long} programs.
      </p>

      <button
        type="button"
        className="btn2 btn2-outline-primary bg-white font-bold mt-16"
        onClick={startQuiz}
        disabled={questionsNum === undefined}
      >
        Get Started
      </button>
    </div>
  </div>
)

export default EligibilityQuizStart
