import { useQueryClient } from "@tanstack/react-query"
import { Formik, FormikValues } from "formik"
import * as yup from "yup"
import { DialogStateReturn } from "reakit"

import AcceptModalForm from "./AcceptModalForm"
import { Modal } from "../../components/Modal"
import { Toast } from "../../components/Toast"
import { LANDOWNER_STATUS, phoneRegex } from "../../shared/constants"
import {
  useUpdateAccountProject,
  useUpdateProfile,
  useViewNotification,
} from "../../hooks"
import { genericErrMsg } from "../../api/auth"
import { BadgeDisplayType } from "@/types/constants"
import { Profile } from "@/types"

interface AcceptModalTypes {
  dialog: DialogStateReturn
  attestationsDialog: DialogStateReturn
  accountId: string
  projectId: string
  sender: string | undefined
  badgeDisplay: BadgeDisplayType | undefined
  profile: Profile | undefined
  notificationId?: number
}

const CONTACT_METHOD = "contact_method"
const PHONE_NUMBER = "phone_number"
const EMAIL = "email"

const validationSchema = (isValidPhoneNumber: boolean) => {
  return yup.object().shape({
    [CONTACT_METHOD]: yup
      .string()
      .required("Please provide a preferred contact method"),
    [PHONE_NUMBER]: yup.string().when(CONTACT_METHOD, (methods, schema) => {
      return !isValidPhoneNumber &&
        (methods.includes("phone") || methods.includes("text"))
        ? yup
            .string()
            .required("Please enter a phone number.")
            .matches(phoneRegex, "Please provide a valid phone number")
        : schema
    }),
    [EMAIL]: yup.string().when(CONTACT_METHOD, (methods, schema) => {
      return !isValidPhoneNumber && methods.includes("email")
        ? yup.string().email("Please provide a valid email address")
        : schema
    }),
  })
}

const AcceptModal = ({
  dialog,
  attestationsDialog,
  accountId,
  projectId,
  sender,
  badgeDisplay,
  profile,
  notificationId,
}: AcceptModalTypes) => {
  const queryClient = useQueryClient()
  const { mutateAsync: updateProfile } = useUpdateProfile(queryClient)
  const { mutateAsync: viewNotification } = useViewNotification(
    queryClient,
    accountId,
    {}
  )
  const { mutateAsync: updateAccountProject } = useUpdateAccountProject(
    queryClient,
    accountId,
    projectId,
    {
      onSuccess: () => {
        dialog.hide()
        queryClient.invalidateQueries({
          queryKey: ["accounts", accountId, "notifications"],
        })
        Toast.success(`You accepted an invitation from ${sender}.`)
        if (notificationId) {
          viewNotification(notificationId.toString())
        }
      },
      onError: (error: { message: string }) => {
        Toast.error(error?.message || genericErrMsg)
      },
    }
  )

  const handleFormSubmit = async (values: FormikValues) => {
    const newValues = {
      preferred_contact_method: [values.contact_method],
      ...(values.contact_method.includes("phone") ||
      values.contact_method.includes("text")
        ? { phone_number: values.phone_number }
        : {}),
      ...(values.contact_method === "email" ? { email: values.email } : {}),
    }

    await updateProfile(newValues, {
      onSuccess: () => {
        const handleUpdateAccountProject = async () => {
          await updateAccountProject({
            status: LANDOWNER_STATUS.request_information,
          })
        }

        if (badgeDisplay === LANDOWNER_STATUS.information_needed) {
          dialog.hide()
          attestationsDialog.show()
        } else {
          handleUpdateAccountProject()
        }
      },
    })
  }

  const isValidPhoneNumber: boolean = phoneRegex.test(
    profile?.phone_number as string
  )

  return (
    <Modal
      header="Accept invitation?"
      aria-label="Accept invitation?"
      className="max-w-[400px]"
      dialog={dialog}
    >
      <p className="text-charcoal-500 text-base leading-130 tracking-0.32 mb-4">
        If you accept this invite to connect, we'll share the contact
        information below with the program partner and they'll reach out to you
        to talk more about their program and your land goals.
      </p>

      <Formik
        initialValues={{
          [CONTACT_METHOD]: "",
          [PHONE_NUMBER]: profile?.phone_number || "",
          [EMAIL]: profile?.email || "",
        }}
        validationSchema={() => validationSchema(isValidPhoneNumber)}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {(formikProps) => (
          <AcceptModalForm formikProps={formikProps} dialog={dialog} />
        )}
      </Formik>
    </Modal>
  )
}

export default AcceptModal
