import {
  NSADocumentLink,
  ERDocumentLink,
  RRDocumentLink,
  RRReviewDocumentLink,
  AIPlotMapDocumentLink,
  OwnershipDocumentLinks,
} from "../../components/DocumentLink"
import { accountCycleStatusIsAtLeast } from "../../shared/utils"
import { AccountCycleTypes } from "../../types/accountCycles"

// DEV: This defines which document links a landowner will see associated with a certain cycle.
// In order to see documents, an account cycle should meet some conditions: namely, to have a document associated with it, of course, and also to have a certain status. For example, it wouldn't make sense for an account cycle in the "created" state to show the Results Report document link because it doesn't have one yet, and it's not at the point in the program lifecycle where it would be receiving one.
export const ACCOUNT_CYCLE_DOCUMENT_CONFIG = [
  {
    component: AIPlotMapDocumentLink,
    condition: (accountCycle: AccountCycleTypes) =>
      accountCycleStatusIsAtLeast(accountCycle, "bid_accepted") &&
      accountCycle.access_info_plot_map_document,
  },
  {
    component: ERDocumentLink,
    condition: (accountCycle: AccountCycleTypes) =>
      accountCycleStatusIsAtLeast(accountCycle, "ineligible_geography"),
  },
  {
    component: NSADocumentLink,
    condition: (accountCycle: AccountCycleTypes) =>
      accountCycleStatusIsAtLeast(accountCycle, "ncapx_sale_agreement_signed"),
  },
  {
    component: RRReviewDocumentLink,
    condition: (accountCycle: AccountCycleTypes) =>
      accountCycleStatusIsAtLeast(
        accountCycle,
        "results_delivered_ineligible"
      ) && accountCycle.rr_review_document,
  },
  {
    component: RRDocumentLink,
    condition: (accountCycle: AccountCycleTypes) =>
      accountCycleStatusIsAtLeast(accountCycle, "results_delivered_ineligible"),
  },
]

// DEV: This defines document links to anything uploaded by the landowner (at the time of writing, just ownership documents).
export const ACCOUNT_DOCUMENT_CONFIG = [
  {
    component: OwnershipDocumentLinks,
  },
]
