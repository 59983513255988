const NoQuestions = ({ title, text }) => (
  <div className="text-center pt-38 pb-42.5">
    <h3 className="text-xl text-charcoal-900 leading-6 tracking-0.4">
      {title}
    </h3>
    <p className="text-base text-dusk leading-130 tracking-0.32 mt-2">{text}</p>
  </div>
)

export default NoQuestions
