import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

import ProjectMapParcelsFeature from "./ProjectMapParcelsFeature"
import {
  getViewportFromFeature,
  handleNextFeature,
  handlePrevFeature,
} from "./helpers"
import { shortenAcreage } from "../../../utils"

const ProjectMapParcels = ({
  isIneligible,
  parcelData,
  activeFeature,
  setActiveFeature,
  setViewport,
  className,
  onNextFeatureClick,
  onPrevFeatureClick,
  enableEligibility = true,
}) => (
  <div
    tabIndex={isIneligible ? -1 : 0}
    className={`parcel-sidebar lg:max-w-[244px] w-full flex flex-col-reverse lg:flex-col ${className}`}
  >
    <ul
      className={`block border border-charcoal-50 border-t-0 lg:border-t-1 border-b-0 rounded-b overflow-y-auto`}
    >
      {parcelData.features.map((feature, index, arr) => {
        const disabled =
          enableEligibility && feature.properties.elig_acres === 0

        return (
          <li
            key={index}
            onClick={() => {
              if (!disabled) {
                setActiveFeature(index)
                setViewport(getViewportFromFeature(feature))
              }
            }}
            className={cx(
              "w-full flex justify-between items-center p-2 border-b-1 border-b-charcoal-50",
              "text-charcoal-500 text-sm leading-130 tracking-0.14",
              { "bg-charcoal-50": disabled },
              { "hover:bg-grass-50 cursor-pointer": !disabled },
              { "bg-grass-50": activeFeature === index },
              { "border-b-0": index === arr.length - 1 }
            )}
          >
            {enableEligibility ? (
              <div>
                <strong className="font-bold">
                  {shortenAcreage(feature.properties.elig_acres)}/
                  {shortenAcreage(feature.properties.parcel_acres)}
                </strong>{" "}
                acres eligible
              </div>
            ) : (
              <div>
                <strong className="font-bold">
                  {shortenAcreage(feature.properties.parcel_acres)}
                </strong>{" "}
                acres
              </div>
            )}

            <ProjectMapParcelsFeature
              parcelData={parcelData}
              featureIndex={index}
            />
          </li>
        )
      })}
    </ul>

    <div className="w-full h-[60px] flex gap-4 border border-charcoal-50 lg:rounded-br px-4 py-2">
      <button
        type="button"
        className="grow btn2 btn2-outline-primary text-dusk-500 p-[10px]"
        onClick={() =>
          onPrevFeatureClick
            ? onPrevFeatureClick(setActiveFeature, setViewport, parcelData)
            : handlePrevFeature(setActiveFeature, setViewport, parcelData)
        }
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>

      <button
        type="button"
        className="grow btn2 btn2-outline-primary text-dusk-500 p-[10px]"
        onClick={() =>
          onNextFeatureClick
            ? onNextFeatureClick(setActiveFeature, setViewport, parcelData)
            : handleNextFeature(setActiveFeature, setViewport, parcelData)
        }
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  </div>
)

export default ProjectMapParcels
