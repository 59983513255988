import assert from "assert"
import Cookies from "js-cookie"
import { enums, ReactSDKClient } from "@optimizely/react-sdk"
import ReactGA from "react-ga4"
import { useSessionStore } from "../stores"
import { Profile } from "../types"

interface AnalyticsEvent {
  category?: string
  action: string
  label: string
}

/**
 * Identify the current user for third-party integrations.
 * Currently we integrate with HubSpot and Heap, scripts for both
 * are loaded in index.html.
 *
 * WARNING: When adding a new integration here, be sure to update
 * `resetUserForIntegrations` so that we don't continue to associate
 * activity with a logged-out user.
 *
 * @param {string} email - the email address of the user
 */

export const identifyUserForIntegrations = (
  email: string,
  optimizelyClient: ReactSDKClient | null,
  profile?: Profile
) => {
  // Get the page path including any UTM params so we can pass to HubSpot
  // pathAndQuery = "/sign-up?utm_source=foo"
  const pathAndQuery = window.location.pathname + window.location.search

  // HubSpot https://developers.hubspot.com/docs/api/events/tracking-code
  const _hsq = (window._hsq = window._hsq || [])
  _hsq.push(["identify", { email }])
  _hsq.push(["setPath", pathAndQuery])
  _hsq.push(["trackPageView"])

  // Heap https://developers.heap.io/reference/identify
  // DEV: We always define `window.heap` via `index.html`, not async `<script>` loading, so it's guaranteed to exist
  const heap = window.heap
  heap.identify(email)

  // profile isn't always available at this point
  let additionalUserProperties: Record<string, any> = {}
  if (profile) {
    additionalUserProperties = {
      is_partner: profile.is_partner,
      is_service_provider: profile.is_service_provider,
      is_partner_assignee: profile.is_partner_assignee,
      partners: profile.partner_names?.toString(),
    }
    heap.addUserProperties(additionalUserProperties)
  }

  const userId = useSessionStore.getState().authData?.user_id
  if (!userId) {
    return
  }

  optimizelyClient?.setUser({
    id: userId.toString(),
    attributes: {
      email,
      ...additionalUserProperties,
    },
  })
  // Set up notification listener to send flag decisions to Heap
  // https://help.heap.io/integrations/testing-and-personalization/optimizely-full-stack-integration/
  // https://docs.developers.optimizely.com/feature-experimentation/docs/set-up-notification-listener-react
  const onDecision = ({ decisionInfo }: any) => {
    const flagKey = `Optimizely-${decisionInfo["flagKey"]}`
    const enabled = decisionInfo["enabled"] ? "on" : "off"
    heap.addUserProperties({ [flagKey]: enabled })
  }

  optimizelyClient?.notificationCenter.addNotificationListener(
    enums.NOTIFICATION_TYPES.DECISION,
    onDecision
  )
}

export const sendAnalyticsEvent = ({
  category = "Forms",
  action,
  label,
}: AnalyticsEvent) => {
  assert(action, "`action` is required")
  assert(label, "`label` is required")

  // Google Analytics
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  })

  // Consolidate signup actions as "Lead" for Meta Pixel
  // https://app.asana.com/0/1149645290438893/1207838180480717/f
  if (["signup", "lp_google_reg", "lp_email_reg"].includes(action)) {
    // fbq is defined via HubSpot's javascript
    if (window.fbq) {
      window.fbq("track", "Lead")
    }
  }
}

export const resetUserForIntegrations = (optimizelyClient: ReactSDKClient) => {
  // We delete all HubSpot analytics cookies when a user is logged out. This allows the HubSpot
  // tracking code to associate activity with the right contact if the user then
  // logs back in or creates a new account with a different email address.
  // https://knowledge.hubspot.com/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser
  Cookies.remove("hubspotutk")
  Cookies.remove("__hstc")
  Cookies.remove("__hssc")
  Cookies.remove("__hssrc")

  // Heap https://developers.heap.io/docs/using-identify
  const heap = window.heap
  heap.resetIdentity()
  // reset client user context & remove any notification listeners
  optimizelyClient.setUser({ id: null, attributes: {} })
  optimizelyClient.notificationCenter.clearAllNotificationListeners()
}

export const heapTrackError = (message: string) => {
  // Used to track validation errors in Heap
  // https://developers.heap.io/docs/tracking-field-validation-errors
  window.heap.track("Error", { message })
}

export const heapTrackEvent = (
  event: string,
  properties: Record<string, any> = {}
) => {
  // Used to track custom events in Heap
  // https://developers.heap.io/docs/track
  window.heap.track(event, properties)
}

export const heapAddUserProperties = (properties: Record<string, any> = {}) => {
  // https://developers.heap.io/reference/adduserproperties
  // properties: a JSON object containing key-value pairs to be associated with a user.
  // Keys and values must be a number or string, with the value being 255 characters or fewer.
  window.heap.addUserProperties(properties)
}
