import { useParams } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { Form, Formik } from "formik"
import * as yup from "yup"
import cx from "classnames"

import EligibilityQuizHeader from "./EligibilityQuizHeader"
import EligibilityQuizFormInput from "./EligibilityQuizFormInput"
import { SubmitButton } from "../../components/SubmitButton"
import { Toast } from "../../components/Toast"
import { useAccountId, useUpdateAttestationQuiz } from "../../hooks"
import EligibilityQuizBtnContainer from "./EligibilityQuizBtnContainer"

const validationSchema = (attestations) => {
  const fields = {}
  const types = {
    bool: yup.bool().oneOf([true], "Required"),
    str: yup
      .string()
      .required("Please fill out this field")
      .max(500, "Maximum length exceeded, please limit to 500 characters"),
    float: yup.number().required("Enter in a number"),
    int: yup.number().integer().required("Enter in a number"),
    multiple_choice: yup.string().required("Choose an option"),
  }

  Object.values(attestations)?.forEach((attestation) =>
    Object.assign(fields, {
      [attestation?.id]: types[attestation.response_type],
    })
  )

  return yup.object().shape(fields)
}

const EligibilityQuizForm = ({
  attestations,
  quizData,
  quizStep,
  setQuizStep,
  setCompleted,
  setNumEligible,
  setProjCategoryStats,
  isMobile,
  imageUrl,
}) => {
  const { quizCategory } = useParams()
  const accountId = useAccountId()
  const queryClient = useQueryClient()

  const questionNum = typeof quizStep === "number" ? quizStep + 1 : null
  const questionsNum = attestations.length

  const initialValues = attestations.reduce((acc, attestation) => {
    const values = {
      bool: false,
      str: "",
      float: 0,
      int: 0,
      multiple_choice: attestation.choices[0],
    }

    return {
      ...acc,
      [attestation?.id]: values[attestation.response_type] || "",
    }
  }, {})

  const { mutateAsync: updateAttestationQuiz } = useUpdateAttestationQuiz(
    queryClient,
    accountId,
    quizCategory,
    {
      onSuccess: (data) => {
        setCompleted(data[quizCategory].completed)
        setNumEligible(data[quizCategory].num_eligible_projects)
        setProjCategoryStats(data[quizCategory].project_type_stats)
      },
      onError: (error) => {
        Toast.error(
          error?.detail || "An error occurred while adding your information."
        )
      },
    }
  )

  const handleSubmit = async (values) => {
    const attData = Object.entries(values)?.map(([attestation_id, value]) => ({
      attestation_id: Number(attestation_id),
      value,
    }))
    const postData = { quiz_type: quizData.quiz_type, att_data: attData }
    await updateAttestationQuiz(postData)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(attestations)}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => {
        const disabled =
          formikProps.values[attestations[quizStep]?.id] === "" ||
          formikProps.values[attestations[quizStep]?.id] === false ||
          formikProps.values[attestations[quizStep]?.id] === null ||
          formikProps.values[attestations[quizStep]?.id] === undefined

        return (
          <Form>
            {attestations.map((attestation, index) => (
              <div
                key={attestation?.id}
                className={cx({ hidden: index !== quizStep })}
              >
                <EligibilityQuizHeader
                  eyebrow={`QUESTION ${questionNum} of ${questionsNum}`}
                  text={attestation.text}
                  tooltip={attestation.tooltip}
                  isMobile={isMobile}
                  imageUrl={imageUrl}
                  quizType={quizData.quiz_type}
                />

                <EligibilityQuizFormInput attestation={attestation} />
              </div>
            ))}

            <EligibilityQuizBtnContainer>
              {quizStep === attestations.length - 1 ? (
                <SubmitButton
                  className="btn2 btn2-primary font-semibold md:self-start md:mt-10"
                  isSubmitting={formikProps.isSubmitting}
                  disabled={disabled}
                >
                  Check Eligibility
                </SubmitButton>
              ) : (
                <button
                  type="button"
                  className="btn2 btn2-primary font-semibold md:self-start md:mt-10"
                  onClick={() =>
                    setQuizStep((prevQuizStep) => prevQuizStep + 1)
                  }
                  disabled={disabled}
                >
                  Next
                </button>
              )}
            </EligibilityQuizBtnContainer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default EligibilityQuizForm
