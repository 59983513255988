import React, { ReactNode } from "react"
import cx from "classnames"

import useViewport from "../../hooks/useViewport"

interface OnboardingBtnContainerProps {
  children: ReactNode
}

const OnboardingBtnContainer = ({ children }: OnboardingBtnContainerProps) => {
  const { width } = useViewport()

  return (
    <div
      className={cx("flex flex-col-reverse gap-6 mt-4", {
        "fixed bottom-0 left-0 right-0 bg-white border-t border-dusk-50 px-4 py-6 ":
          width < 768,
      })}
    >
      {children}
    </div>
  )
}

export default OnboardingBtnContainer
