import React, { useContext } from "react"
import { TooltipReference, useTooltipState } from "reakit/Tooltip"
import cx from "classnames"

import { TooltipContent } from "./ToolTip"
import { VerifyUserEmailModalContext } from "../context/VerifyUserEmailModalContext"
import { variants as verifyUserEmailModalVariants } from "../utils/verifyUserEmailModalVariants"
import { hasRequiredPermission } from "../utils/hasRequiredPermission"

const CANNOT_MESSAGE = {
  admin: "You cannot take this action as an admin",
  editor: "You cannot take this action as an editor",
  viewer: "You cannot take this action as a viewer",
}

interface ActionPermissionWrapperProps {
  accountRole: keyof typeof CANNOT_MESSAGE
  action: string
  children: React.ReactElement
  disabled?: boolean
  className?: string
}

export const ActionPermissionWrapper = ({
  accountRole,
  action,
  children: trigger,
  disabled,
  className,
}: ActionPermissionWrapperProps) => {
  const tooltip = useTooltipState()
  const missingPermission = !hasRequiredPermission({
    action,
    accountRole,
  })

  const contextValue = useContext(VerifyUserEmailModalContext)
  const verifyUserEmailModalVariant = contextValue?.variant

  if (verifyUserEmailModalVariant) {
    const verifyUserEmailModalDismissable = verifyUserEmailModalVariants[
      verifyUserEmailModalVariant
    ]({
      profile: {
        email: "",
      },
    }).dismissable

    if (action === "liableAction" && verifyUserEmailModalDismissable) {
      throw new Error(
        "liableAction must be rendered with a non-dismissable VerifyUserEmailModal. " +
          "Please update the corresponding `verifyUserEmailModalVariant` or `getVerifyUserEmailModalVariant`"
      )
    }
  }

  disabled = disabled || missingPermission

  return (
    <>
      <TooltipReference
        state={tooltip}
        as="span"
        tabIndex={disabled ? 0 : -1}
        className={className}
      >
        <trigger.type
          {...trigger.props}
          disabled={trigger.props.disabled || disabled}
          className={cx(
            trigger.props.className as string,
            disabled && "pointer-events-none"
          )}
        />
      </TooltipReference>

      {missingPermission && (
        <TooltipContent tooltip={tooltip}>
          {CANNOT_MESSAGE[accountRole]}
        </TooltipContent>
      )}
    </>
  )
}
