import { useState } from "react"
import cx from "classnames"
import {
  useTooltipState,
  Tooltip as ReakitTooltip,
  TooltipReference,
} from "reakit/Tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons"

const MARKED_CLASSES =
  "btn2-primary bg-leaf border border-leaf hover:border-grass-900"
const UNMARKED_CLASSES =
  "text-charcoal-500 bg-white border border-dusk-50 hover:border-dusk-100 hover:bg-cloud-50"

const HelpfulBtn = ({
  handleHelpfulClick,
  isAnswerAuthor,
  userMarkedHelpful,
}) => {
  const tooltip = useTooltipState()
  const [isHovered, setIsHovered] = useState(false)
  const [iconAnimation, setIconAnimation] = useState(false)
  const [btnClass, setBtnClass] = useState(
    userMarkedHelpful ? MARKED_CLASSES : UNMARKED_CLASSES
  )

  const handleButtonClick = () => {
    // DEV: Prevent wiggle animation from running after the button has been clicked, but is still being hovered over.
    setIsHovered(false)

    // DEV: Animate helpful button on click.
    setIconAnimation(true)
    setTimeout(() => {
      setBtnClass(userMarkedHelpful ? UNMARKED_CLASSES : MARKED_CLASSES)
      setIconAnimation(false)
    }, 300)

    handleHelpfulClick()
  }

  return (
    <>
      <TooltipReference {...tooltip} className="ring-0">
        <button
          type="button"
          onClick={handleButtonClick}
          className={cx(
            "helpful-btn btn-2 rounded-full text-sm font-bold leading-7.5 tracking-0.14 focus:outline-none px-4 py-1 mr-4",
            { "opacity-50 cursor-not-allowed": isAnswerAuthor },
            btnClass
          )}
          disabled={isAnswerAuthor}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <span className="flex items-center gap-3">
            <FontAwesomeIcon
              icon={faThumbsUp}
              className={cx(
                "flex items-center",
                {
                  "wiggle-on-hover":
                    !userMarkedHelpful && !isAnswerAuthor && isHovered,
                },
                {
                  "opacity-on-hover":
                    userMarkedHelpful && !isAnswerAuthor && isHovered,
                },
                {
                  "marked-icon": userMarkedHelpful,
                },
                {
                  "animate-marked": userMarkedHelpful && iconAnimation,
                },
                {
                  "animate-unmarked": !userMarkedHelpful && iconAnimation,
                }
              )}
            />

            <span>Helpful</span>
          </span>
        </button>
      </TooltipReference>

      {isAnswerAuthor ? (
        <ReakitTooltip {...tooltip} className="st-tooltip">
          <div className="text-sm max-w-sm p-3 st-tooltip-inner">
            You can't mark your own answer as helpful.
          </div>
        </ReakitTooltip>
      ) : null}
    </>
  )
}

export default HelpfulBtn
