import { useQueryClient } from "@tanstack/react-query"

import { Toast } from "../../components/Toast"
import { useProfile, useResendEmailVerification } from "../../hooks"

import mailicon from "../../images/mail-icon.svg"
import NCX from "../../images/ncx-login-logo.png"

const VerifyEmail = () => {
  const queryClient = useQueryClient()
  const { data: profile } = useProfile(queryClient)

  const { mutateAsync: resendEmailVerification } = useResendEmailVerification(
    queryClient,
    {
      onSuccess: () => Toast.success(`Email sent to ${profile.email}`),
      onError: () => Toast.error("Error resending email. Try again later"),
    }
  )

  return (
    <>
      <div className="flex justify-center mb-4">
        <img
          src={NCX}
          alt="NCX Logo"
          className="block w-auto h-[51px] lg:h-[46px]"
        />
      </div>

      <h2 className="text-center text-lg leading-130 tracking-0.378 mb-2">
        <span>Verify your email address</span>
      </h2>

      <p className="text-center text-base leading-130 tracking-0.32 mb-8">
        Check {profile?.email || "your email"} and click the verification link
        to access your account
      </p>

      <div className="mb-8">
        <img className="mx-auto" src={mailicon} alt="Check your email" />
      </div>
      <div className="text-center">
        Need help? Watch the{" "}
        <a
          className="link"
          target="_blank"
          href="https://ncxcarbon.wistia.com/medias/tioyrucw50"
          rel="noreferrer"
        >
          tutorial
        </a>{" "}
        or{" "}
        <a className="link" href="mailto:landowners@ncx.com">
          get in touch
        </a>
      </div>

      <hr className="border-charcoal-50 mt-8 mb-4" />

      <div className="text-center text-dusk"> Don't see an email?</div>
      <div className="text-center text-dusk">
        {" "}
        Check your spam folder or{" "}
        <button
          type="button"
          className="link"
          onClick={resendEmailVerification}
          alt="click to resend email verification"
        >
          resend verification{" "}
        </button>
      </div>
    </>
  )
}
export default VerifyEmail
