import { useContext } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { useFormikContext, Form, Formik } from "formik"
import * as yup from "yup"
import { DialogDisclosure, useDialogState } from "reakit/Dialog"

import { Modal } from "../../components/Modal"
import { WrappedInput } from "../../components/Input"
import { SubmitButton } from "../../components/SubmitButton"
import { Toast } from "../../components/Toast"
import { useUpdateAccountProjectReachout } from "../../hooks"
import { genericErrMsg } from "../../api/auth"
import { PartnersContext } from "../../context/PartnersContext"
import { ButtonPair } from "../../components/ButtonPair"

const validationSchema = yup.object().shape({
  message: yup.string().required("Please fill out this field."),
})

const ReachoutModal = ({ dialog, reachoutsCounter }) => {
  const { submitForm, isSubmitting } = useFormikContext()

  return (
    <Modal
      header="Confirm invitation"
      aria-label="Reachout dialog"
      dialog={dialog}
    >
      <p>This action will use one Invitation for the month.</p>
      <p>You have {reachoutsCounter.reachouts_remaining} invitation(s) left.</p>

      <ButtonPair
        className="mt-6"
        // eslint-disable-next-line react/no-unstable-nested-components
        primary={(primaryProps) => (
          <SubmitButton
            isSubmitting={isSubmitting}
            onClick={submitForm}
            disabled={reachoutsCounter.reachouts_remaining < 1}
            {...primaryProps}
          >
            Send
          </SubmitButton>
        )}
        secondary={
          <button type="button" onClick={dialog.hide}>
            Cancel
          </button>
        }
      />
    </Modal>
  )
}

const PartnerEligibleLeadsContact = ({ userFirstName, reachoutsCounter }) => {
  const queryClient = useQueryClient()

  const {
    state: { activeRow },
  } = useContext(PartnersContext)

  const dialog = useDialogState({ animated: true })

  const { mutateAsync: updatePartnersProjectEligibleLead } =
    useUpdateAccountProjectReachout(queryClient, activeRow?.id, {
      onSuccess: () => {
        dialog.hide()
        Toast.success("Message has been sent successfully.")
      },
      onError: (error) => {
        Toast.error(error?.response?.data?.message || genericErrMsg)
      },
    })

  const handleUpdate = async (values, { resetForm }) => {
    await updatePartnersProjectEligibleLead(values)
    resetForm()
  }

  return (
    <div className="relative w-full card card-content self-start overflow-hidden">
      <h4 className="sticky -top-4 left-0 right-0 bg-cloud-50 text-overline text-dusk-500 p-4 -m-4 mb-0 border-b-1 border-b-cloud z-2">
        Contact {activeRow?.first_name}
      </h4>

      <p className="label leading-18px font-normal mt-4">
        Message landowners and invite them to join your program. They'll get a
        notification to accept or decline the invite to connect.{" "}
      </p>

      <Formik
        initialValues={{
          profile_id: activeRow?.profile_id,
          sender: userFirstName,
          message: "",
        }}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={handleUpdate}
        enableReinitialize
      >
        {(formikProps) => (
          <Form>
            <WrappedInput
              label="Sender (Optional)"
              labelClass="leading-18px text-charcoal-500 mt-4 mb-1"
              placeholder="Sender"
              type="text"
              name="sender"
              className="border-charcoal-50"
            />

            <WrappedInput
              label="Message"
              labelClass="leading-18px text-charcoal-500 mt-4 mb-1"
              aria-label="Message"
              name="message"
              as="textarea"
              className="border-charcoal-50"
              maxLength="200"
            />

            <DialogDisclosure
              {...dialog}
              className="btn2 btn2-primary btn2-block font-semibold mt-4"
              disabled={!(formikProps.isValid && formikProps.dirty)}
            >
              Send
            </DialogDisclosure>

            <ReachoutModal
              dialog={dialog}
              reachoutsCounter={reachoutsCounter}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PartnerEligibleLeadsContact
