import assert from "assert"
import React, { forwardRef } from "react"
import cx from "classnames"
import { CycleBadge } from "../../components/Badge"
import { CloseButton } from "../../components/CloseButton"
import { CYCLE_INFOS_BY_KEY } from "../../shared/constants"

// DEV: Minimize loading spinner for content by receiving data from parent
export default forwardRef(
  (
    {
      badge = null,
      account,
      accountCycle,
      cycleKey,
      className,
      children,
      onDismiss,
      showHeaders = true,
    },
    // DEV: Not passing in `ref` most of the time doesn't affect behavior
    ref
  ) => {
    // Perform extended fallback
    // DEV: `badge=false` will disable, `badge=null` will use default, `badge=<CycleBadge />` will override
    badge =
      badge === null ? (
        <CycleBadge account={account} accountCycle={accountCycle} />
      ) : (
        badge
      )

    assert(
      cycleKey || accountCycle,
      "Either cycleKey or accountCycle is required to be provided"
    )
    cycleKey = cycleKey || accountCycle.cycle_key
    // Unset accountCycle to prevent accidental usage later on
    accountCycle = null
    const cycleInfo = CYCLE_INFOS_BY_KEY[cycleKey]

    return (
      <div
        className={cx("card card-content p-0", className)}
        id={`cycle-${cycleKey}`}
        ref={ref}
      >
        {showHeaders && (
          <div className="flex flex-row px-6 py-4 border-b border-cloud items-center">
            <span className="flex-auto">
              <span className="text-overline">
                {cycleInfo.showDates
                  ? `NCX ${cycleInfo.longName}`
                  : "Upcoming NCX Cycle"}
              </span>
            </span>
            {badge}
            {onDismiss && (
              <span className="shrink-0 ml-2.5 -mr-2.5">
                <CloseButton
                  aria-label="Dismiss notification"
                  onClick={onDismiss}
                />
              </span>
            )}
          </div>
        )}
        <div className="p-6">{children}</div>
      </div>
    )
  }
)
