import assert from "assert"
import React from "react"
import { Form, Formik } from "formik"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"

import CycleCard from "./CycleCard"
import { CycleBadge } from "../../components/Badge"
import { Notification } from "../../components/Notification"
import { SubmitButton } from "../../components/SubmitButton"
import { Toast } from "../../components/Toast"
import { CYCLE_INFOS_BY_KEY } from "../../shared/constants"
import { centsToCurrency } from "../../utils"
import { genericErrMsg } from "../../api/auth"
import {
  useAccountUrlPrefix,
  useDeleteAccountNotification,
  useUpdateProfile,
} from "../../hooks"

// DEV: Pre-emptive abstraction in case we make a similar button in a settings page
const SwitchToMultiAccountButton = ({ onError, ...props }) => {
  assert(onError)
  const queryClient = useQueryClient()
  const { mutateAsync: updateProfile } = useUpdateProfile(queryClient, {
    // Prevent cache from updating to avoid making `_single` requests as an MA user (if successful)
    dontUpdateCache: true,
  })

  const handleSubmit = async () => {
    await updateProfile(
      { profile_type: "multi_account" },
      {
        onError,
        onSuccess: () => {
          // Reload our page to `/` to ensure fresh status for our new account
          window.location = "/"
        },
      }
    )
    // DEV: Any further code will still run regardless of error/not due to `onError` handling
  }

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form className="inline">
          <SubmitButton
            isSubmitting={formikProps.isSubmitting}
            {...props}
          ></SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

// Define a common component so we can use hooks (`.map()` with `use` violates Rule of Hooks otherwise)
// DEV: We don't define this in components as it's unexpected those talk to server endpoints
const AccountNotification = ({ accountId, notification }) => {
  const queryClient = useQueryClient()

  const { mutate: deleteNotification } = useDeleteAccountNotification(
    queryClient,
    accountId
  )
  const onDismiss = () => deleteNotification(notification.id)
  const accountUrlPrefix = useAccountUrlPrefix()
  const accountDocumentsUrl = `${accountUrlPrefix}/settings/documents`
  const accountPaymentUrl = `${accountUrlPrefix}/settings/payment`

  let cycleInfo = null
  const cycleKey = notification.account_cycle?.cycle_key
  if (cycleKey) {
    cycleInfo = CYCLE_INFOS_BY_KEY[cycleKey]
    assert(cycleInfo, `Missing cycle info for ${cycleKey}`)
  }

  if (notification.key === "property_partial_coverage") {
    return (
      <Notification
        title={
          <>
            Some of the properties you added are outside of our
            {cycleInfo.showDates ? ` ${cycleInfo.longName} ` : " "}supported
            area
          </>
        }
        onDismiss={onDismiss}
      >
        While it’s required that you add all of your properties to enroll, these
        properties will not be counted as eligible.
      </Notification>
    )
  } else if (notification.key === "assessment_request_period_closed") {
    return (
      <Notification
        title={`The assessment period for NCX ${cycleInfo.longName} is closed`}
        onDismiss={onDismiss}
      >
        The assessment period for this cycle closed on{" "}
        {cycleInfo.eligibilityReportRequestPeriodEnd}. If you’re still
        interested in participating, you can request a free Eligibility
        Assessment for the upcoming NCX cycle below.
      </Notification>
    )
  } else if (notification.key === "bidding_period_closed") {
    return (
      <CycleCard
        badge={<CycleBadge.BiddingPeriodClosed />}
        cycleKey={cycleKey}
        onDismiss={onDismiss}
      >
        <h3 className="text-xl mb-2">
          The selling period for the NCX {cycleInfo.longName} cycle is closed
        </h3>
        <p>
          The selling period for this cycle closed on{" "}
          {cycleInfo.submissionDeadline}. We encourage you to request a new
          assessment to participate in our upcoming cycle.
        </p>
      </CycleCard>
    )
  } else if (notification.key === "no_bids_accepted") {
    return (
      <CycleCard
        badge={<CycleBadge.NoBidsAccepted />}
        cycleKey={cycleKey}
        onDismiss={onDismiss}
      >
        <h3 className="text-xl mb-2">Your offer was not matched this cycle</h3>
        <p>
          We were unable to find a buyer who was willing to purchase credits at
          the price you offered. We encourage you to try again and request a new
          assessment for our upcoming cycle.
        </p>
      </CycleCard>
    )
  } else if (notification.key === "ineligible_for_payment") {
    return (
      <Notification
        title={`${cycleInfo.longName} enrollment was ineligible for payment`}
        onDismiss={onDismiss}
      >
        {notification.account_cycle.rr_review_document ? (
          <>
            You did not earn the amount of credited deferrals required to be
            eligible for payment this year. You may access your Results Report
            Review anytime on the{" "}
            <Link className="link" to={accountDocumentsUrl}>
              Documents
            </Link>{" "}
            page. If you would like further details, please{" "}
            <a href="mailto:landowners@ncx.com" className="link">
              contact payment support
            </a>
            .
          </>
        ) : notification.account_cycle.can_request_review ? (
          <>
            You did not earn the amount of credited deferrals required to be
            eligible for payment this year. You may still initiate a results
            review within the 14 day window by{" "}
            <a href="mailto:landowners@ncx.com" className="link">
              contacting payment support
            </a>
            .
          </>
        ) : (
          <>
            You did not earn the amount of credited deferrals required to be
            eligible for payment this year or did not initiate a results review
            within 14 days of receiving your Results Report per the NCX Seller
            Agreement. Please visit the{" "}
            <Link className="link" to={accountDocumentsUrl}>
              Documents
            </Link>{" "}
            page to access your Results Report. If you would like further
            details, please{" "}
            <a href="mailto:landowners@ncx.com" className="link">
              contact payment support
            </a>
            .
          </>
        )}
      </Notification>
    )
  } else if (notification.key === "payment_delivered") {
    const payment = notification.account_cycle.enrollment.payment
    return (
      <Notification
        title={`${cycleInfo.longName} payment delivered!`}
        onDismiss={onDismiss}
        variant="success"
      >
        Your payment of {centsToCurrency(payment.confirmed_amount_cents)} has
        successfully been delivered for your participation in NCX{" "}
        {cycleInfo.longName}. You may view your payment history and documents in{" "}
        <Link className="link" to={accountPaymentUrl}>
          Account Settings
        </Link>
        . Thank you again for trusting NCX with your land!
      </Notification>
    )
  } else if (notification.key === "invite_inaccessible") {
    return (
      <Notification
        key="invite_inaccessible"
        title="Invited to additional accounts"
      >
        <div className="space-y-2">
          <div>
            You’ve been invited to access additional accounts. To view these,
            you must become a multi-account user. This will update your NCX
            interface.
          </div>
          <div>Would you like to become a multi-account user?</div>
          <div>
            <button
              type="button"
              className="btn2 btn2-outline-primary font-semibold mt-3 mr-2 mb-2"
              onClick={onDismiss}
            >
              No, reject the invites
            </button>
            <SwitchToMultiAccountButton
              onError={(error) => {
                // DEV: We're skipping <ErrorCard> handling here, Toast is a "good enough" stand-in
                Toast.error(error?.detail || genericErrMsg)
              }}
              className="btn2 btn2-primary font-semibold"
            >
              Yes, become a multi-account user
            </SwitchToMultiAccountButton>
          </div>
        </div>
      </Notification>
    )
  } else if (notification.key === "cycle_postponed") {
    return (
      <Notification
        title="Did you miss the webinar about our program update and expanded offerings?"
        onDismiss={onDismiss}
      >
        Don't worry! You can watch{" "}
        <a
          href="https://ncxcarbon.wistia.com/medias/25smdgzuqr"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          the recording
        </a>
        .
      </Notification>
    )
  } else if (notification.key === "pd_invite") {
    return null
  } else {
    // DEV: Tempted to silently warn to Sentry but we prob want to error out/hard block
    //   since there could be critical information in our notifications
    throw new Error(`Unrecognized notification: ${notification.key}`)
  }
}

// DEV: Minimize loading spinner for content by receiving data from parent
const NotificationContent = ({ accountId, accountsCount, notifications }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const notificationEls = notifications.map((notification) => (
    <AccountNotification
      key={notification.id}
      accountId={accountId}
      notification={notification}
    />
  ))

  if (location.state?.createNextNotification) {
    notificationEls.push(
      <Notification
        key="create_next_notification"
        onDismiss={() => {
          navigate(location.pathname, { replace: true })
        }}
        title={
          <>
            Great! You’ve requested {accountsCount === 1 ? "your first" : "an"}{" "}
            eligibility report. What do you want to do next?
          </>
        }
        variant="success"
      >
        <Link
          className="btn2 btn2-outline-primary font-semibold mt-3 mr-2 mb-2"
          to="/"
        >
          View Dashboard
        </Link>
        <Link className="btn2 btn2-primary font-semibold" to="/accounts/new">
          Add Another Account
        </Link>
      </Notification>
    )
  }

  return notificationEls
}

export default NotificationContent
