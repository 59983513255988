import { useEffect, useState } from "react"
import mapboxglSupported from "@mapbox/mapbox-gl-supported"

import { MapboxGLNotSupported } from "../../../components/MapVisualization"
import ProjectMapHeader from "./ProjectMapHeader"
import ProjectMapVisualization from "./ProjectMapVisualization"
import ProjectMapOverlay from "./ProjectMapOverlay"
import ProjectMapParcels from "./ProjectMapParcels"
import ProjectMapSkeleton from "./ProjectMapSkeleton"
import { getViewportFromFeature } from "./helpers"
import { LANDOWNER_STATUS } from "../../../shared/constants"
import { shortenAcreage } from "../../../utils"
import { genericErrMsg } from "../../../api/auth"

const ProjectMap = ({
  isLoading,
  tileData,
  parcelData,
  dialog,
  totalAcreage,
  isIneligible,
  ineligibilityReasons,
  eligibleAcres,
  badgeDisplay,
  eligibilityDialog,
}) => {
  const [activeFeature, setActiveFeature] = useState(0)
  const [viewport, setViewport] = useState()

  useEffect(() => {
    if (parcelData !== undefined) {
      setViewport(getViewportFromFeature(parcelData?.features[0]))
    }
  }, [parcelData])

  if (!mapboxglSupported.supported()) {
    const reason = mapboxglSupported.notSupportedReason()

    return (
      <div className="container xl:max-w-1120 mt-[66px]">
        <div className="lg:max-w-[844px] h-[400px] lg:flex relative rounded bg-gray2 overflow-hidden p-6">
          <MapboxGLNotSupported reason={reason} />
        </div>
      </div>
    )
  }

  return (
    <div className="container xl:max-w-1120 mt-[66px]">
      <ProjectMapHeader dialog={dialog} />

      <div className="lg:max-w-[844px] lg:flex relative rounded overflow-hidden">
        {isLoading ? (
          <ProjectMapSkeleton animate />
        ) : tileData && parcelData ? (
          <>
            <ProjectMapVisualization
              viewport={viewport}
              setViewport={setViewport}
              parcelData={parcelData}
              tileData={tileData}
              activeFeature={activeFeature}
              isIneligible={isIneligible}
            />

            <ProjectMapParcels
              isIneligible={isIneligible}
              parcelData={parcelData}
              activeFeature={activeFeature}
              setActiveFeature={setActiveFeature}
              setViewport={setViewport}
              className="lo-project-details"
            />

            {isIneligible ? (
              <ProjectMapOverlay
                title={
                  ineligibilityReasons?.length > 0
                    ? ineligibilityReasons[0].charAt(0).toUpperCase() +
                      ineligibilityReasons[0].slice(1)
                    : "You are ineligible"
                }
                text={`${
                  typeof eligibleAcres === "number"
                    ? shortenAcreage(eligibleAcres)
                    : 0
                }/${shortenAcreage(
                  totalAcreage
                )} acres eligible for this program`}
              />
            ) : null}

            {!isIneligible && totalAcreage >= 20000 ? (
              <ProjectMapOverlay
                title="Wow! You've got a lot of land"
                text="Let's talk about your eligibility."
                ctaUrl="mailto:landowners@ncx.com"
                ctaText="Get in Touch"
              />
            ) : null}

            {badgeDisplay === LANDOWNER_STATUS.information_needed ? (
              <ProjectMapOverlay
                title="Discover your eligibility"
                text="Answer questions about your land to unlock eligibility."
                ctaType="button"
                ctaText="Check Eligibility"
                ctaOnClick={eligibilityDialog.show}
              />
            ) : null}
          </>
        ) : (
          <ProjectMapSkeleton
            message={
              <div className="lg:max-w-[844px] h-[400px] lg:flex relative rounded bg-gray2 overflow-hidden p-6">
                <div className="bg-gray2 w-full h-full">
                  <div className="w-1/2 mx-auto mt-1/4 bg-white p-12">
                    {genericErrMsg}
                  </div>
                </div>
              </div>
            }
          />
        )}
      </div>
    </div>
  )
}

export default ProjectMap
