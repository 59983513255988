import { createContext } from "react"

import { variants } from "../utils/verifyUserEmailModalVariants"

export interface VerifyUserEmailModalContextValue {
  dialog: any
  variant: keyof typeof variants
}

export const VerifyUserEmailModalContext =
  createContext<VerifyUserEmailModalContextValue | null>(null)
