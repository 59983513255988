import { QueryClient, UseQueryResult } from "@tanstack/react-query"
import { cloneDeep } from "lodash"

import {
  useAccountCycleOverrider,
  useAccountOverrider,
  useQueryWithRefresh,
} from "../utils"
import { getDashboardAccounts } from "../../../api/data"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"
import {
  DashboardAccountsTypes,
  ObjectListItemTypes,
} from "@/types/dashboardAccounts"
import { AccountCycleTypes } from "@/types/accountCycles"

interface UseDashboardAccountsConfig<TData, TError>
  extends Partial<UseQueryWithRefreshConfig<TData, TError>> {
  staleTime?: number
}

export function useDashboardAccounts(
  queryClient: QueryClient,
  config: UseDashboardAccountsConfig<DashboardAccountsTypes, Error> = {}
) {
  const applyAccountOverride = useAccountOverrider()
  const applyAccountCycleOverride = useAccountCycleOverrider()

  const query: UseQueryResult<DashboardAccountsTypes, Error> =
    useQueryWithRefresh<DashboardAccountsTypes, Error>(
      queryClient,
      ["accounts", "dashboard-accounts"],
      getDashboardAccounts,
      config
    )

  const transformedData = query.data ? cloneDeep(query.data) : query.data
  if (transformedData) {
    transformedData?.object_list?.forEach((account: ObjectListItemTypes) => {
      applyAccountOverride(account)
      account.active_cycle_set.forEach((accountCycle: AccountCycleTypes) =>
        applyAccountCycleOverride(accountCycle, account.id.toString())
      )
    })
  }

  return {
    ...query,
    data: transformedData,
  }
}
