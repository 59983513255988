import { QueryClient, UseQueryResult } from "@tanstack/react-query"
import { requestAccountPropertyPreview } from "../../../api/data"
import { useAccountQueryWithRefresh } from "../utils"
import {
  Feature,
  Geometry,
  GeometryCollection,
  Properties,
} from "@turf/helpers"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"

interface DataTypes {
  features: Feature<Geometry | GeometryCollection, Properties>[]
}

export function useAccountPropertyPreview<TData, TError>(
  queryClient: QueryClient,
  accountId: string,
  data: DataTypes,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> {
  return useAccountQueryWithRefresh<TData, TError>(
    queryClient,
    ["accounts", accountId, "property", "preview", data],
    () => requestAccountPropertyPreview(accountId, data),
    config
  )
}
