import { ContentCard } from "../../components/Card"
import IneligibleContent from "./IneligibleContent"
import EligibleContent from "./EligibleContent"
import AssessmentInProgress from "./AssessmentInProgress"

const EligibilityContent = ({
  accountId,
  isMultiAccount,
  isIneligible,
  isBeingAssessed,
  property,
  profile,
}) => (
  <ContentCard>
    {isBeingAssessed ? (
      <AssessmentInProgress
        accountId={accountId}
        isMultiAccount={isMultiAccount}
      />
    ) : isIneligible ? (
      <IneligibleContent />
    ) : (
      <EligibleContent
        accountId={accountId}
        isMultiAccount={isMultiAccount}
        property={property}
        profile={profile}
      />
    )}
  </ContentCard>
)

export default EligibilityContent
