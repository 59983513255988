import React, { HTMLAttributes } from "react"
import cx from "classnames"
import PlacesAutocomplete from "react-places-autocomplete"

interface SuggestionProps {
  children: React.ReactNode
  isActive: boolean
  id: string
}

interface AddressAutocompleteProps {
  value: string
  onChange: (value: string) => void
  onSelect: (address: string) => void
  onError: (error: any) => void
  isLoading?: boolean
  className?: string
}

const Suggestion = ({
  children,
  isActive,
  ...rest
}: SuggestionProps & HTMLAttributes<HTMLDivElement>) => {
  const suggestionClass = cx(
    "p-2 border-b border-gray-200 last:border-0 text-sm",
    {
      "bg-gray-100": isActive,
    }
  )
  return (
    <div className={suggestionClass} {...rest}>
      {children}
    </div>
  )
}

// parcels data is available only for USA so restrict places suggestions to USA
const searchOptions = {
  componentRestrictions: {
    country: "US",
  },
}

export const AddressAutocomplete = ({
  value,
  onChange,
  onSelect,
  onError,
  isLoading,
  className,
}: AddressAutocompleteProps) => (
  <PlacesAutocomplete
    value={value}
    onChange={onChange}
    onSelect={onSelect}
    onError={onError}
    searchOptions={searchOptions}
  >
    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
      <div className={cx(className, "relative")}>
        {isLoading && (
          <div className="absolute right-0 h-full flex items-center justify-center pr-3">
            <i className="block fad fa-spinner-third fa-spin text-green-light"></i>
          </div>
        )}
        <input
          {...getInputProps({
            placeholder: "Search by parcel or mailing address",
            className: "w-full form-input py-3 border-0",
          })}
          disabled={isLoading}
        />
        {/* DEV: z-index is for mobile where dropdown rolls over buttons below it */}
        <div className="autocomplete-dropdown-container absolute left-0 right-0 w-full bg-white z-overlay rounded-sm shadow">
          {loading && (
            <div className="p-4 text-gray-600 text-sm">Loading...</div>
          )}
          {suggestions.map((suggestion) => {
            return (
              <Suggestion
                {...getSuggestionItemProps(suggestion)}
                key={suggestion.placeId}
              >
                {suggestion.description}
              </Suggestion>
            )
          })}
        </div>
      </div>
    )}
  </PlacesAutocomplete>
)

export default AddressAutocomplete
