import { MutableRefObject, ReactNode, useContext, useEffect } from "react"
import { useQueryClient, UseQueryResult } from "@tanstack/react-query"
import { useEffectOnce } from "react-use"
import { useDialogState } from "reakit/Dialog"

import { VerifyUserEmailModal } from "../components/VerifyUserEmailModal"
import { Toast } from "../components/Toast"
import { useAccessToken } from "../stores"
import { useProfile, useQueryParam, useVerifyEmail } from "../hooks"
import { VerifyUserEmailModalContext } from "../context/VerifyUserEmailModalContext"
import {
  VerifyEmailResponseTypes,
  VerifyUserEmailResponseContext,
} from "../context/VerifyUserEmailResponseContext"
import { AxiosError } from "axios"
import { Profile, VerifyUserEmailVariant } from "../types"
import { useScrubQuerystring } from "../hooks/useScrubQuerystring"

/*
Hello, welcome to the file at the heart of email verification

This seemingly simple feature is rather complex in its nature
The main culprits for this are:
- Having non-dismissable modals on some statuses on some screens (e.g. selling process)
    (i.e. need to wait for requests to load before showing our dialog)
- Needing to persist showing modal through redirects but not on multiple page navigation
    (i.e. if new user, then navigation to / becomes redirect to /onboarding/contact-info)

We've resolved this through 3 stages:

- App.js level -> VerifyEmailOuterContent
  - This ensures we don't drop requests that we're firing for verification, even through redirects
  - We'd like to show `Toast` messages here, but that requires having `profile` info loaded
- Page level -> Customizing what dialog should be showing/how dismissable it is
  - This comes through as `verifyUserEmailVariant` to `Main.js`
- Main.js layout level -> VerifyEmailInnerContent
  - This provides a common layer for rendering the dialog so we don't forget to on a new page
  - Once the render completes, then we clear out the original request at the top level

As you might expect, this is rather tricky to test (as well as this is an infrequently touched feature),
so we're leaning on manual testing for now.
We had it at one point but have removed it, https://github.com/ncx-co/ncapx-platform/blob/25dea2ba4fb18dad192d295d5b34bc49142f63cb/src/pages/_layouts/__tests__/Main.js
Here are test cases to verify:

- On new sign up, we prompt a user to verify their email with a dismissable modal
- On verifying a new user, we can follow the link through a navigation and see Toast success message
  - Try the same for an invalid key + showing that modal: e.g. http://127.0.0.1:3000/?confirm=email&key=foo
- For a user which already existed, we show a dismissable modal
- For a user which already existed, we can follow a sent verification email and see Toast success message
- For a user which has already verified their email, they see a Toast success message about "already being verified"
- For a user which ran into key expiration on verification, they see a modal with expiration message
- For a user which ran into an error on verification, they see a modal with an error message
- For a user which ran into an error on sending a new verification email, they see a modal with an error message
- For a user viewing a non-dismissable page (e.g. /settings/payment), they see a non-dismissable modal
  - The modal is also not dismissable on key error
- A user who dismissed their modal, can re-open the modal via screens like /settings/contact-info
*/

interface VerifyEmailOuterContentProps {
  children: ReactNode
}

interface VerifyEmailInnerContentProps {
  profile: Profile
  children: ReactNode
  verifyUserEmailVariant?: VerifyUserEmailVariant
  verifyUserEmailModalFinalFocusRef: MutableRefObject<HTMLAnchorElement | null>
}

export const VerifyEmailOuterContent = ({
  children,
}: VerifyEmailOuterContentProps) => {
  const queryClient = useQueryClient()
  const accessToken = useAccessToken()
  const isLoggedIn = !!accessToken

  const isUserConfirmingEmail = useQueryParam("confirm") === "email"
  // DEV: `key` is from the Django library we're using, naming variable `token` for clarity
  const confirmEmailToken = useQueryParam("key")
  const scrubQuerystring = useScrubQuerystring()

  const { data: profile } = useProfile(queryClient, {
    enabled: !!accessToken,
  }) as UseQueryResult<Profile>

  // DEV: If there's an error, `data` will be `undefined`. On success, it will be filled
  const {
    mutate: verifyEmail,
    data,
    error,
    status,
    isIdle,
    reset,
  } = useVerifyEmail(queryClient)

  const verifyEmailResponse: VerifyEmailResponseTypes = {
    data,
    error: error as AxiosError<unknown, any> | null,
    status,
    isIdle,
    reset,
  }

  // This used to be `useEffectOnce` but this effect is evaluated multiple times for users
  // who are logged out when they click the email verification link:
  //
  // 1. Landing at /?confirm=email&key=<verification token>, `isLoggedIn` === false
  // 2. Redirected to /login, `isLoggedIn` === false
  // 3. On receipt of access token (`isLoggedIn` changes), still at /login, `isLoggedIn` === true
  //
  // If a user clicked an email verification link, then `isUserConfirmingEmail` === true
  // and `confirmEmailToken` is populated, so the result of this dependency array
  // is that we call `verifyEmail` a single time, after `isLoggedIn` becomes true
  // (after #3 above)
  // https://app.asana.com/0/1198952737412966/1202733928483468/f
  useEffect(() => {
    if (
      isLoggedIn &&
      isUserConfirmingEmail &&
      confirmEmailToken &&
      profile?.is_email_verified !== true
    ) {
      verifyEmail({ key: confirmEmailToken })
      scrubQuerystring() // Scrubs `key=****` and `confirm=email`
    }
    // Including `verifyEmail` as a dependency here causes infinite re-runs of this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoggedIn,
    isUserConfirmingEmail,
    confirmEmailToken,
    profile?.is_email_verified,
  ])

  return (
    // DEV: We're mostly trusting that we only use MainLayout for the underlying one if we're logged in
    //   Otherwise, there's no surfacing of the `verifyEmailResponse`
    //   We require this structure so we can alter dismissable/not
    <VerifyUserEmailResponseContext.Provider value={verifyEmailResponse}>
      {children}
    </VerifyUserEmailResponseContext.Provider>
  )
}

// DEV: Using a separate component allows to wait until `profile` loaded to set `visible`
export const VerifyEmailInnerContent = ({
  profile,
  children,
  verifyUserEmailVariant = "generic",
  verifyUserEmailModalFinalFocusRef,
}: VerifyEmailInnerContentProps) => {
  const verifyEmailResponse = useContext(VerifyUserEmailResponseContext)

  // Define default values to avoid conditional hook calls
  let verifyEmailError: AxiosError | null = null
  let verifyEmailStatus: "error" | "idle" | "pending" | "success" = "idle"
  let verifyEmailIsIdle = true
  let resetVerifyEmailResponse = () => {}

  if (verifyEmailResponse) {
    verifyEmailError = verifyEmailResponse.error
    verifyEmailStatus = verifyEmailResponse.status
    verifyEmailIsIdle = verifyEmailResponse.isIdle
    resetVerifyEmailResponse = verifyEmailResponse.reset
  }

  // Determine whether we should show a modal, toast, or nothing (regardless of dismiss)
  const isEmailVerified = profile.is_email_verified
  const firedVerifyEmailRequest = !verifyEmailIsIdle
  let toastMessage: string | null = null
  let initialVerifyModalStatus: string | boolean = false
  // If we fired a verifyEmail request, the user is confirming their email
  if (firedVerifyEmailRequest) {
    if (verifyEmailStatus === "success") {
      toastMessage = "Your email address has been successfully verified!"
    } else if (verifyEmailStatus === "error") {
      // DEV: If an email verification token was already used, then we'll get `error`
      //   but the profile would still have `is_email_verified` set
      if (isEmailVerified) {
        toastMessage =
          "Your email address has already been verified. Thank you!"
        // django-allauth returns a 404 for expired or invalid keys
        // https://github.com/pennersr/django-allauth/blob/0.50.0/allauth/account/models.py#L147-L149
      } else if (verifyEmailError?.response?.status === 404) {
        initialVerifyModalStatus = "key-invalid"
      } else {
        initialVerifyModalStatus = "error"
      }
    } else {
      throw new Error(
        `Encountered unexpected status "${verifyEmailStatus}". Request should be completed already`
      )
    }
  } else {
    // Else user is on site normally. Show email verification modal if they're
    // not already verified and haven't recently dismissed it or can't dismiss it.
    initialVerifyModalStatus = !isEmailVerified
  }

  // DEV: We'll only run "verify email request" on page load, so `useEffectOnce` is perfect
  useEffectOnce(() => {
    if (toastMessage) {
      Toast.success(toastMessage)
    }

    // DEV: If we don't reset `verifyEmailResponse`, then we show Toast messages + "key expired" modals on page nav
    if (firedVerifyEmailRequest) {
      resetVerifyEmailResponse()
    }
  })

  const verifyUserEmailDialog = useDialogState({
    animated: true,
    visible: !!initialVerifyModalStatus,
  })

  return (
    // Exposes `dialog` to all `children` elements
    //   If an element is unable to access the content via `useContext`
    //   then it might be invoking *before* `MainLayout` is used
    //   Try putting all the children content into its own component
    //   which occurs inside of `MainLayout` instead
    //   e.g.
    //   Bad:
    //   const MyPage = () => {
    //     const { dialog } = useContext(VerifyUserEmailModalContext)
    //     return <MainLayout>
    //       <DialogDisclosure {...dialog}>Click me</DialogDisclosure>
    //     </MainLayout>
    //   }
    //   Good:
    //   const ContactInfoForm = () => {
    //     const { dialog } = useContext(VerifyUserEmailModalContext)
    //     return <DialogDisclosure {...dialog}>Click me</DialogDisclosure>
    //   }
    //   const MyPage = () => (
    //     <MainLayout><ContactInfoForm /></MainLayout>
    //   )
    //   Why this works: We'd be cloning `children` to pass in new `props` with `dialog` otherwise, https://stackoverflow.com/a/35102287/1960509
    //     So the same nesting would be needed, context gives us a shortcut to avoid that + props passing down the tree
    // DEV: Expose `variant` through `Context` so we can check it for `liableAction` in `ActionPermissionWrapper`
    <VerifyUserEmailModalContext.Provider
      value={{ dialog: verifyUserEmailDialog, variant: verifyUserEmailVariant }}
    >
      {children}
      <VerifyUserEmailModal
        profile={profile}
        initialVerifyStatus={initialVerifyModalStatus}
        unstable_finalFocusRef={verifyUserEmailModalFinalFocusRef}
      />
    </VerifyUserEmailModalContext.Provider>
  )
}
