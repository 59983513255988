import cx from "classnames"
import { useState } from "react"
import { DialogDisclosure } from "reakit/Dialog"
import { useQueryClient } from "@tanstack/react-query"

import { DEADLINE_TYPE, LANDOWNER_STATUS } from "../../shared/constants"
import { SubmitButton } from "../../components/SubmitButton"
import { ActionPermissionWrapper } from "../../components/ActionPermissionWrapper"
import { Toast } from "../../components/Toast"
import { genericErrMsg } from "../../api/auth"
import { useUpdateAccountProject } from "../../hooks"

const RequestInformationBtn = ({
  requestInfoDialog,
  landowner_status,
  projectId,
  accountId,
  onRequestCallSubmit,
  sourceIdentifier,
  accountRole,
  enrollmentDeadlineType,
  ctaOverride,
  ctaOverrideUrl,
  requires_service_provider,
  has_service_provider_coverage,
  formikProps,
}) => {
  const queryClient = useQueryClient()

  const hasRequestedCall =
    landowner_status &&
    landowner_status !== LANDOWNER_STATUS.not_interested &&
    landowner_status !== LANDOWNER_STATUS.lo_not_interested &&
    landowner_status !== LANDOWNER_STATUS.internal_dq
  const isCustomCta = ctaOverride?.length > 0 && ctaOverrideUrl?.length > 0
  const disableRequestCall = formikProps
    ? !(formikProps.isValid && formikProps.dirty)
    : isCustomCta
      ? false
      : hasRequestedCall

  const [isSubmitting, setIsSubmitting] = useState(false)
  const { mutateAsync: updateAccountProject } = useUpdateAccountProject(
    queryClient,
    accountId,
    projectId
  )

  let REQUEST_CALL

  if (hasRequestedCall) {
    REQUEST_CALL = "Requested"
  } else if (ctaOverride?.length > 0) {
    REQUEST_CALL = ctaOverride
  } else if (enrollmentDeadlineType === DEADLINE_TYPE.pending_interest) {
    REQUEST_CALL = "Join Waitlist"
  } else if (requires_service_provider && !has_service_provider_coverage) {
    REQUEST_CALL = "Join Waitlist"
  } else {
    REQUEST_CALL = "Request Call"
  }

  const onSubmitAction = async () => {
    setIsSubmitting(true)
    // DEV: this to detect if the button is part of a formik form (i.e. PreferredContactMethodForm)
    // which has its own submit actions
    if (formikProps && formikProps.handleSubmit) {
      await formikProps.handleSubmit()
      setIsSubmitting(false)
    } else {
      await updateAccountProject(
        { status: LANDOWNER_STATUS.request_information },
        {
          onSuccess: () => {
            setIsSubmitting(false)
            onRequestCallSubmit && onRequestCallSubmit(true)
          },
          onError: (error) => {
            setIsSubmitting(false)
            Toast.error(error?.detail || genericErrMsg)
            onRequestCallSubmit && onRequestCallSubmit(false)
          },
        }
      )
    }
  }
  // buttons to either open an external link or move to next step in EligibilityInterestModal
  if (!requestInfoDialog || isCustomCta) {
    const sourceIdClass = isCustomCta
      ? `list-land-btn-${sourceIdentifier}`
      : `request-call-btn-${sourceIdentifier}`
    return (
      <ActionPermissionWrapper accountRole={accountRole} action="editAccount">
        <SubmitButton
          onClick={onSubmitAction}
          isSubmitting={isSubmitting}
          disabled={disableRequestCall}
          typeButton
          className={cx(
            `text-base font-bold btn2 btn2-primary w-full sm:w-auto ${sourceIdClass}`,
            {
              enabled: !disableRequestCall,
            }
          )}
        >
          {isCustomCta ? ctaOverride : REQUEST_CALL}
        </SubmitButton>
      </ActionPermissionWrapper>
    )
  }
  // opens a dialog to confirm requesting a call. Used in the project details page
  return (
    <ActionPermissionWrapper accountRole={accountRole} action="editAccount">
      <DialogDisclosure
        {...requestInfoDialog}
        className={cx(
          "text-base font-bold btn2 btn2-primary w-full sm:w-auto",
          `request-call-btn-${sourceIdentifier}`,
          {
            enabled: !disableRequestCall,
          }
        )}
        disabled={disableRequestCall}
      >
        {REQUEST_CALL}
      </DialogDisclosure>
    </ActionPermissionWrapper>
  )
}

export default RequestInformationBtn
