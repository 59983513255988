import { QueryClient, UseQueryResult } from "@tanstack/react-query"

import { useAccountAccountCycles } from "./useAccountAccountCycles"
import { AccountCycleTypes } from "@/types/accountCycles"

export function useAccountLatestAccountCycle(
  queryClient: QueryClient,
  accountId: string
): UseQueryResult<AccountCycleTypes | null, Error> {
  // Resolve our cycles info
  const { data, status, isLoading } = useAccountAccountCycles<
    AccountCycleTypes[],
    Error
  >(queryClient, accountId, {
    is_archived: false,
  })

  // If we're not yet loaded, do nothing
  if (status !== "success") {
    return { data: null, status, isLoading } as UseQueryResult<
      AccountCycleTypes | null,
      Error
    >
  }

  // Otherwise, return our latest cycle
  // DEV: Cycles should come pre-sorted from the API (otherwise our dashboard will look wonky)
  // One day this may move to `object_list`, but not today, https://app.asana.com/0/1201653128864814/1201759560003317/f
  let accountCycles: AccountCycleTypes[]
  // DEV: We are depreciating the use of accountcycles and new accounts will not have any cycles.  To support the payment tab in
  // settings though we have to return one. This will be removed once the payment tab is removed.
  if (data.length === 0) {
    accountCycles = [
      {
        cycle_key: "23D",
        status: "created",
        access_info_plot_map_document: undefined,
        enrollment: undefined,
        rr_document: undefined,
        rr_review_document: undefined,
      },
    ]
  } else {
    accountCycles = data
  }

  return { data: accountCycles[0], status, isLoading } as UseQueryResult<
    AccountCycleTypes | null,
    Error
  >
}
