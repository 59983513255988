import { Link } from "react-router-dom"
import IMG from "../../images/created-content.png"
import LandCoverContent from "./LandCoverContent"

const EligibleContent = ({ accountId, isMultiAccount, property, profile }) => {
  return (
    <>
      {property?.landcover_data ? (
        <LandCoverContent
          accountId={accountId}
          profile={profile}
          property={property}
        />
      ) : (
        <>
          <h3 className="text-xl mb-4">
            Your land is eligible for an opportunity
          </h3>
          <div className="mb-4">
            <img src={IMG} alt="" className="w-full h-auto" />
          </div>
          <div className="mt-2 mb-4 space-y-p">
            <p>
              Based on the land you submitted, you are eligible for a program
              opportunity!
            </p>
            <p>
              Visit the Programs page to browse, compare, and view program
              details. You can access the Programs at any time in the navigation
              menu.
            </p>
          </div>
        </>
      )}
      <div>
        <Link
          to={
            isMultiAccount
              ? `/accounts/${accountId}/programs/page/1`
              : "/programs/page/1"
          }
          className="btn2 btn2-primary font-semibold"
        >
          View Programs
        </Link>
      </div>
    </>
  )
}

export default EligibleContent
