import { MouseEvent, useContext, useEffect, useRef } from "react"
import {
  ScrollMenu,
  VisibilityContext,
  publicApiType,
} from "react-horizontal-scrolling-menu"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

import useDrag from "../hooks/useDrag"
import usePreventBodyScroll from "../hooks/usePreventBodyScroll"
import { getArrowItemsObject, onWheel } from "../shared/utils"

export interface FilterType {
  id: string
  tag: string
  selected: boolean
}

interface FilterChipProps {
  tag: string
  selected: boolean
  onChange: () => void
}

interface ArrowProps {
  onClick: () => void
  icon: any
}

interface FilterChipsProps {
  filters: FilterType[]
  onChange: (filter: FilterType) => void
}

const FilterChip = ({ tag, selected, onChange }: FilterChipProps) => (
  <div className="p-1">
    <label
      htmlFor={tag}
      className={cx("filter-chip", {
        "filter-chip-active": selected,
      })}
      aria-label={`Filter programs by ${tag}`}
    >
      <input
        type="checkbox"
        id={tag}
        className="filter-chip-input"
        checked={selected}
        onChange={onChange}
      />
      {tag}
    </label>
  </div>
)

const Arrow = ({ onClick, icon }: ArrowProps) => (
  <button type="button" onClick={onClick} className="filter-chips-arrow">
    <FontAwesomeIcon icon={icon} />
  </button>
)

const LeftArrow = () => {
  const { useIsVisible, getPrevElement, scrollToItem, items } =
    useContext<publicApiType>(VisibilityContext)
  const isFirstItemVisible = useIsVisible("first", true)
  const itemsObject = getArrowItemsObject(items)

  if (isFirstItemVisible) {
    return null
  }

  return (
    <Arrow
      onClick={() => {
        const prevElement = getPrevElement()
        if (!prevElement) {
          return
        }

        let centerItem

        if (prevElement?.entry.isIntersecting) {
          centerItem = prevElement
        } else {
          centerItem = Object.values(itemsObject).find(
            (item) => Number(item.index) === Number(prevElement.index) + 1
          )
        }

        if (centerItem) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          scrollToItem(centerItem, "smooth", "center")
        }
      }}
      icon={faArrowLeft}
    />
  )
}

const RightArrow = () => {
  const { useIsVisible, getNextElement, scrollToItem, items } =
    useContext<publicApiType>(VisibilityContext)
  const isLastItemVisible = useIsVisible("last", false)
  const itemsObject = getArrowItemsObject(items)

  if (isLastItemVisible) {
    return null
  }

  return (
    <Arrow
      onClick={() => {
        const nextElement = getNextElement()
        if (!nextElement) {
          return
        }

        let centerItem

        if (nextElement?.entry.isIntersecting) {
          centerItem = nextElement
        } else {
          centerItem = Object.values(itemsObject).find(
            (item) => Number(item.index) === Number(nextElement.index) - 1
          )
        }

        if (centerItem) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          scrollToItem(centerItem, "smooth", "center")
        }
      }}
      icon={faArrowRight}
    />
  )
}

const FilterChips = ({ filters = [], onChange }: FilterChipsProps) => {
  const { dragStart, dragStop, dragMove, dragging } = useDrag()
  const { disableScroll, enableScroll } = usePreventBodyScroll()
  const selectedFilter = filters.find((filter) => filter.selected)
  const scrollMenuRef = useRef<publicApiType>(null!)

  useEffect(() => {
    if (selectedFilter && scrollMenuRef.current) {
      const itemElement = scrollMenuRef.current.getItemElementById(
        selectedFilter.id
      )
      if (itemElement) {
        scrollMenuRef.current.scrollToItem(itemElement, "smooth", "center")
      }
    }
  }, [filters, selectedFilter])

  const handleDrag =
    ({ scrollContainer }: publicApiType) =>
    (ev: MouseEvent) =>
      dragMove(ev, (posDiff: number) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff
        }
      })

  return (
    <div
      className="filter-chips"
      onMouseEnter={disableScroll}
      onMouseLeave={() => {
        enableScroll()
        dragStop()
      }}
    >
      <ScrollMenu
        apiRef={scrollMenuRef}
        LeftArrow={<LeftArrow />}
        RightArrow={<RightArrow />}
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
        onWheel={onWheel}
      >
        {filters.map((filter) => (
          <FilterChip
            key={filter.id}
            tag={filter.tag}
            selected={filter.selected}
            onChange={() => !dragging && onChange(filter)}
          />
        ))}
      </ScrollMenu>
    </div>
  )
}

export default FilterChips
