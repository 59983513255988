import { Formik, FormikProps } from "formik"
import { Link } from "react-router-dom"
import { DialogDisclosure, useDialogState } from "reakit/Dialog"
import { ActionPermissionWrapper } from "../components/ActionPermissionWrapper"
import { Modal } from "../components/Modal"
import { RadioGroup } from "../components/RadioGroup"
import { DynamicTipaltiIframe } from "../components/TipaltiIframe"
import { useAccountUrlPrefix } from "../hooks"
import { AccountTypes } from "@/types/account"

interface AttestationTypes {
  attestation: "authorized" | "not authorized"
}

type FormComponentValuesType = AttestationTypes | Record<string, never>

type EditModeType = "modal" | "inline"

interface FormComponentTypes {
  account: AccountTypes
  editMode: EditModeType
  formikProps: FormikProps<FormComponentValuesType>
}

interface PaymentInfoPromptTypes {
  account: AccountTypes
  editMode: EditModeType
}

const ATTESTATION_AUTHORIZED = "authorized"
const ATTESTATION_NOT_AUTHORIZED = "not authorized"

const FormComponent = ({
  account,
  editMode = "modal",
  formikProps,
}: FormComponentTypes) => {
  const accountUrlPrefix = useAccountUrlPrefix()
  const paymentInfoDialog = useDialogState({ animated: true })
  const { values } = formikProps

  const paymentInfoComplete = account.payment_info_complete

  const modalTitle = paymentInfoComplete
    ? "Edit payment information"
    : "Enter payment information"

  // If the we're replacing our prompt inline and the payment dialog would be opened, then show inline content
  if (editMode === "inline") {
    if (paymentInfoDialog.visible) {
      return (
        <DynamicTipaltiIframe
          tipaltiIframeType="PaymentDetails"
          className="border-smoke border-1"
        />
      )
    }
  } else if (editMode !== "modal") {
    throw new Error(`Unrecognized editMode ${editMode as string}`)
  }

  // Otherwise, show our prompt and its normal modal dialog
  return (
    <>
      <div className="mb-4">
        <RadioGroup
          valueContentTuples={[
            [
              ATTESTATION_AUTHORIZED,
              <p key={ATTESTATION_AUTHORIZED}>
                <strong>I'm an authorized representative:</strong>{" "}
              </p>,
            ],
            [
              ATTESTATION_NOT_AUTHORIZED,
              <p key={ATTESTATION_NOT_AUTHORIZED}>
                <strong>I'm not an authorized representative:</strong>{" "}
              </p>,
            ],
          ]}
          name="attestation"
        />
      </div>
      <div className="mb-2">
        {values.attestation === ATTESTATION_AUTHORIZED ? (
          <ActionPermissionWrapper
            accountRole={account.role}
            action="liableAction"
          >
            <DialogDisclosure
              className="btn2 btn2-primary font-semibold"
              {...paymentInfoDialog}
            >
              {paymentInfoComplete
                ? "Edit your payment information"
                : "Enter your payment information"}
            </DialogDisclosure>
          </ActionPermissionWrapper>
        ) : values.attestation === ATTESTATION_NOT_AUTHORIZED ? (
          <Link
            to={{
              pathname: `${accountUrlPrefix}/settings/users`,
            }}
            state={{ openAddModal: true }}
            className="btn2 btn2-primary font-semibold"
          >
            Invite an authorized user
          </Link>
        ) : (
          <button
            type="button"
            className="btn2 btn2-primary font-semibold"
            disabled={true}
          >
            Select a designation first
          </button>
        )}
      </div>
      {editMode === "modal" && (
        <Modal
          header={modalTitle}
          aria-label={modalTitle}
          // Prevent accidental closes, as per user testing: https://app.asana.com/0/1201467379381113/1202050023464744/f
          hideOnEsc={false}
          hideOnClickOutside={false}
          // DEV: Use bottom margin inside since padding is ignored when we need to scroll
          className="px-6 pt-6 pb-0 max-w-3xl"
          dialog={paymentInfoDialog}
        >
          <div className="mb-8">
            {/* DEV: Only render Tipalti iframe once modal visible, otherwise it will expire */}
            {paymentInfoDialog.visible && (
              <DynamicTipaltiIframe
                tipaltiIframeType="PaymentDetails"
                className="border-smoke border-1"
              />
            )}
          </div>
        </Modal>
      )}
      <p className="text-sm text-dusk">
        <a
          href="https://tipalti.com/privacy/"
          className="link--underline-only"
          target="_blank"
          rel="noopener noreferrer"
        >
          NCX uses Tipalti to facilitate safe and secure payments. To learn more
          about how Tipalti safeguards your information, view their privacy
          policy <span className="link">here</span>
        </a>
        .
      </p>
    </>
  )
}

export const PaymentInfoPrompt = ({
  account,
  editMode,
}: PaymentInfoPromptTypes) => {
  // DEV: This isn't a typical Formik in that it's a light form and no validation
  //   but it's to follow best practices for our RadioGroup and resolving values via Formik context
  return (
    <Formik initialValues={{}} onSubmit={() => {}}>
      {(formikProps) => (
        <>
          {/* No <UnsavedChangesPrompt /> since only a radio input, and possible conflict with <Link> navigation */}
          <FormComponent
            formikProps={formikProps}
            account={account}
            editMode={editMode}
          />
        </>
      )}
    </Formik>
  )
}
