import { Modal } from "../../../components/Modal"

const WhatIsThisNumberModal = ({ dialog }) => (
  <Modal
    header="What is time adjusted value?"
    aria-label="What is Time Adjusted Value?"
    dialog={dialog}
    className="p-5"
  >
    <p className="text-base text-charcoal-500 leading-130 tracking-0.32 mb-4">
      Time adjusted value is an estimate of today's net present value of the
      cash receipts and payments over the full life of the program.
    </p>

    <p className="text-base text-charcoal-500 leading-130 tracking-0.32 mb-4">
      This estimate uses the time value of money - the idea that money today is
      more valuable than the same amount in the future because it can be used or
      invested now.{" "}
      <a
        href="https://ncx.com/learning-hub/comparing-payment-schedules-between-natural-capital-programs"
        target="_blank"
        rel="noopener noreferrer"
        className="link"
      >
        Learn more
      </a>
    </p>

    <button
      type="button"
      className="btn2 btn2-primary font-semibold"
      onClick={dialog.hide}
    >
      Got it
    </button>
  </Modal>
)

export default WhatIsThisNumberModal
