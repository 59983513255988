import React, { ReactNode } from "react"

export interface Profile {
  email: string
}

export interface VariantContent {
  header: string
  body: ReactNode
  dismissable?: boolean
  isCloseButtonPrimary?: boolean
  videoId?: string
}

export const variants = {
  generic: ({ profile }: { profile: Profile }): VariantContent => ({
    header: "Email verification required",
    body: (
      <>
        <p>
          A verification email from landowners@ncx.com was sent to{" "}
          <span className="font-bold">{profile.email}</span>.
        </p>
        <p className="mt-2">
          Watch the video tutorial below to learn how to verify your email.
        </p>
      </>
    ),
    videoId: "cilyv99ojl",
    dismissable: false,
    isCloseButtonPrimary: false,
  }),
}
