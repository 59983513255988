import bbox from "@turf/bbox"

const DEFAULT_ZOOM_LEVEL = 12

export const getViewportFromFeature = (feature, width = 600, height = 400) => {
  const bounds = bbox({
    type: "FeatureCollection",
    features: [feature],
  })

  const [minLng, minLat, maxLng, maxLat] = bounds

  const longitude = (minLng + maxLng) / 2
  const latitude = (minLat + maxLat) / 2

  // DEV: Calculate the zoom level based on the bounding box
  const deltaLng = maxLng - minLng
  const deltaLat = maxLat - minLat

  // Calculate zoom levels based on the width and height of the map
  const zoomWidth = Math.floor(Math.log2(((width / deltaLng) * 360) / 512))
  const zoomHeight = Math.floor(Math.log2(((height / deltaLat) * 170) / 512))

  const zoom = Math.min(zoomWidth, zoomHeight, 20)

  return {
    longitude,
    latitude,
    zoom: zoom || DEFAULT_ZOOM_LEVEL,
  }
}

const getPreviousValidFeatureIndex = (features, currentIndex) => {
  const previousIndex = features
    .slice(0, currentIndex)
    .reverse()
    .findIndex((feature) => feature.properties.elig_acres !== 0)

  return previousIndex !== -1 ? currentIndex - previousIndex - 1 : currentIndex
}

export const handlePrevFeature = (
  setActiveFeature,
  setViewport,
  parcelData
) => {
  setActiveFeature((oldFeature) => {
    const previousValidIndex = getPreviousValidFeatureIndex(
      parcelData?.features,
      oldFeature
    )

    if (previousValidIndex !== oldFeature) {
      setViewport(
        getViewportFromFeature(parcelData?.features[previousValidIndex])
      )
    }

    return previousValidIndex
  })
}

const getNextValidFeatureIndex = (features, currentIndex) => {
  const nextIndex = features.findIndex(
    (feature, index) =>
      index > currentIndex && feature.properties.elig_acres !== 0
  )

  return nextIndex !== -1 ? nextIndex : currentIndex
}

export const handleNextFeature = (
  setActiveFeature,
  setViewport,
  parcelData
) => {
  setActiveFeature((oldFeature) => {
    const nextValidIndex = getNextValidFeatureIndex(
      parcelData?.features,
      oldFeature
    )

    if (nextValidIndex !== oldFeature) {
      setViewport(getViewportFromFeature(parcelData?.features[nextValidIndex]))
    }

    return nextValidIndex
  })
}
