import assert from "assert"

interface HasRequiredPermissionTypes {
  action: string
  accountRole: string
}

const ACTION_TO_PERMISSION_MAP = {
  editAccount: "edit",
  editUserSelf: null, // No permission check
  editUser: "admin",
  liableAction: "edit", // Sign NSA, enter tax info
}

export const hasRequiredPermission = ({
  action,
  accountRole,
}: HasRequiredPermissionTypes) => {
  assert(!!accountRole, "accountRole must be specified")
  assert(!!action, "action must be specified")
  assert(
    Object.prototype.hasOwnProperty.call(ACTION_TO_PERMISSION_MAP, action),
    `Action ${action} not defined in ACTION_TO_PERMISSION_MAP`
  )
  const permissionRequired =
    ACTION_TO_PERMISSION_MAP[action as keyof typeof ACTION_TO_PERMISSION_MAP]
  if (permissionRequired === "admin") {
    return accountRole === "admin"
  } else if (permissionRequired === "edit") {
    return ["admin", "editor"].includes(accountRole)
  } else if (permissionRequired === "view") {
    return true // All supported
    // Treat `null` as explicitly disabled (unlike `undefined`)
  } else if (permissionRequired === null) {
    return true
  } else {
    throw new Error(`Unrecognized permissionRequired ${permissionRequired}`)
  }
}
