import assert from "assert"
import React, { ReactNode } from "react"
import { Checkbox, CheckboxState, useCheckboxState } from "reakit/Checkbox"
import { useField } from "formik"
import cx from "classnames"

type ValueContentTuple = [string, string | ReactNode]

interface CheckboxGroupProps {
  valueContentTuples: ValueContentTuple[]
  name: string
  className?: string
  state?: CheckboxState
}

const CheckboxGroup = ({
  valueContentTuples,
  name,
  className,
  state,
}: CheckboxGroupProps) => {
  assert.notStrictEqual(name, undefined, "`name` prop should be provided")
  const [field] = useField(name)
  const checkbox = useCheckboxState(state)

  return (
    <div className={cx("reakit-checkbox-group", className)}>
      {valueContentTuples.map(([value, content]) => (
        <label key={value}>
          <Checkbox {...field} {...checkbox} name={name} value={value} />
          <span className="flex grow pl-2 text-neutral-800 text-base font-normal">
            {content}
          </span>
        </label>
      ))}
    </div>
  )
}

export default CheckboxGroup
