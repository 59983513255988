import React, { useState, useRef, useEffect, ReactNode } from "react"
import cx from "classnames"
import { useIsOverflow } from "../hooks/useIsOverflow"

interface ExpandableContainerProps {
  id: string
  btnTextOpen: string
  btnTextClose: string
  className?: string
  containerHeight?: number
  children: ReactNode
}

const CONTAINER_HEIGHT = 492

const ExpandableContainer = ({
  id,
  children,
  btnTextOpen,
  btnTextClose,
  containerHeight = CONTAINER_HEIGHT,
  className,
}: ExpandableContainerProps) => {
  const [expanded, setExpanded] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const { isOverflow, scrollHeight } = useIsOverflow(ref, containerHeight)

  useEffect(() => {
    setExpanded(false)
  }, [id])

  return (
    <div className={cx("relative", className)}>
      <div
        ref={ref}
        className="overflow-hidden h-auto transition-max-height duration-300 ease-in-out"
        // DEV: we use style instead of tailwind classes here in order to animate the container height from fixed to its dynamic scrollHeight value
        style={{
          maxHeight: expanded ? scrollHeight : containerHeight,
        }}
      >
        {children}
      </div>
      {!expanded && isOverflow && (
        <div className="h-32 absolute bottom-14 left-0 right-0 bg-gradient-to-t from-cloud-50 to-transparent expandable-container-unexpanded"></div>
      )}
      {isOverflow && (
        <div className="text-center mt-8">
          <button
            type="button"
            className="text-leaf font-semibold focus:outline-none hover:underline"
            onClick={() => setExpanded((state) => !state)}
          >
            {expanded ? btnTextClose : btnTextOpen}
          </button>
        </div>
      )}
    </div>
  )
}

export default ExpandableContainer
