import { QueryClient, UseMutationResult } from "@tanstack/react-query"

import { useMutationWithRefresh } from "../utils"
import { getAttestationQuiz } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"

export const useRefetchAttestationQuizes = <TData, TError, TVariables>(
  queryClient: QueryClient,
  accountId: string,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables> => {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    () => getAttestationQuiz(accountId),
    {
      _onSuccess: (data) => {
        queryClient.setQueryData(
          ["accounts", accountId, "attestationQuizes"],
          data
        )
      },
      ...config,
    }
  )
}
