import { faLightbulbOn } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const StayInTheLoop = () => (
  <>
    <div className="mt-4 mb-2">
      <h4 className="text-overline">Stay in the loop</h4>
    </div>
    <div className="rounded bg-cloud-500 border border-solid border-dusk-50 p-6">
      <div className="flex">
        <div className="flex items-center shrink-0 text-leaf px-1">
          <FontAwesomeIcon icon={faLightbulbOn} />
        </div>
        <div className="ml-2">
          <p className="text-base pr-4">
            To get updates, add{" "}
            <a
              href="mailto:landowners@ncx.com"
              className="link link--underline-only"
            >
              landowners@ncx.com
            </a>{" "}
            to your email contacts and regularly log in to your NCX account for
            the most recent information.
          </p>
        </div>
      </div>
    </div>
  </>
)

export default StayInTheLoop
