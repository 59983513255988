// If a user tries to log in with email but we have them as SSO, make them signin with Sso
import { useContext, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { OptimizelyContext } from "@optimizely/react-sdk"

import { useLoginWithGoogle } from "../../hooks"
import { genericErrMsg } from "../../api/auth"
import {
  identifyUserForIntegrations,
  sendAnalyticsEvent,
  heapTrackEvent,
} from "../../api/integrations"
import { ContentCard, ErrorCard } from "../../components/Card"
import { SsoAuthButtons } from "./SsoAuthButtons"

const VITE_APP_PARTNER_VIEW = import.meta.env.VITE_APP_PARTNER_VIEW

const SsoLogin = () => {
  const { optimizely } = useContext(OptimizelyContext)

  const location = useLocation()
  const navigate = useNavigate()

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { mutateAsync: loginWithGoogle } = useLoginWithGoogle({
    /*
     * data is from api response (our jwt info + user info),
     * variables is what we send in onSubmit (google token)
     */
    onSuccess: (
      data: { email: string; profile_type: string },
      _variables: any
    ) => {
      identifyUserForIntegrations(data.email, optimizely)
      sendAnalyticsEvent({
        action: "login",
        label: "Landowners Login with Social",
      })
      heapTrackEvent("google_login", { authStep: "sso-login", newUser: false })
      // DEV: multi-account users have to select an account context before viewing programs
      const landingPage =
        data?.profile_type === "multi_account" && VITE_APP_PARTNER_VIEW === "0"
          ? "/accounts"
          : "/programs"
      // Complete any existing login state, https://reactrouter.com/web/example/auth-workflow
      // DEV: They use `replace` but I'd rather keep `login` on the history stack
      const redirect: string = location.state?.from || landingPage
      navigate(redirect)
    },
    onError: () => {
      setErrorMessage(genericErrMsg)
    },
  })

  const handleSubmitSso = async (values: { accessToken: string }) => {
    setErrorMessage(null)
    await loginWithGoogle(values)
  }
  return (
    <ContentCard>
      <h3 className="text-lg text-center mb-2">
        <span>Welcome Back</span>
      </h3>
      <p className="text-base text-center leading-5 my-2">
        Log in to explore programs
      </p>
      {errorMessage && <ErrorCard>{errorMessage}</ErrorCard>}
      {/* DEV: when we have more than just Google, 
        we'll need to pass in the provider to show the right button */}
      <div className="space-y-4">
        <div className="my-6">
          <SsoAuthButtons
            onSuccess={(values: { accessToken: string }) => {
              handleSubmitSso(values)
            }}
            onError={() => setErrorMessage(genericErrMsg)}
          />
        </div>
        <hr className="border-charcoal-50" />
        <div className="text-center text-dusk">
          Need Help?{" "}
          <a
            className="link--underline-only text-base text-dusk-500"
            href="mailto:landowners@ncx.com"
            data-test="having-trouble"
          >
            <span className="link">landowners@ncx.com</span>
          </a>
        </div>
      </div>
    </ContentCard>
  )
}
export default SsoLogin
