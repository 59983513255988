import { useContext, useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"

import ProSignUpLayout from "./_layouts/ProSignUpLayout"
import GettingStartedForm from "../sections/ProSignUp/GettingStartedForm"
import EmailLoginForm from "../sections/ProSignUp/EmailLoginForm"
import SignUpForm from "../sections/ProSignUp/SignUpForm"
import VerifyEmail from "../sections/ProSignUp/VerifyEmail"
import { useProfile, useQueryParam } from "../hooks"
import { resetUserForIntegrations } from "../api/integrations"
import { OptimizelyContext } from "@optimizely/react-sdk"

export const AUTH_STEPS = {
  getStarted: "getStarted",
  emailLogin: "emailLogin",
  signUp: "signUp",
}

const AUTH_STEP_FORMS = {
  getStarted: GettingStartedForm,
  emailLogin: EmailLoginForm,
  signUp: SignUpForm,
}

const ProSignUp = ({ authData, setAuthData }) => {
  const queryClient = useQueryClient()
  const { optimizely } = useContext(OptimizelyContext)

  const navigate = useNavigate()
  const location = useLocation()
  const queryEmail = useQueryParam("email")
  const authStep = location.state?.authStep || AUTH_STEPS.getStarted

  const [inputEmail, setInputEmail] = useState(queryEmail)
  const [isUserCreated, setIsUserCreated] = useState(
    location.state?.doesUserExist
  )

  const { data: profile } = useProfile(queryClient, {
    enabled: !!isUserCreated,
    refetchInterval: 1000,
  })

  // Delete HubSpot cookies so that this page view is not associated with a particular contact.
  // We could do this on explicit logout, but in the case where a user doesn't allow cookies on their
  // browser they will be redirected to the Login page on every refresh without logging out explicitly.
  useEffect(() => {
    resetUserForIntegrations(optimizely)
    if (profile?.is_email_verified) {
      navigate(location?.state?.from?.pathname || "/onboarding")
    }
  }, [profile, navigate, location, optimizely])

  const FormComponent = authStep
    ? AUTH_STEP_FORMS[authStep]
    : AUTH_STEP_FORMS.getStarted

  return (
    <ProSignUpLayout
      form={
        isUserCreated ? (
          <VerifyEmail />
        ) : (
          <FormComponent
            authData={authData}
            setAuthData={setAuthData}
            setIsUserCreated={setIsUserCreated}
            inputEmail={inputEmail}
            setInputEmail={setInputEmail}
          />
        )
      }
    />
  )
}

export default ProSignUp
