import { AxiosError } from "axios"
import { createContext } from "react"

export interface VerifyEmailResponseTypes {
  data: unknown
  error: AxiosError<unknown, any> | null
  status: "error" | "success" | "idle" | "pending"
  isIdle: boolean
  reset: () => void
}

export const VerifyUserEmailResponseContext =
  createContext<VerifyEmailResponseTypes | null>(null)
