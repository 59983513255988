import { QueryClient, UseQueryResult } from "@tanstack/react-query"
import { FormikValues } from "formik"

import { useAccountQueryWithRefresh } from "../utils"
import { requestAccountPaymentMethod } from "../../../api/data"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"

export function useAccountPaymentMethod<TData, TError>(
  queryClient: QueryClient,
  accountId: string,
  values?: FormikValues,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> {
  return useAccountQueryWithRefresh(
    queryClient,
    ["accounts", accountId, "payment-method", values],
    () => requestAccountPaymentMethod(accountId, values),
    config
  )
}
