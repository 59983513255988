import { useState, useEffect } from "react"
import { useParams, useLocation, useNavigate } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { Accordion, AccordionItem } from "react-accessible-accordion"
import { useDialogState } from "reakit/Dialog"

import { Spinner } from "../../../components/Spinner"
import NumericPagination from "../../../components/NumericPagination"
import Question from "./Question"
import Answer from "./Answer"
import AskAQuestionModal from "./AskAQuestionModal"
import CQAHeader from "./CQAHeader"
import NoQuestions from "./NoQuestions"
import { useGetQuestions, useIsMultiAccount } from "../../../hooks"
import { formatLongTimestamp } from "../../../shared/utils"

const pageSize = 4

const CQA = ({ profileId }) => {
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState("")
  const askAQuestionDialog = useDialogState({ animated: true })
  const isMultiAccount = useIsMultiAccount()
  const { accountId, projectId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { data: questions, status: questionsStatus } = useGetQuestions(
    queryClient,
    projectId,
    {}
  )

  const filteredQuestions = questions?.filter((question) => {
    const questionTextMatch = question.text
      .toLocaleLowerCase()
      .includes(searchTerm.toLocaleLowerCase())

    const answerTextMatch = question.answers.some((answer) =>
      answer.text.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    )

    return questionTextMatch || answerTextMatch
  })

  const pageQuestions = filteredQuestions?.slice(
    (page - 1) * pageSize,
    (page - 1) * pageSize + pageSize
  )

  const questionNumber = (index) =>
    filteredQuestions?.findIndex(
      (question) => question?.id === pageQuestions[index]?.id
    ) + 1

  // DEV: Get page number from query params
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)

    if (location.search !== "") {
      setPage(parseInt(queryParams.get("page")))
    }
  }, [location.search])

  // DEV: Go to 1st page and remove query params from url on search
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)

    if (searchTerm !== "") {
      setPage(1)
      queryParams.delete("page")
      navigate(
        {
          pathname: location.pathname,
          search: queryParams.toString(),
        },
        { replace: true }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  if (questionsStatus === "pending") {
    return (
      <div className="flex-auto flex items-center justify-center">
        <Spinner />
      </div>
    )
  }

  if (questionsStatus === "error") {
    return (
      <NoQuestions
        title="An error occurred while fetching questions."
        text="Please refresh the page to try again!"
      />
    )
  }

  return (
    <div>
      <CQAHeader
        setSearchTerm={setSearchTerm}
        projectsNumber={filteredQuestions?.length}
        firstOnPageNo={questionNumber(0)}
        lastOnPageNo={questionNumber(pageQuestions?.length - 1)}
        askAQuestionDialog={askAQuestionDialog}
      />

      {questions?.length > 0 && filteredQuestions?.length === 0 ? (
        <NoQuestions
          title="No questions found"
          text="Try entering a different search term!"
        />
      ) : null}

      {questions?.length === 0 ? (
        <NoQuestions
          title="No questions here yet"
          text="Be the first to ask a question about this program!"
        />
      ) : (
        <Accordion
          allowMultipleExpanded={true}
          allowZeroExpanded={true}
          className="mt-6"
        >
          {pageQuestions.map((question) => (
            <AccordionItem
              className="border border-dusk-50 rounded p-4 mt-4"
              key={question.id}
            >
              <Question
                question={question.text}
                authorName={question.author_name}
                createdAt={formatLongTimestamp(question.created_at)}
                disabled={question.answers.length === 0}
              />

              {question.answers.map((answer) => (
                <Answer
                  key={answer.id}
                  authorName={answer.author_name}
                  createdAt={formatLongTimestamp(answer.created_at)}
                  helpfulCount={answer.helpful_count}
                  userMarkedHelpful={answer.user_marked_helpful}
                  projectId={projectId}
                  questionId={question.id}
                  answer={answer.text}
                  answerId={answer.id}
                  isAnswerAuthor={answer.profile_id === profileId}
                />
              ))}
            </AccordionItem>
          ))}
        </Accordion>
      )}

      <NumericPagination
        currentPage={page}
        totalCount={filteredQuestions.length}
        pageSize={pageSize}
        urlRoot={
          isMultiAccount
            ? `/accounts/${accountId}/programs/${projectId}`
            : `/programs/${projectId}`
        }
        className="mt-8"
        queryParams
      />

      <AskAQuestionModal dialog={askAQuestionDialog} projectId={projectId} />
    </div>
  )
}

export default CQA
