import React from "react"
import { Link } from "react-router-dom"
import cx from "classnames"

import { eligibilityQuizBackground } from "../EligibilityQuiz/helpers"
import { useAccountUrlPrefix } from "../../hooks"
import { EligibilityQuizCategoryType } from "../../types/constants"

interface QuizBannerTypes {
  category: EligibilityQuizCategoryType | undefined
  completed: boolean
}

const QuizBanner = ({ category, completed }: QuizBannerTypes) => {
  const accountUrlPrefix = useAccountUrlPrefix()
  const bgImage = eligibilityQuizBackground(category)

  return (
    <div className="container max-w-6xl my-6 md:mt-0">
      <Link
        to={`${accountUrlPrefix}/eligibility-quiz/${category}`}
        className={cx(
          "quiz-banner",
          category,
          "block w-full bg-center rounded shadow-banner p-4 md:px-12 md:py-6"
        )}
        style={{
          background: `linear-gradient(0deg, rgba(36, 36, 38, 0.60) 0%, rgba(36, 36, 38, 0.60) 100%), url(${bgImage}) lightgray 50% / cover no-repeat`,
          backgroundPosition:
            category === "regen_ag"
              ? "50% 40%"
              : category === "general"
                ? "50% 8%"
                : "50%",
        }}
        state={{ completed }}
      >
        <h3 className="text-white text-lg md:text-xl leading-130 md:leading-120 tracking-0.378 md:tracking-0.4">
          Discover Your Eligibility
        </h3>

        <p className="text-white text-base leading-130 tracking 0.32 my-2">
          Answer questions about your land and check your program eligibility in
          a few minutes.
        </p>

        <p className="text-west-side text-base font-bold leading-130 tracking-0.32">
          Get Started
        </p>
      </Link>
    </div>
  )
}

export default QuizBanner
