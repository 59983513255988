import React, { MutableRefObject, useContext, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import cx from "classnames"
import { Form, Formik } from "formik"

import { Modal } from "../components/Modal"
import { SubmitButton } from "./SubmitButton"
import { useResendEmailVerification } from "../hooks"
import { genericErrMsg } from "../api/auth"
import { ErrorCard } from "./Card"
import { WistiaVideo } from "./WistiaVideo"
import {
  variants,
  Profile,
  VariantContent,
} from "../utils/verifyUserEmailModalVariants"
import { VerifyUserEmailModalContext } from "../context/VerifyUserEmailModalContext"

interface VerifyUserEmailModalProps {
  profile: Profile
  initialVerifyStatus?: string | boolean | undefined
  onDismiss?: () => void
  unstable_finalFocusRef: MutableRefObject<HTMLAnchorElement | null>
}

const getVerificationEmailSentContent = ({
  profile,
}: {
  profile: Profile
}): VariantContent => ({
  header: "Verification email sent",
  body: (
    <>
      <p>
        A verification email from landowners@ncx.com was sent to{" "}
        <span className="font-bold">{profile.email}</span>.
      </p>
      <p className="mt-2">
        Watch the video tutorial below to learn how to verify your email.
      </p>
    </>
  ),
  videoId: "cilyv99ojl",
  dismissable: false,
  isCloseButtonPrimary: true,
})

const getKeyInvalidContent = ({
  profile,
}: {
  profile: Profile
}): VariantContent => ({
  header: "Your verification email link has expired",
  body: (
    <>
      The verification email you clicked has expired or is no longer valid.
      Please click the button below to receive a new verification email at{" "}
      <span className="font-bold">{profile.email}</span>.
    </>
  ),
  isCloseButtonPrimary: false,
})

export const VerifyUserEmailModal = ({
  profile,
  initialVerifyStatus,
  onDismiss,
  unstable_finalFocusRef,
}: VerifyUserEmailModalProps) => {
  const queryClient = useQueryClient()
  const contextValue = useContext(VerifyUserEmailModalContext)
  const variant = contextValue?.variant || "generic"

  const { dismissable } = variants[variant]({ profile })

  const [verifyStatus, setVerifyStatus] = useState<
    string | boolean | undefined
  >(initialVerifyStatus)

  const { mutateAsync: resendEmailVerification } = useResendEmailVerification(
    queryClient,
    {
      onSuccess: () => setVerifyStatus("email-sent"),
      onError: () => setVerifyStatus("error"),
    }
  )

  const handleDismiss = () => {
    setVerifyStatus("true")
    if (onDismiss) {
      onDismiss()
    }
  }

  const handleSendVerificationEmail = async () => {
    await resendEmailVerification()
  }

  let getContentFn = variants[variant]
  if (verifyStatus === "email-sent") {
    getContentFn = getVerificationEmailSentContent
  } else if (verifyStatus === "key-invalid") {
    getContentFn = getKeyInvalidContent
  }
  const { header, body, isCloseButtonPrimary, videoId } = getContentFn({
    profile,
  })

  return (
    <Modal
      aria-label="Verify email dialog"
      header={header}
      dismissable={dismissable}
      tabIndex={0}
      dialog={contextValue?.dialog}
      className=""
      unstable_finalFocusRef={unstable_finalFocusRef}
      onDismiss={handleDismiss}
    >
      {verifyStatus === "error" && (
        <ErrorCard className="mb-4">{genericErrMsg}</ErrorCard>
      )}
      <div className="mb-6">{body}</div>
      {videoId && (
        <div className="mb-6">
          <WistiaVideo
            id={videoId}
            className=""
            title="Email Verification Tutorial"
          />
        </div>
      )}
      <div className="mb-4">
        {dismissable && (
          <button
            type="button"
            className={cx("btn2", "font-semibold", {
              "btn2-primary": isCloseButtonPrimary,
              "btn2-outline-primary": !isCloseButtonPrimary,
            })}
            onClick={contextValue?.dialog.hide}
          >
            Close
          </button>
        )}
        <Formik initialValues={{}} onSubmit={handleSendVerificationEmail}>
          {(formikProps) => (
            <Form className="inline">
              <SubmitButton
                className={cx("btn2", "font-semibold", {
                  "ml-2": dismissable,
                  "btn2-primary": !isCloseButtonPrimary,
                  "btn2-outline-primary": isCloseButtonPrimary,
                })}
                isSubmitting={formikProps.isSubmitting}
              >
                Resend Verification Email
              </SubmitButton>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}
