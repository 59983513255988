import { ButtonPair } from "../../components/ButtonPair"
import { LANDOWNER_STATUS } from "../../shared/constants"

const InvitationBanner = ({ invite, setAcceptInvite, landownerStatus }) => {
  if (
    invite === undefined ||
    landownerStatus === LANDOWNER_STATUS.under_contract ||
    landownerStatus === LANDOWNER_STATUS.request_information ||
    landownerStatus === LANDOWNER_STATUS.not_interested ||
    landownerStatus === LANDOWNER_STATUS.lo_not_interested ||
    landownerStatus === LANDOWNER_STATUS.datapack_shareable
  ) {
    return null
  }

  return (
    <div className="w-full flex justify-center px-4 py-6 -mt-6 lg:-mt-12">
      <div className="w-full max-w-[1296px] flex flex-col lg:flex-row lg:items-center lg:gap-[65px] bg-sand rounded p-4 lg:px-12 lg:py-6">
        <p className="grow shrink-0 text-charcoal-500 text-xl font-bold leading-120 tracking-0.4">
          {invite.extra_fields.sender} sent you an exclusive invite
        </p>

        <p className="shrink min-w-0 text-charcoal-500 text-xs leading-130 mt-2 lg:mt-0 break-words">
          {invite.extra_fields.message}
        </p>

        <div className="mt-6 lg:mt-0">
          <ButtonPair
            className="gap-4 lg:gap-6"
            primary={
              <button type="button" onClick={() => setAcceptInvite(true)}>
                Accept
              </button>
            }
            secondary={
              <button
                type="button"
                onClick={() => setAcceptInvite(false)}
                className="bg-white"
              >
                Decline
              </button>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default InvitationBanner
