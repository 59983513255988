import { useState, useEffect } from "react"
import {
  faList,
  faHourglassEnd,
  faQuestion,
  faFile,
  faPen,
  faUsers,
  faTrees,
} from "@fortawesome/pro-solid-svg-icons"
import { DialogDisclosure, useDialogState } from "reakit/Dialog"
import DOMPurify from "dompurify"
import cx from "classnames"
import { usePopoverState } from "reakit/Popover"

import AcceptModal from "../Inbox/AcceptModal"
import InvitationEligibilityModal from "../Inbox/InvitationEligibilityModal"
import Popover from "../../components/Popover"
import { ProjectBadge } from "../../components/ProjectBadge"
import IconText from "../../components/IconText"
import IconLink from "../../components/IconLink"
import IconButton from "../../components/IconButton"
import { ButtonPair } from "../../components/ButtonPair"
import { ActionPermissionWrapper } from "../../components/ActionPermissionWrapper"
import NotInterestedModal from "./NotInterestedModal"
import RequestInformationBtn from "./RequestInformationBtn"
import RequestInformationModal from "./RequestInformationModal"
import IneligibleModal from "./IneligibleModal"
import EligibilityInterestModal from "./EligibilityInterestModal"
import OutOfCoverageModal from "./OutOfCoverageModal"

import { LANDOWNER_STATUS } from "../../shared/constants"
import {
  allAttestationsHaveValue,
  getEnrollmentDeadline,
  getProjectType,
  getProjectBadgeType,
  getProjectBadgeText,
  secondsToMinutes,
} from "../../shared/utils"
import { shortenAcreage } from "../../utils"

const ProjectDetailsHero = ({
  data,
  attestationsData = {},
  accountId,
  projectId,
  accountRole,
  profile,
  similarPrograms,
  isMultiAccount,
  stackablePrograms,
  eligibilityDialog,
  acceptInvite,
  setAcceptInvite,
  invite,
}) => {
  const {
    landowner_status,
    type,
    enrollment_deadline_type,
    enrollment_deadline_date = null,
    image_url,
    description_long,
    name,
    sample_contract_url = null,
    faq_url = null,
    badge_display,
    is_eligible,
    cta_override,
    cta_override_url,
    requires_service_provider,
    has_service_provider_coverage,
    eligible_acres,
  } = data

  const [animateBadge, setAnimateBadge] = useState(false)

  const ineligibilityPopover = usePopoverState({ animated: 250 })
  const requestInfoDialog = useDialogState({ animated: true })
  const notInterestedDialog = useDialogState({ animated: true })
  const ineligibleDialog = useDialogState({ animated: true })
  const acceptDialog = useDialogState({ animated: true })
  const acceptAttestationDialog = useDialogState({ animated: true })
  const outOfCoverageDialog = useDialogState({ animated: true })
  const badgeType = getProjectBadgeType(badge_display)
  const badgeText = getProjectBadgeText(badge_display)
  const projectType = getProjectType(type)
  const enrollmentDeadline = getEnrollmentDeadline(
    enrollment_deadline_type,
    enrollment_deadline_date
  )

  const unansweredAttestationsNum = Object.values(attestationsData).filter(
    (attestation) => !Object.prototype.hasOwnProperty.call(attestation, "value")
  ).length

  const showTimeToComplete =
    is_eligible !== false && unansweredAttestationsNum > 0

  const ineligibilityReasonMap = {
    "not in project geography": "Not in program geography",
    "not enough acres meet minimum requirements":
      "Not enough acres meet program requirements",
    "no part of the property meets the project requirements":
      "No part of the property meets program requirements",
  }

  function mapIneligibilityReason(ineligibilityReason) {
    /* DEV: Convert undefined reason to sentence case */
    return (
      ineligibilityReasonMap[ineligibilityReason] ||
      ineligibilityReason.charAt(0).toUpperCase() + ineligibilityReason.slice(1)
    )
  }

  useEffect(() => {
    if (acceptInvite) {
      // TODO Change this to show AcceptModal
      acceptDialog.show()
    }

    if (acceptInvite === false) {
      notInterestedDialog.show()
    }

    return () => setAcceptInvite(null)
  }, [acceptInvite, setAcceptInvite, acceptDialog, notInterestedDialog])

  return (
    <div className="w-full py-12 bg-grass-50">
      <div className="container px-5 lg:flex xl:max-w-1120">
        <img
          src={image_url}
          alt="project details"
          className="details-hero-img"
          width="250"
        />
        <div className="lg:w-190 lg:shrink-0 lg:order-3 mb-3">
          <div className="flex gap-2 items-center mb-4">
            <ProjectBadge type={badgeType} animated={animateBadge}>
              <span className="whitespace-nowrap">{badgeText}</span>
            </ProjectBadge>

            {badge_display === LANDOWNER_STATUS.ineligible &&
            Array.isArray(data.ineligibility_reasons) ? (
              <Popover
                popover={ineligibilityPopover}
                label="Why am I ineligible?"
              >
                <ul className="ul-custom max-w-54 pl-4 text-sm leading-150 tracking-0.14">
                  {data.ineligibility_reasons?.map((reason) => (
                    <li key={reason}>{mapIneligibilityReason(reason)}</li>
                  ))}
                </ul>
              </Popover>
            ) : null}
          </div>
          {Object.values(attestationsData).length > 0 && (
            <IconButton
              onClick={() => {
                eligibilityDialog.show()
              }}
              icon={faPen}
              text="Edit Land Details"
              className="mb-3"
            />
          )}
          <div className="flex lg:flex-col">
            <div className="mr-7 lg:mr-0">
              {typeof eligible_acres === "number" ? (
                <IconText
                  icon={faTrees}
                  className="text-sm text-dusk-700 mb-3 leading-6"
                  iconClass="text-base mr-1"
                >
                  {shortenAcreage(eligible_acres)} eligible acres
                </IconText>
              ) : null}
              <IconText
                icon={faList}
                className="text-sm text-dusk-700 mb-3 leading-6"
                iconClass="text-base mr-1"
              >
                {projectType}
              </IconText>
              <IconText
                icon={faHourglassEnd}
                className="text-sm text-dusk-700 mb-3 leading-6"
                iconClass="text-base mr-1"
              >
                {enrollmentDeadline}
              </IconText>
            </div>
            <div>
              {data?.num_accounts_interested > 0 ? (
                <IconText
                  icon={faUsers}
                  className="text-sm text-dusk-700 mb-3 leading-6"
                  iconClass="text-base mr-1"
                >
                  {data?.num_accounts_interested} interested
                </IconText>
              ) : null}

              {!faq_url || faq_url?.length === 0 ? (
                <IconText
                  icon={faQuestion}
                  className="mb-3 leading-6 text-dusk-300"
                  iconClass="text-dusk-300 mr-1"
                >
                  FAQ not provided
                </IconText>
              ) : (
                <IconLink
                  to={faq_url}
                  icon={faQuestion}
                  text="FAQ"
                  className="mb-3 leading-6"
                  external
                />
              )}
              {!sample_contract_url || sample_contract_url?.length === 0 ? (
                <IconText
                  icon={faFile}
                  className="mb-3 leading-6 text-dusk-300 sm:whitespace-nowrap"
                  iconClass="text-dusk-300 mr-1"
                >
                  Sample Contract not provided
                </IconText>
              ) : (
                <IconLink
                  to={sample_contract_url}
                  icon={faFile}
                  text="Sample Contract"
                  className="mb-3 leading-6"
                  external
                />
              )}
            </div>
          </div>
        </div>
        <div className="lg:grow lg:pl-12 lg:pr-2 text-lg lg:order-2">
          <h1 className="text-3xl lg:text-5xl mb-4">{name}</h1>
          <div
            className="project-description-long"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description_long, {
                ADD_ATTR: ["target"],
              }),
            }}
          />
          {landowner_status !== LANDOWNER_STATUS.under_contract && (
            <>
              <ButtonPair
                className="mx-auto sm:mx-0 mt-6"
                secondaryHidden={
                  landowner_status === LANDOWNER_STATUS.not_interested ||
                  landowner_status === LANDOWNER_STATUS.lo_not_interested
                }
                primary={
                  is_eligible === false ? (
                    <DialogDisclosure
                      {...ineligibleDialog}
                      className="text-base interested-btn-project-details"
                      disabled={
                        landowner_status ===
                        LANDOWNER_STATUS.request_information
                      }
                    >
                      I'm Interested
                    </DialogDisclosure>
                  ) : is_eligible ||
                    allAttestationsHaveValue(attestationsData) ||
                    Object.keys(attestationsData).length === 0 ? (
                    <RequestInformationBtn
                      sourceIdentifier="project-details"
                      requestInfoDialog={requestInfoDialog}
                      landowner_status={landowner_status}
                      projectId={projectId}
                      accountId={accountId}
                      accountRole={accountRole}
                      enrollmentDeadlineType={enrollment_deadline_type}
                      ctaOverride={cta_override}
                      ctaOverrideUrl={cta_override_url}
                      requires_service_provider={requires_service_provider}
                      has_service_provider_coverage={
                        has_service_provider_coverage
                      }
                      // DEV: the RequestInformationBtn will handle updating AP status,
                      // this makes sure we open the custom CTA after
                      onRequestCallSubmit={
                        cta_override_url?.length > 0
                          ? () => {
                              window.open(
                                cta_override_url,
                                "_blank",
                                "noopener"
                              )
                            }
                          : null
                      }
                    />
                  ) : (
                    <DialogDisclosure
                      {...eligibilityDialog}
                      className="text-base"
                    >
                      Check Eligibility
                    </DialogDisclosure>
                  )
                }
                // eslint-disable-next-line react/no-unstable-nested-components
                secondary={(secondaryProps) => (
                  <ActionPermissionWrapper
                    accountRole={accountRole}
                    action="editAccount"
                  >
                    <DialogDisclosure
                      {...notInterestedDialog}
                      className={cx(
                        secondaryProps.className,
                        "bg-cloud-50 text-base !font-bold py-7px not-interested-btn-project-details"
                      )}
                    >
                      Not Interested
                    </DialogDisclosure>
                  </ActionPermissionWrapper>
                )}
              />
              {showTimeToComplete && (
                <p className="text-xs text-charcoal-400 leading-130 mt-4">
                  Check your eligibility in about{" "}
                  {secondsToMinutes(unansweredAttestationsNum * 30)}!
                </p>
              )}
              {is_eligible === false && (
                <p className="text-xs text-charcoal-400 leading-130 mt-4">
                  You're not eligible for this program. Express your interest to
                  help us find new opportunities like this for you.
                </p>
              )}
            </>
          )}
        </div>
      </div>

      <IneligibleModal
        dialog={ineligibleDialog}
        accountId={accountId}
        projectId={projectId}
      />
      <OutOfCoverageModal
        dialog={outOfCoverageDialog}
        accountId={accountId}
        projectId={projectId}
      />
      <EligibilityInterestModal
        dialog={eligibilityDialog}
        attestationsData={attestationsData}
        accountId={accountId}
        projectId={projectId}
        landowner_status={landowner_status}
        is_eligible={is_eligible}
        setAnimateBadge={setAnimateBadge}
        accountRole={accountRole}
        enrollmentDeadlineType={enrollment_deadline_type}
        img={image_url}
        profile={profile}
        ctaOverride={cta_override}
        ctaOverrideUrl={cta_override_url}
        similarPrograms={similarPrograms}
        stackablePrograms={stackablePrograms}
        isMultiAccount={isMultiAccount}
        requires_service_provider={requires_service_provider}
        has_service_provider_coverage={has_service_provider_coverage}
      />
      <NotInterestedModal
        dialog={notInterestedDialog}
        accountId={accountId}
        projectId={projectId}
      />
      <RequestInformationModal
        dialog={requestInfoDialog}
        accountId={accountId}
        projectId={projectId}
        accountRole={accountRole}
        enrollmentDeadlineType={enrollment_deadline_type}
        img={image_url}
        profile={profile}
        requires_service_provider={requires_service_provider}
        has_service_provider_coverage={has_service_provider_coverage}
        ctaOverride={cta_override}
        ctaOverrideUrl={cta_override_url}
        landowner_status={landowner_status}
      />
      <AcceptModal
        dialog={acceptDialog}
        attestationsDialog={acceptAttestationDialog}
        accountId={accountId}
        projectId={projectId}
        sender={name}
        badgeDisplay={badge_display}
        profile={profile}
        notificationId={invite?.id}
      />
      <InvitationEligibilityModal
        dialog={acceptAttestationDialog}
        attestationsData={attestationsData}
        accountId={accountId}
        projectId={projectId}
        accountRole={accountRole}
        sender={invite?.extra_fields.sender}
      />
    </div>
  )
}

export default ProjectDetailsHero
