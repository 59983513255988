import React from "react"
import cx from "classnames"

interface OnboardingSurveyHeaderProps {
  text: string
  subheader?: string
  isMobile: boolean
  headerImg: React.ReactNode
}

const OnboardingSurveyHeader = ({
  text,
  subheader,
  isMobile,
  headerImg,
}: OnboardingSurveyHeaderProps) => (
  <div className={cx(isMobile ? "" : "mt-14")}>
    <h2 className="text-charcoal-500 text-xl lg:text-2xl leading-120 tracking-0.4 md:tracking-0.48 mt-2">
      {text}
    </h2>
    {subheader && (
      <div className="text-dusk text-base leading-130 tracking-0.32 mt-3">
        {subheader}
      </div>
    )}

    {isMobile ? (
      <div className="w-full flex justify-center mt-12">{headerImg}</div>
    ) : null}
  </div>
)

export default OnboardingSurveyHeader
