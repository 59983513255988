import { useState } from "react"
import MapGL, { Source, Layer } from "@urbica/react-map-gl"

import ToggleSwitch from "../../../components/ToggleSwitch"
import { MAPBOX_TOKEN, mapStyles } from "../../../shared/constants"
import { useAccessToken } from "../../../stores"

export const EligibilityLayer = ({ showEligibilityLayer, tileData }) => {
  if (!showEligibilityLayer) {
    return null
  }

  if (tileData.raster.min.length === 0) {
    return (
      <>
        <Source id="default" type="vector" url={tileData.vector} />
        <Layer
          id="default"
          source="default"
          source-layer="default"
          type="fill"
          paint={{
            "fill-color": "#3BB2D0",
            "fill-opacity": 0.8,
          }}
        />
      </>
    )
  }

  return (
    <>
      <Source id="default" type="raster" tiles={tileData.raster.min} />
      <Layer id="default" source="default" type="raster" />
    </>
  )
}

const ProjectMapVisualization = ({
  viewport,
  setViewport,
  parcelData,
  tileData,
  activeFeature,
  isIneligible,
  enableEligibility = true,
}) => {
  const [showEligibilityLayer, setShowEligibilityLayer] =
    useState(enableEligibility)
  const accessToken = useAccessToken()

  if (viewport === null || viewport === undefined) {
    return null
  }

  return (
    <div tabIndex={isIneligible ? -1 : 0} className="w-full h-[400px] relative">
      <MapGL
        style={{ width: "100%", height: "400px" }}
        mapStyle={mapStyles.aerial.url}
        accessToken={MAPBOX_TOKEN}
        onViewportChange={(newViewport) => setViewport(newViewport)}
        viewportChangeMethod="flyTo"
        viewportChangeOptions={{
          duration: 1000,
        }}
        cursorStyle="default"
        scrollZoom={!enableEligibility}
        doubleClickZoom={!enableEligibility}
        dragPan={!enableEligibility}
        attributionControl={false}
        transformRequest={(url) => {
          if (
            url.includes("tipg") | url.includes("amazonaws.com/collections")
          ) {
            return {
              url: url,
              headers: { Authorization: `Bearer ${accessToken}` },
              credentials: "include",
            }
          }
        }}
        {...viewport}
      >
        <Source
          id="features"
          type="geojson"
          data={
            activeFeature !== null
              ? {
                  type: "FeatureCollection",
                  features: parcelData?.features,
                }
              : null
          }
        />
        <Layer
          id="featuresLayer"
          source="features"
          type="line"
          paint={{
            "line-color": "#77A0C3",
            "line-width": 2,
          }}
        />

        <EligibilityLayer
          showEligibilityLayer={showEligibilityLayer}
          tileData={tileData}
        />
      </MapGL>
      {enableEligibility && (
        <div className="absolute top-4 left-4 block bg-white border border-dusk-50 rounded px-2 py-[7px]">
          <ToggleSwitch
            label={
              <>
                Layer: <span className="font-normal">Eligibility</span>
              </>
            }
            value={showEligibilityLayer}
            onChange={() => setShowEligibilityLayer((oldValue) => !oldValue)}
          />
        </div>
      )}
    </div>
  )
}

export default ProjectMapVisualization
