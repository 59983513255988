import React from "react"
import useScript from "@charlietango/use-script"
import AspectRatio from "react-aspect-ratio"

interface WistiaVideoTypes {
  id: string
  title: string
  className?: string
  ratio?: string
  minWidth?: number | undefined
  maxHeight?: number
}

export const WistiaVideo = ({
  id,
  title,
  className,
  ratio = "16/9",
  minWidth,
  maxHeight = 600,
}: WistiaVideoTypes) => {
  // DEV: By using `useScript`, we avoid re-loading existing scripts on re-render
  //   as well as sharing them across multiple <WistiaVideo> instances
  //   https://www.npmjs.com/package/@charlietango/use-script
  useScript("//fast.wistia.com/assets/external/E-v1.js")
  return (
    <AspectRatio
      className={className}
      ratio={ratio}
      style={{ minWidth: minWidth, maxHeight: maxHeight }}
    >
      {/* Using inline Fallback Embed because Popover Embed caused lots of javascript errors on close 
          https://wistia.com/support/embed-and-share/embed-troubleshooting#fallback-embed 

          Note this is not an exact copypaste of the Wistia code at the above link; the code below is JSX not HTML
          and has required some adjustments e.g. 'frameborder' -> 'frameBorder', 'msallowfullscreen' -> 'msallowfullscreen="true"'
          */}
      <div className="wistia_responsive_padding">
        <div className="wistia_responsive_wrapper">
          <iframe
            src={`https://fast.wistia.net/embed/iframe/${id}?videoFoam=true`}
            title={title}
            allowFullScreen
            frameBorder="0"
            scrolling="no"
            className="wistia_embed"
            name="wistia_embed"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </div>
    </AspectRatio>
  )
}
