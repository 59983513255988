import { getViewportFromFeature } from "../ProjectDetails/ProjectMap/helpers"

export const handlePrevFeature = (
  setActiveFeature,
  setViewport,
  parcelData
) => {
  setActiveFeature((oldFeature) => {
    const previousValidIndex =
      oldFeature - 1 !== -1 ? oldFeature - 1 : oldFeature

    if (previousValidIndex !== oldFeature) {
      setViewport(
        getViewportFromFeature(parcelData?.features[previousValidIndex])
      )
    }

    return previousValidIndex
  })
}

export const handleNextFeature = (
  setActiveFeature,
  setViewport,
  parcelData
) => {
  setActiveFeature((oldFeature) => {
    const nextValidIndex =
      oldFeature + 1 < parcelData?.features.length ? oldFeature + 1 : oldFeature

    if (nextValidIndex !== oldFeature) {
      setViewport(getViewportFromFeature(parcelData?.features[nextValidIndex]))
    }

    return nextValidIndex
  })
}

export const explanatoryText = (onClick) => (
  <div className="space-y-p my-4">
    <p>
      NCX assessed the parcels you added to determine land cover using remote
      satellite imagery last updated in 2021. If you've completed any harvesting
      since then, you won't see it reflected in this report.
    </p>
    <p>
      To assess land cover, we use the National Land Cover Dataset from the
      United States Geological Survey. This data set is trusted by scientists,
      land managers and environmentalists to accurately determine the most
      likely type of land from satellite imagery.
    </p>
    <p>
      Learn more about{" "}
      <button
        aria-label="Land Cover Classes"
        className="link"
        type="button"
        onClick={onClick}
      >
        land cover classes.
      </button>
    </p>
  </div>
)
