import { faList } from "@fortawesome/pro-solid-svg-icons"

import IconLink from "../../components/IconLink"
import Sidebar from "../../components/Sidebar"

const Stackables = ({ stackablePrograms, isMultiAccount, accountId }) => {
  if (!stackablePrograms || stackablePrograms?.length === 0) {
    return null
  }

  return (
    <Sidebar
      title="Stackable programs"
      className="mb-6 stackables-section"
      description="Stackables are programs that you can participate in at the same time as this program."
    >
      {stackablePrograms?.map((program) => (
        <IconLink
          key={program.id}
          to={
            isMultiAccount
              ? `/accounts/${accountId}/programs/${program.id}`
              : `/programs/${program.id}`
          }
          icon={faList}
          text={program.name}
          className="truncate mb-3 stackable-program-link"
        />
      ))}
    </Sidebar>
  )
}

export default Stackables
