import { Link, useNavigate, useParams } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import cx from "classnames"

import EligibilityQuizHeader from "./EligibilityQuizHeader"
import EligibilityQuizBtnContainer from "./EligibilityQuizBtnContainer"
import EligibilityQuizCategoryStats from "./EligibilityQuizCategoryStats"
import { Toast } from "../../components/Toast"
import { SubmitButton } from "../../components/SubmitButton"
import {
  useAccountId,
  useAccountUrlPrefix,
  useRefetchAttestationQuizes,
  useRefetchAttestationQuiz,
} from "../../hooks"
import { PROJECT_TYPES } from "../../shared/constants"
import { getRandomUncompletedQuiz } from "./helpers"
import { genericErrMsg } from "../../api/auth"

const EligibilityQuizCompleted = ({
  someNotCompleted,
  numEligible,
  projCategoryStats,
  quizData,
  isMobile,
  setQuizStep,
  setCompleted,
}) => {
  const queryClient = useQueryClient()
  const accountId = useAccountId()
  const navigate = useNavigate()
  const { quizCategory } = useParams()
  const accountUrlPrefix = useAccountUrlPrefix()

  const {
    mutateAsync: refetchAttestationQuizes,
    status: refetchAttestationQuizesStatus,
  } = useRefetchAttestationQuizes(queryClient, accountId, {
    onSuccess: (data) => {
      const randomUncompletedQuiz = getRandomUncompletedQuiz(data)

      if (randomUncompletedQuiz !== null) {
        navigate(
          `${accountUrlPrefix}/eligibility-quiz/${randomUncompletedQuiz}`
        )
      }
    },
    onError: (error) => {
      Toast.error(error?.message || genericErrMsg)
    },
  })

  const { mutateAsync: refetchAttestationQuiz } = useRefetchAttestationQuiz(
    queryClient,
    accountId,
    quizCategory,
    true,
    {
      onSuccess: () => {
        setQuizStep(0)
        setCompleted(false)
      },
    }
  )

  const isEligible = numEligible > 0
  const quizProjectCategory = quizData?.project_type

  return (
    <div className="mt-2">
      <EligibilityQuizHeader
        eyebrow="Eligibility check"
        text={
          isEligible
            ? `Congrats! You're Eligible for ${numEligible} ${
                PROJECT_TYPES[quizProjectCategory].long
              } ${numEligible === 1 ? "Program" : "Programs"}`
            : `You're Not Eligible for Any Current ${PROJECT_TYPES[quizProjectCategory].long} Programs`
        }
        isMobile={isMobile}
        imageUrl={quizData.image_url}
        quizType={quizData.quiz_type}
      />

      {isEligible ? (
        <>
          <p className="text-charcoal-500 leading-130 tracking-0.32 mt-8">
            NCX created a personalized list of eligible programs just for you.
          </p>

          <p className="text-charcoal-500 leading-130 tracking-0.32 mt-3">
            Browse eligible programs to find the one that's just right for you
            and your land.
          </p>
        </>
      ) : (
        <>
          <p className="text-charcoal-500 leading-130 tracking-0.32 mt-8">
            Based on your answers, your land is not currently eligible for any
            programs in this category.
          </p>
          <p className="text-charcoal-500 leading-130 tracking-0.32 mt-3">
            Need to make a change to your answers?{" "}
            <button
              aria-label="Edit Land Details"
              className="link"
              type="button"
              onClick={async () => {
                await refetchAttestationQuiz()
              }}
            >
              Edit Land Details
            </button>
          </p>
          <hr className="border-charcoal-50 my-6" />
          {someNotCompleted && (
            <p className="text-charcoal-500 leading-130 tracking-0.32 mt-3">
              Keep going to the next category to answer more questions and see
              your eligibility.
            </p>
          )}
        </>
      )}

      <EligibilityQuizCategoryStats projCategoryStats={projCategoryStats} />

      <EligibilityQuizBtnContainer>
        {isEligible ? (
          <Link
            to={`${accountUrlPrefix}/programs/page/1`}
            className={cx(
              "quiz-view-eligible-programs",
              quizCategory,
              "btn2 btn2-primary text-center font-semibold md:self-start md:mt-12"
            )}
            state={{
              quizProjectCategory,
            }}
          >
            View Eligible Programs
          </Link>
        ) : (
          <>
            <Link
              to={`${accountUrlPrefix}/programs/page/1`}
              className={cx(
                "quiz-view-all-programs",
                quizCategory,
                "btn2 btn2-outline-primary text-center font-semibold lg:mt-12 py-[7px]"
              )}
            >
              View All Programs
            </Link>

            {someNotCompleted ? (
              <SubmitButton
                className={cx(
                  "quiz-keep-going",
                  quizCategory,
                  "btn2 btn2-primary text-center font-semibold md:mt-12"
                )}
                onClick={async () => {
                  await refetchAttestationQuizes()
                }}
                isSubmitting={refetchAttestationQuizesStatus === "pending"}
                typeButton
              >
                Keep Going
              </SubmitButton>
            ) : null}
          </>
        )}
      </EligibilityQuizBtnContainer>
    </div>
  )
}

export default EligibilityQuizCompleted
