import { useEffect, useMemo, useRef } from "react"
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSort } from "@fortawesome/pro-duotone-svg-icons"
import cx from "classnames"
import scrollIntoView from "scroll-into-view-if-needed"

import { roundTo } from "../../../utils"

const columns = [
  {
    accessorKey: "year",
    header: "Year",
    cell: (props) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "revenue",
    header: "Revenue",
    cell: (props) => <p>{props.getValue().toLocaleString("en-US")}</p>,
  },
  {
    accessorKey: "cost",
    header: "Cost",
    cell: (props) => <p>{props.getValue().toLocaleString("en-US")}</p>,
  },
  {
    accessorKey: "profit",
    header: "Profit",
    cell: (props) => <p>{props.getValue().toLocaleString("en-US")}</p>,
  },
  {
    accessorKey: "total_npv",
    header: "Total NPV",
    cell: (props) => <p>{props.getValue().toLocaleString("en-US")}</p>,
  },
]

const getTableData = (data) => {
  return data.revenue.map((_, i) => {
    const newObj = { year: i + 1 }

    Object.keys(data).forEach((key) => {
      const cents = data[key][i] < 10 ? 1 : 10
      newObj[key] = roundTo(data[key][i], cents)
    })

    return newObj
  })
}

const RoiTable = ({ roiData, isMobile, activeYear, setActiveYear }) => {
  const containerRef = useRef()
  const tableRef = useRef()

  const tableData = useMemo(
    () =>
      getTableData({
        revenue: roiData.yearly_revenue,
        cost: roiData.yearly_cost,
        profit: roiData.yearly_profit,
        total_npv: roiData.total_npv,
      }),
    [
      roiData.yearly_revenue,
      roiData.yearly_cost,
      roiData.yearly_profit,
      roiData.total_npv,
    ]
  )

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: isMobile ? getPaginationRowModel() : null,
  })

  // DEV: Center and highlight the corresponding table row inside table container whenever activeYear changes.
  useEffect(() => {
    const rowToScroll = document.getElementById(`${activeYear}_year`)
    const currentContainerRef = containerRef.current

    if (rowToScroll) {
      scrollIntoView(rowToScroll, {
        behavior: "smooth",
        block: "center",
        boundary: currentContainerRef,
      })
      rowToScroll.classList.add("!bg-grass-200")
    }

    // DEV: Unselect the active row on click outside of table.
    const handleClickOutside = (e) => {
      if (currentContainerRef && !currentContainerRef.contains(e.target)) {
        setActiveYear(null)
      }
    }
    document.addEventListener("click", handleClickOutside)

    return () => {
      if (rowToScroll) {
        document.removeEventListener("click", handleClickOutside)
        rowToScroll.classList.remove("!bg-grass-200")
      }
    }
  }, [activeYear, setActiveYear])

  const numShowing =
    table.getState().pagination.pageSize *
    (table.getState().pagination.pageIndex + 1)

  return (
    <div className="mt-6 lg:mt-7">
      <div
        ref={containerRef}
        className={cx(
          "relative overflow-x-auto border border-charcoal-50 rounded-t",
          { "max-h-204": !isMobile }
        )}
      >
        <table
          ref={tableRef}
          className="w-full text-sm text-left leading-130 tracking-0.14 text-charcoal-500 whitespace-nowrap border-separate border-spacing-0 roi-table"
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <thead key={headerGroup.id} className="font-bold">
              <tr className="sticky top-0 bg-white z-[2]">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="p-4 border-b-2 border-b-charcoal-50"
                  >
                    {header.column.columnDef.header}
                    {header.column.getCanSort() && (
                      <FontAwesomeIcon
                        icon={faSort}
                        className={cx(
                          "cursor-pointer inline-block w-4 h-4 ml-1",
                          {
                            "fa-swap-opacity":
                              header.column.getNextSortingOrder() === "asc",
                          }
                        )}
                        style={{
                          "--fa-primary-opacity":
                            header.column.getIsSorted() === false ? 0.4 : 1,
                        }}
                        onClick={header.column.getToggleSortingHandler()}
                      />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
          ))}
          <tbody className="roi-table">
            {table.getRowModel().rows.map((row, i, arr) => (
              <tr
                key={row.id}
                tabIndex={0}
                id={`${row.original.year}_year`}
                className={cx("roi-table-row hover:bg-grass-200 focus:ring-0", {
                  "bg-grass-50": i % 2 !== 0,
                })}
                onClick={() => setActiveYear(row.original.year)}
                onFocus={() => setActiveYear(row.original.year)}
                onBlur={() => setActiveYear(null)}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={cx("px-4 py-2.5", {
                      "border-b-1 border-b-charcoal-50": i < arr.length - 1,
                    })}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isMobile ? (
        <div className="mt-2">
          <p className="text-sm text-dusk leading-130 tracking-0.14">
            Showing {numShowing - 9}-{numShowing} of{" "}
            {Object.keys(table.getRowModel().rowsById).length} rows
          </p>
          <div className="flex gap-6 mt-1.25">
            <button
              type="button"
              className="text-base text-leaf disabled:text-dusk-300 font-semibold leading-130 tracking-0.32"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </button>
            <button
              type="button"
              className="text-base text-leaf disabled:text-dusk-300 font-semibold leading-130 tracking-0.32"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              Next
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default RoiTable
