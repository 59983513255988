import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTriangleExclamation } from "@fortawesome/pro-duotone-svg-icons"
import { StickyNotification } from "../../components/StickyNotification"
import { LANDOWNER_STATUS } from "../../shared/constants"
import { getProjectType } from "../../shared/utils"

const ProjectNotification = ({ data, projectData, projectId }) => {
  const isUnderContract =
    projectData.landowner_status === LANDOWNER_STATUS.under_contract
  // isConflicting is true if the user is under contract on another project
  // of the same type
  const isConflicting = data.account_project_data.some(
    (accountProject) =>
      accountProject.status === LANDOWNER_STATUS.under_contract &&
      accountProject.id !== projectId &&
      accountProject.type === projectData.type
  )
  const type = getProjectType(projectData.type).toLowerCase()

  if (isUnderContract || isConflicting) {
    return (
      <StickyNotification
        title={isUnderContract ? "Contract signed" : null}
        variant={isUnderContract ? "success" : "warning"}
        className={isConflicting ? "py-4" : null}
        sticky
      >
        {isUnderContract ? (
          <>You are currently enrolled in this program opportunity.</>
        ) : (
          <>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              style={{
                "--fa-primary-opacity": 0,
                "--fa-secondary-opacity": 1,
              }}
            />{" "}
            <span className="font-bold">Conflicting Program.</span> You are
            currently enrolled in another {type} program, which may affect your
            eligibility for this opportunity.
          </>
        )}
      </StickyNotification>
    )
  }

  return null
}

export default ProjectNotification
