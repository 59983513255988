import { useEffect, useState } from "react"
import assert from "assert"
import cx from "classnames"
import { useQueryClient } from "@tanstack/react-query"

import {
  useAccountAbsoluteUrl,
  useAccountId,
  useAccountPaymentMethod,
} from "../hooks"
import { PaymentMethod } from "@/types/payment"

type TipaltiIframeType = "PaymentDetails" | "Invoices" | "PaymentHistory"

interface TipaltiIframeInfo {
  title: string
  keyForUrl: string
}

interface TipaltiIframeTypes {
  width?: number | string | undefined
  height?: number | string | undefined
  scrolling?: "yes" | "no" | "auto"
  className?: string
  tipaltiIframeType: TipaltiIframeType
}

interface DynamicTipaltiIframeTypes {
  tipaltiIframeType: TipaltiIframeType
  className?: string
}

// Custom <iframe> to support Tipalti's dynamic resizing
//   https://support.tipalti.com/Content/Topics/Development/iFrames/SetSizeOfPayeeIframe.htm
// DEV: This operates on cross-frame messages which is secure and allowed :tada:
//   https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
const TIPALTI_IFRAME_TYPE_TO_INFO: {
  [key in TipaltiIframeType]: TipaltiIframeInfo
} = {
  PaymentDetails: {
    title: "Tipalti payment details",
    keyForUrl: "iframe_payment_details_url",
  },
  Invoices: {
    title: "Tipalti invoices",
    keyForUrl: "iframe_invoices_url",
  },
  PaymentHistory: {
    title: "Tipalti payment history",
    keyForUrl: "iframe_payment_history_url",
  },
}

export const TipaltiIframe = ({
  width,
  height,
  scrolling,
  className,
  tipaltiIframeType,
}: TipaltiIframeTypes) => {
  // DEV: Sometimes we take a really long time to create the Tipalti payee on JIT creation/loading
  //   As a result, isolate the iframe with a spinner to avoid making entire site seem slow (perceived UX), https://app.asana.com/0/0/1201533899803144/f
  const tipaltiIframeInfo = TIPALTI_IFRAME_TYPE_TO_INFO[tipaltiIframeType]
  assert(
    tipaltiIframeInfo,
    "For proper dynamic resize support, we require valid `tipaltiIframeType` (e.g. PaymentDetails, PaymentHistory)"
  )

  const queryClient = useQueryClient()
  const accountId = useAccountId()
  const accountAbsoluteUrl = useAccountAbsoluteUrl()
  const [iframeLoaded, setIframeLoaded] = useState(false)

  // DEV: This is an exception to the rule for making HTTP requests inside components
  //   We're carefully handling loading status within this component
  // DEV: Due to React-Query properly handling concurrent requests, we should only be one request
  //   (and thus no double creation issues)
  const { data: paymentMethod, status: paymentMethodStatus } =
    useAccountPaymentMethod<PaymentMethod, Error>(queryClient, accountId, {
      // DEV: Use implicit absolute URL like we use for DocuSign in `useAccountDocumentActionURL` (e.g. /accounts/:id)
      // DEV: Since we receive multiple URLs back, we've delegated setting proper `event` to `bark_api`
      //   The final redirectto in the iframe will be https://landowners.ncx.com/accounts/:id?tipalti_event=payment_details_completed
      redirectto: accountAbsoluteUrl,
    })

  // If we ran into an error, error out
  if (paymentMethodStatus === "error") {
    throw new Error("Encountered `error` while loading payment method")
  }

  const fullyLoaded = paymentMethodStatus === "success" && iframeLoaded
  className = cx(className, "w-full") // Not currently supporting receiving width via `className`

  return (
    <>
      {/* If our data is still loading, or our iframe is loading, then show our custom spinner */}
      {/* DEV: We don't show Tipalti iframe until it's loaded so our spinner stays consistent */}
      {/* Default height: 150px, https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#attr-height */}
      {/* Default width: 300px, https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#attr-width */}
      {!fullyLoaded && (
        <div
          className={cx(className, "flex justify-center items-center")}
          style={{ height: "150px", backgroundColor: "#f7f7f7" }}
        >
          {/* Visually closest to Tipalti's spinner */}
          <i className="fas fa-3x fa-spinner fa-spin text-carbon" />
        </div>
      )}

      {/* Additionally, render iframe once we have data (thus URL) but only show it once we hide our spinner */}
      {paymentMethod && (
        <iframe
          title={tipaltiIframeInfo.title}
          src={
            (paymentMethod as unknown as Record<string, string>)[
              tipaltiIframeInfo.keyForUrl
            ]
          }
          className={cx(className, !fullyLoaded && "hidden")}
          // https://support.tipalti.com/Content/Topics/Development/iFrames/DisplayCustomIframeLoader.htm
          onLoad={() => setIframeLoaded(true)}
          width={width}
          height={height}
          scrolling={scrolling}
        />
      )}
    </>
  )
}

export const DynamicTipaltiIframe = ({
  tipaltiIframeType,
  className,
}: DynamicTipaltiIframeTypes) => {
  const [{ width, height }, setDimensions] = useState<{
    width: string | number | undefined
    height: string | number | undefined
  }>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    const handleMessage = (evt: {
      data: {
        TipaltiIframeInfo: TipaltiIframeTypes
        caller: string
      }
    }) => {
      // Sanity check we know all iframe types
      if (evt.data?.TipaltiIframeInfo) {
        assert(
          Object.prototype.hasOwnProperty.call(
            TIPALTI_IFRAME_TYPE_TO_INFO,
            evt.data.caller
          ),
          `Unrecognized tipaltiIframeType from Tipalti: ${evt.data.caller}`
        )
      }

      // If we have a matching iframe, then update our width/height
      // DEV: Unfortunately we can't determine which iframe from `evt` since we can have multiple Tipalti URLs
      //   https://stackoverflow.com/a/20404180/1960509
      //   As a result, lean on `evt.data.caller` (provided by Tipalti) and singletons
      // evt.data = {TipaltiIframeInfo: {width: 713, height: 200}, sessionTimeout: null, caller: "PaymentDetails"}
      if (
        evt.data?.TipaltiIframeInfo &&
        evt.data?.caller === tipaltiIframeType
      ) {
        setDimensions({
          width: evt.data.TipaltiIframeInfo.width,
          height: evt.data.TipaltiIframeInfo.height,
        })
      }
    }

    // DEV: We're not supporting IE8 so no need for `attachEvent` support, https://caniuse.com/addeventlistener
    window.addEventListener("message", handleMessage, false /* bubbling */)
    // DEV: This is our hack for enforcing only 1 dynamic Tipalti iframe of a given type
    assert.strictEqual(
      window[`${tipaltiIframeType}`],
      undefined,
      "Cannot use multiple <DynamicTipaltiIframe> of same type"
    )
    window[`__tipalti_singleton_${tipaltiIframeType}`] = true
    return () => {
      window.removeEventListener("message", handleMessage, false /* bubbling */)
      delete window[`__tipalti_singleton_${tipaltiIframeType}`]
    }
  }, [tipaltiIframeType])

  return (
    <TipaltiIframe
      width={width}
      height={height}
      // DEV: Tipalti's iframe gets mostly there but still see scrollbar + a little scroll so disable it
      //   https://app.asana.com/0/0/1201533806444339/f
      scrolling="no"
      className={className}
      tipaltiIframeType={tipaltiIframeType}
    />
  )
}
