import React from "react"
import { Link } from "react-router-dom"

import {
  faList,
  faHourglassEnd,
  faClock,
} from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

import { ProjectBadge } from "./ProjectBadge"
import IconText from "./IconText"
import {
  getEnrollmentDeadline,
  getProjectType,
  getProjectBadgeType,
  getProjectBadgeText,
} from "../shared/utils"
import {
  BadgeDisplayType,
  ProjectBadgeType,
  ProgramCategoryType,
} from "../types/constants"

interface ProjectCardTypes {
  className?: string
  badge_display: BadgeDisplayType
  description: string
  enrollmentDeadlineDate: string | undefined | null
  enrollmentDeadlineType: string | undefined | null
  imageUrl: string
  name: string
  type: ProgramCategoryType
  term: string | null | undefined
  to: string
  showMinimalInfo?: boolean
  onClick?: () => void
}

const ProjectCard = ({
  className,
  badge_display,
  description,
  enrollmentDeadlineDate,
  enrollmentDeadlineType,
  imageUrl,
  name,
  type,
  term,
  to,
  showMinimalInfo = false,
  onClick,
}: ProjectCardTypes) => {
  const badgeType = getProjectBadgeType(badge_display)
  const badgeText = getProjectBadgeText(badge_display)
  const projectType = getProjectType(type, "short")
  const enrollmentDeadline = getEnrollmentDeadline(
    enrollmentDeadlineType,
    enrollmentDeadlineDate
  )

  return (
    <Link
      to={to}
      className={cx("project-card", className)}
      aria-label={name}
      onClick={onClick}
    >
      {!showMinimalInfo ? (
        <div className="mb-3">
          <ProjectBadge type={badgeType as ProjectBadgeType} size="sm">
            {badgeText}
          </ProjectBadge>
        </div>
      ) : null}

      <div className="mb-3">
        <img
          src={imageUrl}
          alt=""
          className="w-full h-121 sm:h-222 object-cover rounded"
        />
      </div>

      <div className="line-clamp-2 mb-2">
        <h3 className="font-semibold text-lg leading-130 mb-0">{name}</h3>
      </div>

      {!showMinimalInfo ? (
        <div className="line-clamp-2 mb-3.5">
          <p className="text-base leading-130 mb-0">{description}</p>
        </div>
      ) : null}

      <div className="grid grid-rows-2 grid-cols-2-max-content gap-2 mt-auto mb-0">
        <IconText
          icon={faList}
          className="text-sm text-dusk-700 leading-6"
          iconClass="text-base mr-1"
        >
          {projectType}
        </IconText>

        <IconText
          icon={faHourglassEnd}
          className="text-sm text-dusk-700 leading-6"
          iconClass="text-base mr-1"
        >
          {enrollmentDeadline}
        </IconText>

        <IconText
          icon={faClock}
          className="text-sm text-dusk-700 leading-6"
          iconClass="text-base mr-1"
        >
          {term === null ? "Term length varies" : `${term} year term`}
        </IconText>
      </div>
    </Link>
  )
}

export default ProjectCard
