import React from "react"
import { useDialogState } from "reakit/Dialog"

import LandCoverStats from "../LandCoverDetails/LandCoverStats"
import PropertyMap from "../LandCoverDetails/PropertyMap"
import LandCoverClassesModal from "../LandCoverDetails/LandCoverClassesModal"
import { explanatoryText } from "../LandCoverDetails/helpers"
import { shortenAcreage } from "../../utils"

const LandCoverContent = ({ accountId, profile, property }) => {
  const landCoverDialog = useDialogState({ animated: true })

  return (
    <div className="landcover-content dashboard">
      <div className="mb-4 text-black text-xl font-medium leading-7 tracking-0.4">
        {`${profile.first_name}'s ${shortenAcreage(property.acreage)} Acres`}
      </div>
      <LandCoverStats landCoverDetails={property.landcover_data} />
      <PropertyMap accountId={accountId} />
      {explanatoryText(landCoverDialog.show)}
      <LandCoverClassesModal dialog={landCoverDialog} />
    </div>
  )
}

export default LandCoverContent
