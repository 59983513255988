import { useEffect, useRef, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { Formik } from "formik"
import * as yup from "yup"
import { Dialog, useDialogState } from "reakit/Dialog"

import NotInterestedModal from "./NotInterestedModal"
import IneligibleModal from "./IneligibleModal"
import { Toast } from "../../components/Toast"
import { CloseButton } from "../../components/CloseButton"
import { STEPS } from "./EligibilityInterestModalSteps"
import FormComponent from "./EligibilityInterestModalForm"
import PreferredContactMethodForm from "./PreferredContactMethodForm"
import { allAttestationsHaveValue } from "../../shared/utils"
import {
  useUpdateAccountProject,
  useUpdateAttestations,
  useUpdateProfile,
} from "../../hooks"
import { genericErrMsg } from "../../api/auth"
import {
  DEADLINE_TYPE,
  LANDOWNER_STATUS,
  phoneRegex,
} from "../../shared/constants"

const validationSchema = (attestationsData) => {
  const fields = {}
  const types = {
    bool: yup.bool().oneOf([true], "Required"),
    str: yup
      .string()
      .required("Please fill out this field")
      .max(500, "Maximum length exceeded, please limit to 500 characters"),
    float: yup.number().required("Enter in a number"),
    int: yup.number().integer().required("Enter in a number"),
    multiple_choice: yup.string().required("Choose an option"),
  }
  Object.values(attestationsData)?.forEach((attestation) =>
    Object.assign(fields, {
      [attestation.attestation_id]: types[attestation.response_type],
    })
  )

  return yup.object().shape(fields)
}

const PREFERRED_CONTACT_METHOD = "preferred_contact_method"
const PHONE_NUMBER = "phone_number"

const contactValidationSchema = (isValidPhoneNumber) => {
  return yup.object().shape({
    [PREFERRED_CONTACT_METHOD]: yup
      .array()
      .min(1, "Please provide a preferred contact method")
      .of(yup.string().required())
      .required("Please provide a preferred contact method"),
    [PHONE_NUMBER]: yup
      .string()
      .when(PREFERRED_CONTACT_METHOD, (methods, schema) => {
        return !isValidPhoneNumber &&
          (methods.includes("phone") || methods.includes("text"))
          ? yup
              .string()
              .required("Please enter a phone number.")
              .matches(phoneRegex, "Please provide a valid phone number")
          : schema
      }),
  })
}

const EligibilityInterestModal = ({
  dialog,
  attestationsData,
  accountId,
  projectId,
  landowner_status,
  setAnimateBadge,
  is_eligible: initialEligibility,
  accountRole,
  enrollmentDeadlineType,
  img,
  profile,
  ctaOverride,
  ctaOverrideUrl,
  similarPrograms,
  stackablePrograms,
  isMultiAccount,
  requires_service_provider,
  has_service_provider_coverage,
}) => {
  const queryClient = useQueryClient()
  const [currStep, setCurrentStep] = useState(STEPS.ATTESTATION)
  const notInterestedDialog = useDialogState({ animated: true })
  const ineligibleDialog = useDialogState({ animated: true })
  const { mutateAsync: updateAttestations } = useUpdateAttestations(
    queryClient,
    accountId,
    projectId,
    {
      onSuccess: (updatedData) => {
        // skip steps if eligibility complete & it's same after editing
        if (
          !(updatedData?.is_eligible === null) &&
          updatedData?.is_eligible === initialEligibility
        ) {
          dialog.hide()
        }
        // Handle projects that require service providers
        if (requires_service_provider && updatedData?.is_eligible) {
          switch (updatedData?.has_service_provider_coverage) {
            case true:
              setCurrentStep(STEPS.INCOVERAGE)
              break
            case false:
              setCurrentStep(STEPS.OUTOFCOVERAGE)
              break
            case null:
              setCurrentStep(STEPS.ASSESSING)
              break
            default:
              dialog.hide()
          }
        } else {
          // For all other projects that don't require service providers
          switch (updatedData?.is_eligible) {
            case true:
              setCurrentStep(STEPS.ELIGIBLE)
              break
            case false:
              setCurrentStep(STEPS.INELIGIBLE)
              break
            case null:
              setCurrentStep(STEPS.ASSESSING)
              break
            // something went wrong, just close the modal
            default:
              dialog.hide()
          }
        }
      },
      onError: (error) => {
        dialog.hide()
        Toast.error(
          error?.detail || "An error occurred while adding your information."
        )
      },
    }
  )

  const onAttestationSubmit = async (values) => {
    const attData = Object.entries(values)?.map(([attestation_id, value]) => ({
      attestation_id: Number(attestation_id),
      value,
    }))
    const postData = { project_id: projectId, att_data: attData }
    await updateAttestations(postData)
  }

  const onRequestCallSubmit = (success) => {
    // DEV: custom CTA opens a new tab so we just close the modal
    if (ctaOverride?.length > 0 && ctaOverrideUrl?.length > 0) {
      dialog.hide()
      window.open(ctaOverrideUrl, "_blank", "noopener")
    } else {
      if (success) {
        setCurrentStep(STEPS.CALL_CONFIRMED)
        setAnimateBadge(true)
      } else {
        Toast.error("An error occurred while adding your information.")
      }
    }
  }

  const { mutateAsync: updateAccountProject } = useUpdateAccountProject(
    queryClient,
    accountId,
    projectId,
    {
      onSuccess: () => {
        onRequestCallSubmit(true)
      },
      onError: () => {
        onRequestCallSubmit(false)
      },
    }
  )

  const { mutateAsync: updateProfile } = useUpdateProfile(queryClient, {
    dontUpdateCache: true,
  })

  const onContactMethodSubmit = async (values) => {
    const newValues = {
      preferred_contact_method: values.preferred_contact_method,
      ...(values.preferred_contact_method.includes("phone") ||
      values.preferred_contact_method.includes("text")
        ? { phone_number: values.phone_number }
        : {}),
    }

    await updateProfile(newValues, {
      onSuccess: async () => {
        await updateAccountProject({
          status: LANDOWNER_STATUS.request_information,
        })
      },
      onError: (error) => {
        Toast.error(error?.detail || genericErrMsg)
      },
    })
  }

  const attestationsHaveVal = allAttestationsHaveValue(attestationsData)
  const initialValues = {}
  const valTypes = {
    bool: false,
    str: "",
    float: 0,
    int: 0,
    multiple_choice: "",
  }
  Object.values(attestationsData)?.forEach((attestation) =>
    Object.assign(initialValues, {
      [attestation.attestation_id]:
        attestation?.value || valTypes[attestation.response_type],
    })
  )

  const otherPrograms =
    enrollmentDeadlineType === DEADLINE_TYPE.pending_interest
      ? similarPrograms
      : stackablePrograms

  // Detect when modal is re-opened (and ensure current step back to attestation)
  const isDialogOnScreen = dialog.visible || dialog.animating
  const previousOnScreenRef = useRef(isDialogOnScreen)
  useEffect(() => {
    if (previousOnScreenRef.current !== isDialogOnScreen && !isDialogOnScreen) {
      setCurrentStep(STEPS.ATTESTATION)
    }
    previousOnScreenRef.current = isDialogOnScreen
  }, [isDialogOnScreen])

  const has_preferred_contact_method =
    profile.preferred_contact_method.length > 0

  const isValidPhoneNumber = phoneRegex.test(profile?.phone_number)

  return (
    <Dialog
      {...dialog}
      aria-label="Complete eligibility assessment"
      className="dialog-full"
      hideOnEsc={true}
      hideOnClickOutside={false}
    >
      {(dialogProps) => (
        <>
          <div {...dialogProps}>
            <div className="container xl:max-w-1120 pt-6 pb-16 lg:pt-16">
              <div className="flex flex-col-reverse lg:flex-row justify-between gap-4 mb-7 lg:mb-10">
                {currStep.renderHeadline({
                  attestationsHaveVal,
                  enrollmentDeadlineType,
                  requires_service_provider,
                  has_service_provider_coverage,
                })}
                <CloseButton
                  className="shrink-0 justify-self-end self-end lg:self-auto rounded focus:outline-none text-charcoal-500 font-bold lg:absolute lg:top-64 lg:right-64"
                  aria-label="Close modal"
                  onClick={dialog.hide}
                />
              </div>
              {currStep.renderBody({
                enrollmentDeadlineType,
                img,
                otherPrograms,
                isMultiAccount,
                accountId,
                requires_service_provider,
                has_service_provider_coverage,
                has_preferred_contact_method,
                eligibilityDialog: dialog,
              })}

              {/* Start custom additional info based on step */}
              <div>
                {/* Force formik to re-render since it won't update initial values for cached projects otherwise */}
                {currStep.key === STEPS.ATTESTATION.key &&
                dialogProps.hidden === false ? (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema(attestationsData)}
                    onSubmit={onAttestationSubmit}
                  >
                    {(formikProps) => (
                      <FormComponent
                        formikProps={formikProps}
                        dialog={dialog}
                        attestationsData={attestationsData}
                        accountRole={accountRole}
                      />
                    )}
                  </Formik>
                ) : null}

                {(currStep === STEPS.ELIGIBLE ||
                  currStep === STEPS.INCOVERAGE) &&
                !has_preferred_contact_method ? (
                  <Formik
                    initialValues={{
                      preferred_contact_method: [],
                      phone_number: profile.phone_number,
                    }}
                    validationSchema={() =>
                      contactValidationSchema(isValidPhoneNumber)
                    }
                    onSubmit={onContactMethodSubmit}
                  >
                    {(formikProps) => (
                      <PreferredContactMethodForm
                        formikProps={formikProps}
                        dialog={dialog}
                        accountRole={accountRole}
                        enrollmentDeadlineType={enrollmentDeadlineType}
                        notInterestedDialog={notInterestedDialog}
                        requires_service_provider={requires_service_provider}
                        ctaOverride={ctaOverride}
                        ctaOverrideUrl={ctaOverrideUrl}
                        isValidPhoneNumber={isValidPhoneNumber}
                        landowner_status={landowner_status}
                        projectId={projectId}
                        accountId={accountId}
                      />
                    )}
                  </Formik>
                ) : (
                  currStep.renderActions({
                    landowner_status,
                    projectId,
                    accountId,
                    onRequestCallSubmit,
                    notInterestedDialog,
                    ineligibleDialog,
                    eligibilityDialog: dialog,
                    accountRole,
                    enrollmentDeadlineType,
                    ctaOverride,
                    ctaOverrideUrl,
                    requires_service_provider,
                    has_service_provider_coverage,
                  })
                )}
              </div>
            </div>
          </div>
          <IneligibleModal
            dialog={ineligibleDialog}
            accountId={accountId}
            projectId={projectId}
            onClose={dialog.hide}
          />
          <NotInterestedModal
            dialog={notInterestedDialog}
            accountId={accountId}
            projectId={projectId}
            onClose={dialog.hide}
          />
        </>
      )}
    </Dialog>
  )
}

export default EligibilityInterestModal
