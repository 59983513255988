import { getViewportFromFeature } from "./helpers"
import { MAPBOX_TOKEN, mapStyles } from "../../../shared/constants"
import simplify from "@turf/simplify"
import truncate from "@turf/truncate"

const SIZE = 48

function simplifyGeoJSON(geojson) {
  let simpleGeoJSON
  const maxCoordinates = 50
  // Dev Note: Coordinates is an array[array[lat, long], array[lat, long], ...]
  const coordArray = geojson.features[0].geometry.coordinates
  const numCoordinates = coordArray.reduce(
    (total, sublist) => total + sublist[0].length,
    0
  )
  if (numCoordinates <= maxCoordinates) {
    simpleGeoJSON = geojson
  } else {
    simpleGeoJSON = simplify(geojson, { tolerance: 0.0005 })
  }
  return truncate(simpleGeoJSON, { precision: 5, coordinates: 2 })
}

const ProjectMapParcelsFeature = ({ parcelData, featureIndex }) => {
  const { longitude, latitude, zoom } = getViewportFromFeature(
    parcelData?.features[featureIndex],
    SIZE,
    SIZE
  )
  const parcelGeoJSON = parcelData?.features[featureIndex]
  const styledGeoJSON = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {
          fill: "#3BB2D0",
          "fill-opacity": 0.8,
          stroke: "transparent",
        },
        geometry: parcelGeoJSON.geometry,
      },
    ],
  }

  const geoJSONString = encodeURIComponent(
    JSON.stringify(simplifyGeoJSON(styledGeoJSON))
  )
  const mapStyle = mapStyles.aerial.url.slice("mapbox://styles/".length)
  const staticMapURL = `https://api.mapbox.com/styles/v1/${mapStyle}/static/geojson(${geoJSONString})/${longitude},${latitude},${zoom}/${SIZE}x${SIZE}?access_token=${MAPBOX_TOKEN}&format=png`

  return (
    <div className="project-map-parcel-feature w-12 h-12 bg-gray-500 rounded overflow-hidden">
      <img
        src={staticMapURL}
        alt="Static Map"
        className="w-full h-full object-cover"
      />
    </div>
  )
}

export default ProjectMapParcelsFeature
