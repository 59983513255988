import { useDialogState } from "reakit/Dialog"
import cx from "classnames"

import LandCoverStats from "../LandCoverDetails/LandCoverStats"
import OnboardingPropertyMap from "./OnboardingPropertyMap"
import LandCoverClassesModal from "../LandCoverDetails/LandCoverClassesModal"
import OnboardingProjectQuizCards from "./OnboardingProjectQuizCards"
import { explanatoryText } from "../LandCoverDetails/helpers"
import { shortenAcreage } from "../../utils"
import { getProjectTypeRecommendations } from "./helpers"
import { Profile } from "../../types"
import { AccountProperty } from "../../types/property"
import useViewport from "../../hooks/useViewport"
import { ProgressBar } from "../../components/ProgressBar"
import { EligibilityQuizzes } from "../../types/eligibility"

interface OnboardingReportProps {
  accountId: string
  profile: Profile
  property: AccountProperty
  quizzesData: EligibilityQuizzes
  progressStep: number
  maxSteps: number
}

const OnboardingReport = ({
  accountId,
  profile,
  property,
  quizzesData,
  progressStep,
  maxSteps,
}: OnboardingReportProps) => {
  const landCoverDialog = useDialogState({ animated: true })
  const { width } = useViewport()

  const recommendedProjectTypes = getProjectTypeRecommendations(
    property.landcover_data
  )

  const isMobile = width < 768
  const hasLandcoverData = property.landcover_data

  return (
    <>
      <div
        className={cx("landcover-content onboarding w-full", {
          "bg-sand": hasLandcoverData,
        })}
      >
        <ProgressBar
          className="onboarding-survey-progress-bar w-full h-5"
          value={progressStep}
          max={maxSteps}
        />
        {hasLandcoverData && (
          <div className="px-4 sm:px-[72px] py-4 sm:pt-12 sm:pb-[76px]">
            <h4 className="text-gray-500 text-overline">
              {hasLandcoverData ? "Land Report" : "Discover your eligibility"}
            </h4>
            <hr className="border-gray-500 mt-2 mb-4" />
            <div className="flex flex-col md:grid md:grid-cols-2 md:gap-14">
              <div className="mb-4 text-black text-xl font-medium leading-7 tracking-0.4">
                {`${profile.first_name}'s ${shortenAcreage(
                  property.acreage
                )} Acres`}
              </div>
              <LandCoverStats landCoverDetails={property.landcover_data} />
            </div>
            {isMobile ? (
              <>
                <OnboardingPropertyMap accountId={accountId} />
                {explanatoryText(landCoverDialog.show)}
              </>
            ) : (
              <div className="grid grid-cols-2 gap-14 mt-8 mb-12">
                {explanatoryText(landCoverDialog.show)}
                <OnboardingPropertyMap accountId={accountId} />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="w-full bg-white px-4 sm:px-[72px] py-4 sm:pt-12 sm:pb-[76px]">
        <h1 className="text-2xl pb-4">
          {hasLandcoverData ? "Recommended Programs" : "Program Categories"}
        </h1>
        <p className="pb-4">
          {hasLandcoverData
            ? "Select one category to get started."
            : "We need more time to generate your report, we'll send you an email when it's ready! While you wait, select one category to get started."}
        </p>
        <OnboardingProjectQuizCards
          recommendedProgramTypes={recommendedProjectTypes}
          quizzesData={quizzesData}
        />
      </div>
      <LandCoverClassesModal dialog={landCoverDialog} />
    </>
  )
}

export default OnboardingReport
