import React, { useContext, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { OptimizelyContext } from "@optimizely/react-sdk"

import * as auth from "../../api/auth"
import {
  identifyUserForIntegrations,
  sendAnalyticsEvent,
} from "../../api/integrations"

import { ErrorCard } from "../../components/Card"
import { SubmitButton } from "../../components/SubmitButton"
import { WrappedInput } from "../../components/Input"
import { useLogin } from "../../hooks"
import { AUTH_STEPS } from "../../pages/ProSignUp"
import NCX from "../../images/ncx-login-logo.png"

const VITE_APP_PARTNER_VIEW = import.meta.env.VITE_APP_PARTNER_VIEW

const FormComponent = (props) => {
  const [showPassword, setShowPassword] = useState(false)
  const location = useLocation()

  function handleBlur() {
    props.setAuthData({
      email: props.email,
      password: props.values.password,
    })
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <>
      <div className="flex justify-center mb-4">
        <img
          src={NCX}
          alt="NCX Logo"
          className="block w-auto h-[51px] lg:h-[46px]"
        />
      </div>

      <h2 className="text-center text-lg leading-130 tracking-0.378 mb-2">
        <span>Welcome Back</span>
      </h2>

      <p className="text-center text-base leading-130 tracking-0.32 mb-4">
        Log in to explore programs
      </p>

      <Form className="space-y-4" onBlur={handleBlur}>
        {props.errorMessage && <ErrorCard>{props.errorMessage}</ErrorCard>}
        <div className="space-y-4">
          <div>
            {/* DEV: If spacing was on outer div, then it'd overspace Link from error message*/}
            <div className="space-y-1">
              <WrappedInput
                data-test="input-password"
                label="Password"
                name="password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                as="password"
                toggleShowPassword={toggleShowPassword}
              />
            </div>
            <div>
              <Link
                className="text-sm text-leaf link--underline-only"
                to={location.pathname}
                state={{
                  ...location.state,
                  authStep: AUTH_STEPS.resetPassword,
                }}
              >
                Forgot your password?
              </Link>
            </div>
          </div>

          <SubmitButton
            className="btn2 btn2-primary btn2-block font-semibold"
            isSubmitting={props.isSubmitting}
            data-test="email-log-in"
          >
            Log In
          </SubmitButton>
        </div>

        <hr className="border-charcoal-50" />

        <div className="text-center text-dusk">
          Need help?{" "}
          <a
            className="link--underline-only text-base text-dusk-500"
            href="mailto:landowners@ncx.com"
            data-test="having-trouble"
          >
            <span className="link">landowners@ncx.com</span>
          </a>
        </div>
      </Form>
    </>
  )
}

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Please provide your password."),
})

const EmailLoginForm = ({ authData, setAuthData, inputEmail }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { optimizely } = useContext(OptimizelyContext)

  const [errorMessage, setErrorMessage] = React.useState(null)

  const { mutateAsync: login } = useLogin({
    onSuccess: (data, variables /* from handleSubmit's values */) => {
      identifyUserForIntegrations(variables.email, optimizely)
      sendAnalyticsEvent({ action: "login", label: "Landowners Login" })
      // DEV: multi-account users have to select an account context before viewing programs
      const landingPage =
        data?.profile_type === "multi_account" && VITE_APP_PARTNER_VIEW === "0"
          ? "/accounts"
          : "/programs"
      // Complete any existing login state, https://reactrouter.com/web/example/auth-workflow
      // DEV: They use `replace` but I'd rather keep `login` on the history stack
      const redirect = location.state?.from || landingPage
      navigate(redirect)
    },
    onError: (error) => {
      if (error.response) {
        // Browser received a response
        if (auth.isAuthenticationError(error)) {
          setErrorMessage("Invalid password")
        } else {
          setErrorMessage(auth.genericErrMsg)
        }
      } else {
        setErrorMessage(auth.genericErrMsg)
      }
    },
  })

  const handleSubmit = async (_values) => {
    setErrorMessage(null)
    const values = { ..._values }
    await login({ email: inputEmail, password: values.password })
  }

  return (
    <Formik
      initialValues={{
        password: "",
        ...authData,
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
      children={(formikProps) => (
        <FormComponent
          {...formikProps}
          email={inputEmail}
          errorMessage={errorMessage}
          setAuthData={setAuthData}
        />
      )}
    />
  )
}

export default EmailLoginForm
