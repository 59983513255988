import { Form, Formik } from "formik"
import * as yup from "yup"
import { useQueryClient } from "@tanstack/react-query"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons"

import { Toast } from "../../../components/Toast"
import { ButtonPair } from "../../../components/ButtonPair"
import { WrappedInput } from "../../../components/Input"
import { Modal } from "../../../components/Modal"
import { usePostQuestion } from "../../../hooks"

const validationSchema = yup.object().shape({
  question: yup.string().required("Question input required."),
})

const FormComponent = ({ formikProps, dialog }) => (
  <Form className="inline">
    <div className="mb-6">
      <WrappedInput
        label="Question"
        labelClass="leading-18px"
        name="question"
        as="textarea"
        placeholder="Write your question here."
        className="mt-2 resize-none border-charcoal-50"
        aria-label="Write your question here."
      />
    </div>

    <ButtonPair
      primary={
        // DEV: There's not enough space for SubmitButton component to fit without breaking into multiple lines on submit, so we're using a custom button instead.
        <button
          type="submit"
          className="font-bold"
          disabled={!(formikProps.isValid && formikProps.dirty)}
        >
          {formikProps.isSubmitting ? (
            <>
              Submitting{" "}
              <FontAwesomeIcon icon={faSpinnerThird} className="fa-spin" />
            </>
          ) : (
            "Submit Question"
          )}
        </button>
      }
      secondary={
        <button type="button" onClick={dialog.hide} className="font-bold">
          Cancel
        </button>
      }
    />
  </Form>
)

const AskAQuestionModal = ({ dialog, projectId }) => {
  const queryClient = useQueryClient()

  const { mutateAsync: postQuestion } = usePostQuestion(
    queryClient,
    projectId,
    {
      onSuccess: () => {
        dialog.hide()
        Toast.success(
          "Your question has been successfully submitted, and will appear once it's been approved."
        )
      },
      onError: () => {
        dialog.hide()
        Toast.error(
          "Unable to post your question at this time. Try again later."
        )
      },
    }
  )

  const handleSubmit = async (values) => {
    await postQuestion(values)
  }

  return (
    <Formik
      initialValues={{
        question: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => (
        <Modal
          header="Ask a question"
          aria-label="Ask a question"
          dialog={dialog}
          className="max-w-343 p-4"
          onDismiss={formikProps.resetForm}
        >
          <p className="text-base text-charcoal-500 leading-130 tracking-0.32 mb-4">
            Have a question about this program?
            <br />
            Submit your question below to learn more.
            <br />
            When you submit your question, your first name and last initial will
            be visible.
          </p>

          <FormComponent formikProps={formikProps} dialog={dialog} />

          <p className="text-base text-charcoal-500 text-center leading-130 tracking-0.32 mt-4">
            By submitting, you agree to the{" "}
            <a
              href="https://ncx.com/terms"
              className="link font-semibold"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms and conditions
            </a>{" "}
            and{" "}
            <a
              href="https://ncx.com/privacy"
              className="link font-semibold"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policy
            </a>
            .
          </p>
        </Modal>
      )}
    </Formik>
  )
}

export default AskAQuestionModal
