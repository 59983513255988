import { Form } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLightbulbOn } from "@fortawesome/pro-solid-svg-icons"

import { SubmitButton } from "../../components/SubmitButton"
import { WrappedInput } from "../../components/Input"
import { Checkbox2 } from "../../components/Checkbox"
import { ActionPermissionWrapper } from "../../components/ActionPermissionWrapper"
import { ButtonPair } from "../../components/ButtonPair"

const FormInput = ({ attestationData }) => {
  const components = {
    bool: (
      <div className="col-span-2">
        <Checkbox2
          name={attestationData.attestation_id}
          label={attestationData.attestation}
          className="text-sm font-bold leading-18 text-charcoal-500 mb-0"
        />
      </div>
    ),
    str: (
      <div className="col-span-2">
        <WrappedInput
          label={attestationData.attestation}
          labelClass="leading-18px text-charcoal-500"
          aria-label={attestationData.attestation}
          name={attestationData.attestation_id}
          as="textarea"
          className="mt-2 resize-none border-charcoal-50"
          maxLength="500"
        />
      </div>
    ),
    float: (
      <div className="col-span-2 md:col-span-1 flex flex-col justify-between">
        <WrappedInput
          label={attestationData.attestation}
          labelClass="leading-18px text-charcoal-500 mb-auto"
          aria-label={attestationData.attestation}
          type="number"
          min="0"
          step="0.01"
          name={attestationData.attestation_id}
          className="mt-2 resize-none border-charcoal-50"
        />
      </div>
    ),
    int: (
      <div className="col-span-2 md:col-span-1 flex flex-col justify-between">
        <WrappedInput
          label={attestationData.attestation}
          labelClass="leading-18px text-charcoal-500 mb-auto"
          aria-label={attestationData.attestation}
          type="number"
          min="0"
          name={attestationData.attestation_id}
          className="mt-2 resize-none border-charcoal-50"
        />
      </div>
    ),
    multiple_choice: (
      <div className="col-span-2 md:col-span-1 flex flex-col justify-between">
        <WrappedInput
          label={attestationData.attestation}
          labelClass="leading-18px text-charcoal-500 mb-auto"
          aria-label={attestationData.attestation}
          name={attestationData.attestation_id}
          as="select"
          placeholder="Select a value"
          options={attestationData?.choices?.map((choice) => ({
            label: choice,
            value: choice,
          }))}
          className="mt-2 resize-none border-charcoal-50"
        />
      </div>
    ),
  }
  return components[attestationData.response_type] || components.str
}

const FormNotification = () => (
  <div className="w-full lg:max-w-344 self-start border border-solid border-dusk-50 rounded p-6 mb-9">
    <FontAwesomeIcon icon={faLightbulbOn} className="text-leaf mb-3" />
    <h4 className="text-base text-charcoal-900 font-semibold leading-snug mb-1">
      Need to make a change?
    </h4>
    <p className="text-base leading-snug">
      NCX saves your responses so you can edit these at any time on the program
      details page.
    </p>
  </div>
)

const FormComponent = ({
  accountRole,
  formikProps,
  dialog,
  attestationsData,
  submitText = "Check Eligibility",
  backText = "Back to Program",
}) => (
  <Form className="inline">
    <div className="flex flex-col lg:flex-row-reverse md:justify-between">
      <FormNotification />
      <div className="w-full lg:max-w-624 mb-6">
        <div className="grid grid-cols-2 gap-x-4 gap-y-6">
          {Object.values(attestationsData)
            ?.sort((a, b) =>
              Number(a.attestation_id) > Number(b.attestation_id) ? 1 : -1
            )
            ?.map((attestation) => (
              <FormInput
                key={attestation.attestation_id}
                attestationData={attestation}
              />
            ))}
        </div>
      </div>
    </div>

    <hr className="border-charcoal-50 mb-6 lg:max-w-624 xl:max-w-664" />

    <ButtonPair
      // eslint-disable-next-line react/no-unstable-nested-components
      primary={(primaryProps) => (
        <ActionPermissionWrapper accountRole={accountRole} action="editAccount">
          <SubmitButton
            isSubmitting={formikProps.isSubmitting}
            disabled={!(formikProps.isValid && formikProps.dirty)}
            {...primaryProps}
          >
            {submitText}
          </SubmitButton>
        </ActionPermissionWrapper>
      )}
      secondary={
        <button type="button" onClick={dialog.hide} className="py-7px">
          {backText}
        </button>
      }
    />
  </Form>
)

export default FormComponent
