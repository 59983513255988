import {
  QueryClient,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query"
import { useLocation, useParams } from "react-router-dom"
import assert from "assert"

import { useQueryWithRefresh } from "./useQueryWithRefresh"
import { useAccountQueryWithRefresh } from "./useAccountQueryWithRefresh"
import {
  useMutationWithRefresh,
  UseMutationWithRefreshConfig,
} from "./useMutationWithRefresh"
import { _getAccountUserListMutationConfig } from "./_getAccountUserListMutationConfig"
import {
  ALLOWED_ACCOUNT_VALUE_OVERRIDES,
  useProfileType,
  useSessionStore,
} from "../../../stores"
import { assertAccountId } from "../../../utils"
import { AccountCycleTypes } from "@/types/accountCycles"
import { deleteNotification } from "../../../api/data"
import { DashboardAccountsTypes } from "@/types/dashboardAccounts"
import { _extendWithAuthPersistence } from "./_extendWithAuthPersistence"
import { useAccountDocumentActionURL } from "./useAccountDocumentActionURL"
import { getQueryParam } from "../../../shared/utils"

export type ListQueryEagerMutateFn<TItem, TList> = (
  item: TItem,
  list: TList
) => TList

interface ListMutationConfig<TData, TVariables>
  extends UseMutationOptions<TData, unknown, TVariables> {
  listQueryKeyAndEagerMutateFnTuples: [
    QueryKey,
    (item: unknown, list: TData) => void,
  ][]
}

export const e = encodeURIComponent

export const _getListMutationConfig = <TData, TError, TVariables>(
  queryClient: QueryClient,
  {
    listQueryKeyAndEagerMutateFnTuples,
    ...config
  }: ListMutationConfig<TData, TVariables>
): UseMutationOptions<TData, TError, TVariables> => {
  return {
    onMutate: (item: TVariables) => {
      if (config.onMutate) {
        throw new Error("Currently not extensible for onMutate")
      }

      const listQueryKeyAndPreviousDataTuples =
        listQueryKeyAndEagerMutateFnTuples.map(
          ([listQueryKey, listQueryEagerMutateFn]) => {
            queryClient.cancelQueries({ queryKey: listQueryKey })

            const previousData = queryClient.getQueryData<TData>(listQueryKey)
            if (previousData) {
              queryClient.setQueryData(
                listQueryKey,
                listQueryEagerMutateFn.bind(null, item)
              )
            }

            return [listQueryKey, previousData] as [string[], TData | undefined]
          }
        )

      return () => {
        listQueryKeyAndPreviousDataTuples.forEach(
          ([listQueryKey, previousData]) => {
            if (previousData) {
              queryClient.setQueryData(listQueryKey, previousData)
            }
          }
        )
      }
    },

    onSettled: (data, err, item, rollback: any) => {
      if (config.onSettled) {
        config.onSettled(data, err, item, rollback)
      }

      if (err) {
        rollback()
      }

      listQueryKeyAndEagerMutateFnTuples.forEach(([listQueryKey]) => {
        queryClient.invalidateQueries({ queryKey: listQueryKey })
      })
    },

    ...config,
  }
}

export const coerceNullDataValues = <TData extends Record<string, any>>(
  data: TData
): TData => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    acc[key as keyof TData] = value === null ? "" : value
    return acc
  }, {} as TData)
}

interface QueryPatternParams {
  queryKey: QueryKey
}

export const matchesQueryPattern = (
  { queryKey }: QueryPatternParams,
  queryPattern: string[]
): boolean => {
  // Given a wildcard pattern: [accounts, 12, cycles, *, property]
  // Matches: [accounts, 12, cycles, _latest, property]
  // Matches: [accounts, 12, cycles, _latest, property, preview]
  // Won't match: [accounts, 4, cycles, _latest, property]
  return (
    Array.isArray(queryKey) &&
    queryKey.length >= queryPattern.length &&
    queryPattern.every((item, i) => {
      if (item === "*") {
        return true
      }
      return queryKey[i] === item
    })
  )
}

export function useIsMultiAccount() {
  return useProfileType() === "multi_account"
}

export const useAccountId = () => {
  const { accountId } = useParams()
  return accountId || "_single"
}

export const useQueryParam = (key: string) => {
  return getQueryParam(useLocation(), key)
}

export const buildAccountAbsoluteUrl = (accountId: string) => {
  return accountId !== "_single" ? `/accounts/${accountId}` : "/"
}

export const useAccountAbsoluteUrl = () => {
  const accountId = useAccountId()
  return buildAccountAbsoluteUrl(accountId)
}

export const buildAccountUrlPrefix = (accountId: string) => {
  return accountId !== "_single" ? `/accounts/${accountId}` : ""
}

export const useAccountUrlPrefix = () => {
  const accountId = useAccountId()
  return buildAccountUrlPrefix(accountId)
}

export const useAccountOverrider = () => {
  const { getAccountValueOverride } = useSessionStore((state) => state)

  return (account: Record<string, any>, accountId: string | null = null) => {
    // DEV: Reminder that `accountId` can be `_single` which never matches `account.id`
    accountId = (accountId || account.id).toString()
    assertAccountId(accountId)
    ALLOWED_ACCOUNT_VALUE_OVERRIDES.forEach((propertyName) => {
      const valueOverride = getAccountValueOverride(
        accountId as string,
        propertyName
      )

      account[`_${propertyName}`] = account[propertyName]
      if (valueOverride) {
        account[propertyName] = valueOverride
      }
    })
  }
}

export const useAccountCycleOverrider = () => {
  const { getAccountCycleStatusOverride } = useSessionStore((state) => state)

  return (accountCycle: AccountCycleTypes, accountId?: string | null) => {
    // DEV: Reminder that `accountId` can be `_single` which never matches `account.id`
    accountId = accountId
      ? accountId.toString()
      : (accountCycle?.account?.id.toString() as string)
    assertAccountId(accountId)
    assert(accountCycle.cycle_key)
    const statusOverride = getAccountCycleStatusOverride(
      accountId,
      accountCycle.cycle_key
    )

    accountCycle._status = accountCycle.status
    if (statusOverride) {
      accountCycle.status = statusOverride
    }
  }
}

interface DeleteNotificationConfig {
  listQueryKey: string[]
}

export function _useDeleteNotification<
  TData extends DashboardAccountsTypes,
  TError,
  TVariables,
>(
  queryClient: QueryClient,
  { listQueryKey }: DeleteNotificationConfig,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> {
  const filterOutNotificationFn: ListQueryEagerMutateFn<number, TData> = (
    notificationId,
    oldNotificationsPage
  ) => {
    // Filter out the notification from the list
    const newObjectList = oldNotificationsPage.object_list.filter(
      (notification) => notification.id !== notificationId
    )

    return {
      ...oldNotificationsPage,
      object_list: newObjectList,
    }
  }

  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    deleteNotification,
    _getListMutationConfig<TData, TError, TVariables>(queryClient, {
      listQueryKeyAndEagerMutateFnTuples: [
        [listQueryKey, filterOutNotificationFn],
      ],
      ...config,
    } as ListMutationConfig<TData, TVariables>)
  )
}

export {
  useQueryWithRefresh,
  useAccountQueryWithRefresh,
  useMutationWithRefresh,
  _getAccountUserListMutationConfig,
  _extendWithAuthPersistence,
  useAccountDocumentActionURL,
}
